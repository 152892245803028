import * as React from "react";
import { useState, useEffect } from "react";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import Box from "@material-ui/core";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const SingleDatePicker = (props) => {
  const defaultEndtDate = props?.schema?.default;
  const label = props?.schema?.label;

  const { t } = useTranslation();
  const [endDate, setEndDate] = useState(
    defaultEndtDate ? defaultEndtDate : null
  );
  let EndDateerrorMessage = props?.errorSchema?.to?.__errors[0];

  const newTo = new Date(endDate);
  useEffect(() => {
    console.log("sdpp", props);
  }, [props]);
  const invalidEndDate = newTo == "Invalid Date" ? "Invalid Date" : null;
  /*   useEffect(() => {
    console.log("fromdtwid", props);
    if (props?.formData?.to_date == null) {
    

      setEndDate(null);
    }
  }, [props]);
 */
  useEffect(() => {
    if (!endDate) {
      props.onChange("");
    }
  }, [endDate]);
  useEffect(() => {
    if (newTo == "Invalid Date") {
      props.onChange({
        to: null,
      });
    }
  }, [newTo]);
  useEffect(() => {
    if (newTo != "Invalid Date" && endDate !== null) {
      const end = new Date(endDate);
      const to = new Date(
        Date.UTC(end.getFullYear(), end.getMonth(), end.getDate())
      );
      if (label === "read_time") {
        props.onChange({
          read_time: to ? to.toISOString() : null,
        });
      }
      if (label === "configuration_date") {
        props.onChange({
          inserted_at: to ? to.toISOString() : null,
        });
      }
      if (!label) {
        props.onChange(to ? to.toISOString() : null);
      }
    }
  }, [endDate]);
  useEffect(() => {
    if (props?.schema?.refreshData) {
      setEndDate(null);

      props?.schema?.setRefreshData(false);
    }
  }, [props?.schema?.refreshData]);
  useEffect(() => {
    console.log("datePROPS", props);
  }, [props]);

  return (
    <Grid container>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid item xs={12}>
          <DatePicker
            renderInput={(props) => (
              <TextField
                sx={{ width: "100%" }}
                {...props}
                error={
                  EndDateerrorMessage ? EndDateerrorMessage : invalidEndDate
                }
                helperText={
                  EndDateerrorMessage ? EndDateerrorMessage : invalidEndDate
                }
              />
            )}
            label={label ? t(label) : t("active_surveys_by_date")}
            value={endDate}
            onChange={(newValue) => {
              setEndDate(newValue);
            }}
          />
        </Grid>
      </LocalizationProvider>
    </Grid>
  );
};

export default SingleDatePicker;

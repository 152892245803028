import React, { useEffect } from "react";
import L from "leaflet";
import DeviceIcon from "../DeviceIcon";
import { Skeleton } from "@mui/material";
import ReactDOMServer from "react-dom/server";
import {
  TileLayer,
  Popup,
  MapContainer,
  ScaleControl,
  Marker,
} from "react-leaflet";

import DeviceSvg from "../../statics/icons/device.svg";

const DeviceMap = ({ pos }) => {
  const deviceIcon = (color) =>
    new L.divIcon({
      html: ReactDOMServer.renderToString(<DeviceIcon color={color} />),
      className: "",
      iconSize: [25, 25],
      iconAnchor: [10, 10],
    });

  useEffect(() => {
    console.log("POS", pos);
  }, [pos]);

  if (pos == undefined || pos.coordinates == undefined) {
    return (
      <Skeleton
        variant="rectangular"
        sx={{ width: "100%" }}
        height={300}
        animation="wave"
      />
    );
  }
  return (
    <MapContainer
      id="show-device-map"
      center={[pos.coordinates[1], pos.coordinates[0]]}
      zoom={13}
      scrollWheelZoom={true}
    >
      <ScaleControl />
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker
        icon={deviceIcon()}
        key={1}
        position={[pos.coordinates[1], pos.coordinates[0]]}
      ></Marker>
    </MapContainer>
  );
};

export default DeviceMap;

import { createSlice } from "@reduxjs/toolkit";
import { surveyApi } from "./surveyApi";
const initialState = {
  serveyIndex: null,
  surveysCount: null,
  survey: null,
  trashId: null,
};

const surveySlice = createSlice({
  name: "surveySlice",
  initialState,
  reducers: {
    cleanSurvey(state, action) {
      state.survey = null;
    },
    setSurvayTrashId(state, action) {
      state.trashId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      surveyApi.endpoints.getSurveyIndex.matchFulfilled,
      (state, action) => {
        state.serveyIndex = action.payload.data.attributes.data;
        state.surveysCount = action.payload.data.attributes.meta.count;
      }
    );
    builder.addMatcher(
      surveyApi.endpoints.getSurvey.matchFulfilled,
      (state, action) => {
        state.survey = action.payload.data.attributes;
      }
    );
  },
});
export const surveyActions = surveySlice.actions;
export default surveySlice.reducer;

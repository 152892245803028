import { array } from "@jsonforms/examples";
import { createSlice } from "@reduxjs/toolkit";
import { messageApi } from "./messageApi";
import { deviceMessageApi } from "./deviceMessageApi";

const initialState = {
  readingMessages: null,
  diagnosticMessages: null,
  readingCount: null,
  diagnosticCount: null,
  allMessages: null,
  allConunt: null,
  liveMessages: [],
  antennasMessage: [],
  antennasMessageCount: null,
};

const messageSlice = createSlice({
  name: "messageSlice",
  initialState,
  reducers: {
    setMessages(state, action) {
      debugger;
      state.readingMessages = action.payload.filter(
        (item) => item.values.value_type === "reading"
      );
      state.diagnosticMessages = action.payload.filter(
        (item) => item.values.value_type === "diagnostic"
      );
      state.allMessages = action.payload;
    },
    setLiveMessages(state, action) {
      debugger;

      const newMessages = action?.payload?.payload?.map((item) => item);
      if (state.liveMessages.length == 20) {
        debugger;
        const elemsToDelete = newMessages.length;
        const refreshedMessages = state.liveMessages.filter(
          (x, i) => i + elemsToDelete < state.liveMessages.length
        );
        state.liveMessages = [...newMessages, ...refreshedMessages];
      } else {
        state.liveMessages = [...newMessages, ...state.liveMessages];
      }
    },
    clearLiveMessages(state) {
      state.liveMessages = [];
    },
    clearAntennasMessages(state) {
      state.antennasMessage = [];
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      messageApi.endpoints.getDiagnosticMessages.matchFulfilled,
      (state, action) => {
        state.diagnosticMessages = action.payload.data.map(
          (item) => item.attributes,
          (state.diagnosticCount = action.payload.meta.count)
        );
      }
    );
    builder.addMatcher(
      messageApi.endpoints.getReadingMessages.matchFulfilled,
      (state, action) => {
        state.readingMessages = action.payload.data.map(
          (item) => item.attributes,
          (state.readingCount = action.payload.meta.count)
        );
      }
    );
    builder.addMatcher(
      messageApi.endpoints.getAllMessages.matchFulfilled,
      (state, action) => {
        state.allMessages = action.payload.data.map(
          (item) => item.attributes,
          (state.allConunt = action.payload.meta.count)
        );
      }
    );
    builder.addMatcher(
      deviceMessageApi.endpoints.getAntennasMessages.matchFulfilled,
      (state, action) => {
        state.antennasMessage = action.payload.data;
        state.antennasMessageCount = action.payload.meta.count;
      }
    );
  },
});
export const {
  setMessages,
  setLiveMessages,
  clearLiveMessages,
  clearAntennasMessages,
} = messageSlice.actions;
export default messageSlice.reducer;

import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import { onAddTab } from "../../redux/slices/nodeControllerSlice";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const NodeModal = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [name, setName] = React.useState("");
  const nameHandler = (e) => {
    setName(e.target.value);
  };
  const onSubmitClick = () => {
    dispatch(onAddTab(name));
    handleClose();
    setName("");
  };
  return (
    <Box>
      <Button onClick={handleOpen}>
        <AddIcon />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" sx={{ ml: 10 }}>
            Add new tab
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent={"space-between"}
            sx={{ mt: 2 }}
          >
            <TextField value={name} onChange={nameHandler} variant="outlined" />
            <Button onClick={onSubmitClick}>Submit</Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default NodeModal;

import React, { useEffect } from "react";
import L from "leaflet";
import DeviceIcon from "../DeviceIcon";
import GatewayIcon from "../svgIcons/GatewayIcon";
import { Skeleton } from "@mui/material";
import ReactDOMServer from "react-dom/server";
import {
  TileLayer,
  Popup,
  MapContainer,
  ScaleControl,
  Marker,
} from "react-leaflet";

import DeviceSvg from "../../statics/icons/device.svg";

const AntennasMap = ({ gatewayPos, devicePos }) => {
  const deviceIcon = (color) =>
    new L.divIcon({
      html: ReactDOMServer.renderToString(<DeviceIcon color={color} />),
      className: "",
      iconSize: [25, 25],
      iconAnchor: [10, 10],
    });
  const gatewayIcon = (color) =>
    new L.divIcon({
      html: ReactDOMServer.renderToString(<GatewayIcon color={color} />),
      className: "",
      iconSize: [25, 25],
      iconAnchor: [10, 10],
    });

  useEffect(() => {
    console.log("POS", devicePos);
  }, [gatewayPos, devicePos]);

  if (
    devicePos?.length < 1 ||
    !devicePos /* || pos.coordinates == undefined */
  ) {
    return (
      <Skeleton
        variant="rectangular"
        sx={{ width: "100%" }}
        height={650}
        animation="wave"
      />
    );
  }
  let test = [
    [11.876761, 45.406435],
    [15.973761, 45.486435],
    [13.973761, 49.486435],
  ];
  return (
    <MapContainer
      id="show-device-map"
      style={{ height: "100%", zIndex: 0 }}
      center={[devicePos[1], devicePos[0]]}
      zoom={13}
      scrollWheelZoom={true}
    >
      <ScaleControl />
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker
        icon={deviceIcon()}
        key={1}
        position={[devicePos[1], devicePos[0]]}
      ></Marker>
      {gatewayPos?.map((item, i) => (
        <Marker
          icon={gatewayIcon()}
          kei={i}
          position={[item[1], item[0]]}
        ></Marker>
      ))}
    </MapContainer>
  );
};

export default AntennasMap;

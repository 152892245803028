import { Handle, Position } from "reactflow";
import Box from "@mui/material/Box";
import { Tooltip } from "@mui/material";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import { Typography } from "@mui/material";
const CustomHandle = ({
  type,
  position,
  id,
  style,

  color,
  tooltip,
}) => {
  return (
    <Tooltip title={tooltip}>
      <Handle
        type={type}
        position={position}
        id={id}
        style={{
          border: "2px solid",

          borderRadius: "50%",
          width: "15px",
          height: "15px",
          ...style,

          background: color,
        }}
      ></Handle>
    </Tooltip>
  );
};

export default CustomHandle;

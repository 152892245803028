import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";

import { darken, lighten, styled } from "@mui/material/styles";

const getBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.4) : lighten(color, 0.4);

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  /*   "& .super-app-theme--Open": {
    backgroundColor: getBackgroundColor(
      theme.palette.info?.main,
      theme.palette.mode
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.info?.main,
        theme.palette.mode
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.info?.main,
        theme.palette.mode
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.info?.main,
          theme.palette.mode
        ),
      },
    },
  },
  "& .super-app-theme--Filled": {
    backgroundColor: getBackgroundColor(
      theme.palette.success?.main,
      theme.palette.mode
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.success?.main,
        theme.palette.mode
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.success?.main,
        theme.palette.mode
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.success?.main,
          theme.palette.mode
        ),
      },
    },
  },
  "& .super-app-theme--PartiallyFilled": {
    backgroundColor: getBackgroundColor(
      theme.palette.warning?.main,
      theme.palette.mode
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.warning?.main,
        theme.palette.mode
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.warning?.main,
        theme.palette.mode
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.warning?.main,
          theme.palette.mode
        ),
      },
    },
  },
  "& .super-app-theme--Orange": {
    backgroundColor: getBackgroundColor(
      theme.palette.orange?.main,
      theme.palette.mode
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.orange?.main,
        theme.palette.mode
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.orange?.main,
        theme.palette.mode
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.orange?.main,
          theme.palette.mode
        ),
      },
    },
  },
  "& .super-app-theme--Blue": {
    backgroundColor: getBackgroundColor(
      theme.palette.blue?.main,
      theme.palette.mode
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.orange?.main,
        theme.palette.mode
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.orange?.main,
        theme.palette.mode
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.orange?.main,
          theme.palette.mode
        ),
      },
    },
  },
  "& .super-app-theme--Rejected": {
    backgroundColor: getBackgroundColor(
      theme.palette.error?.main,
      theme.palette.mode
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.error?.main,
        theme.palette.mode
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.error?.main,
        theme.palette.mode
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.error?.main,
          theme.palette.mode
        ),
      },
    },
  }, */
  "& .MuiDataGrid-columnHeaderTitle": {
    whiteSpace: "normal",
    lineHeight: "normal",
    overflow: "hidden",
    textOverflow: "ellipsis",
    padding: "4px",
    width: "100%",
    overflowWrap: "break-word",
    height: "auto",
    display: "flex",
    alignItems: "center",
  },
  "@media screen and (max-width: 1280px) and (max-height: 720px)": {
    "& .MuiDataGrid-columnHeaderTitle": {
      height: "10vh",
    },
  },
  "& .MuiDataGrid-columnHeader": {
    height: "unset !important",
    display: "flex",
    alignItems: "center",
    overflowX: "hidden",
    overflowY: "hidden",
    scrollbarWidth: "thin",
    scrollbarColor: "transparent",
    msOverflowStyle: "none",
  },
  "& .MuiDataGrid-columnHeader::-webkit-scrollbar": {
    width: "8px",
  },
  "& .MuiDataGrid-columnHeader::-webkit-scrollbar-track": {
    backgroundColor: "transparent",
  },
  "& .MuiDataGrid-columnHeader::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    borderRadius: "4px",
  },
  "& .MuiDataGrid-cell": {
    lineHeight: "normal",
    whiteSpace: "normal",
    wordBreak: "break-word",
    padding: "8px",
    textAlign: "left",
    justifyContent: "flex-start !important",
  },
}));

import * as React from "react";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLazyGetPreferenceQuery } from "../../redux/slices/userPreferenceApi";
import TextField from "@mui/material/TextField";

const GroupsSelector = (props) => {
  const { t } = useTranslation();
  const statistic = useSelector((state) => state?.statisticsSlice?.statistic);
  const widget = useSelector((state) => state?.widgetsSlice?.widgetShow);
  const statisticUpdating = useSelector(
    (state) => state?.statisticsSlice?.updating
  );
  const widgetUpdating = useSelector((state) => state?.widgetsSlice?.updating);
  const [value, setValue] = React.useState();
  const roles = useSelector((state) => state?.userPreferenceSlice?.roles).map(
    (item) => ({
      title: item,
      value: item,
    })
  );
  const statisticValue = statistic?.groups?.map((item) => ({
    title: item,
    value: item,
  }));
  const widgetValue = widget?.attributes?.groups.map((item) => ({
    title: item,
    value: item,
  }));
  React.useEffect(() => {
    getPreference();

    return () => {
      setValue(null);
    };
  }, []);
  React.useEffect(() => {
    if (!statisticUpdating && !widgetUpdating) {
      setValue([]);
    }
  }, [statisticUpdating, widgetUpdating]);
  const [getPreference] = useLazyGetPreferenceQuery();
  React.useEffect(() => {
    console.log("valka", props);
  }, [props]);
  React.useEffect(() => {
    if (statistic && statisticUpdating) {
      debugger;
      setValue(
        statistic?.groups?.map((item) => ({
          title: item,
          value: item,
        }))
      );
    }
  }, [statistic]);
  React.useEffect(() => {
    if (widget && widgetUpdating) {
      console.log("ITS ME");
      setValue(
        widget?.attributes?.groups.map((item) => ({
          title: item,
          value: item,
        }))
      );
    }
  }, [widget]);
  React.useEffect(() => {
    if (value) {
      props.onChange(value.map((item) => item.value));
    }
  }, [value]);
  React.useEffect(() => {
    console.log("pisipopik", value);
  }, [value]);
  return (
    <>
      {roles && !statisticUpdating && !widgetUpdating && (
        <Autocomplete
          multiple
          value={value}
          id="tags-outlined"
          options={roles}
          getOptionLabel={(option) => option.title}
          filterSelectedOptions
          onChange={(e, newValue) => {
            console.log("valkanew", newValue);
            setValue(newValue);
          }}
          renderInput={(params) => (
            <TextField
              error={props?.rawErrors?.length > 0}
              {...params}
              label={t("groups")}
            />
          )}
        />
      )}
      {roles && value && (statisticUpdating || widgetUpdating) && (
        <Autocomplete
          multiple
          value={value}
          id="tags-outlined"
          options={roles}
          getOptionLabel={(option) => option.title}
          filterSelectedOptions
          onChange={(e, newValue) => {
            setValue(newValue);
          }}
          renderInput={(params) => (
            <TextField
              error={props?.rawErrors?.length > 0}
              {...params}
              label={t("groups")}
            />
          )}
        />
      )}
    </>
  );
};
export default GroupsSelector;

import validator from "@rjsf/validator-ajv6";
import { Form } from "@rjsf/mui";
import React from "react";
import PolygonsList from "./PolygonsList";
import { setMessage } from "../../redux/slices/snackbarSlice";
import {
  useCreateDistrictMutation,
  useCreatePolygonMutation,
  useLazyGetDistrictQuery,
  useLazyGetPolygonsQuery,
} from "../../redux/slices/deviceDistrictApi";
import { useParams } from "react-router-dom";
import { useUpdateDistrictMutation } from "../../redux/slices/deviceDistrictApi";
import { Grid } from "@mui/material";
import { actions } from "../../redux/slices/deviceDistrictSlice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useDeletePolygonMutation } from "../../redux/slices/deviceDistrictApi";
import Button from "@mui/material/Button";
import { IwdAuthWrapper } from "../../MuiOverrides/IwdAuthWrapper";
import theme from "../../theme";

const objectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={3}>
      {props.properties.map((element) => {
        return (
          <Grid key={element.id} alignItems="center" item xs={12}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
const DeviceGroupShowForm = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();

  const [updateDistrict, { isSuccess: updateDistrictSuccess }] =
    useUpdateDistrictMutation();
  const [formData, setFormData] = useState({});
  const [updating, setUpdating] = useState(false);
  const [polygonId, setPolygonId] = useState(null);
  const [getDistrict] = useLazyGetDistrictQuery();
  const [getPolygons, { isFetching }] = useLazyGetPolygonsQuery();
  const [deletePolygon, { isSuccess: deletePolygonSuccess }] =
    useDeletePolygonMutation();
  const [createDistrict] = useCreateDistrictMutation();
  const [createPolygon, { isSuccess: createPolygonSuccess }] =
    useCreatePolygonMutation();
  const district = useSelector((state) => state?.deviceDistrictSlice?.district);
  const polygons = useSelector((state) => state?.deviceDistrictSlice?.polygons);
  const polygonsToRemove = useSelector(
    (state) => state?.deviceDistrictSlice?.polygonsToRemove
  );
  const newPolygonsInUpdate = useSelector(
    (state) => state?.deviceDistrictSlice?.newPolygonsInUpdate
  );
  const updatePolygons = () => {
    if (newPolygonsInUpdate.length > 0) {
      for (let i = 0, len = newPolygonsInUpdate.length; i < len; i++) {
        createPolygon({ id: id, body: newPolygonsInUpdate[i] });
      }
    }
    if (polygonsToRemove.length > 0) {
      debugger;
      for (let i = 0, len = polygonsToRemove.length; i < len; i++) {
        deletePolygon({
          districtId: id,
          polygonId: polygonsToRemove[i].polygon.id,
        });
      }
    }
  };
  useEffect(() => {
    dispatch(actions.updatingOn());
  }, []);
  const onSubmitClick = () => {
    const attributes = {
      name: formData.name,
      description: formData.description,
    };
    updateDistrict({ id: id, attributes: attributes });
    updatePolygons();
    setUpdating(true);
  };
  /*  
  useEffect(() => {
    if (polygonId && polygons.length > 0) {
      for (let i = 0, len = polygons.length; i < len; i++) {
        createPolygon({ id: polygonId, body: polygons[i] });
      }
    }
  }, [polygonId, polygons]); */
  useEffect(() => {
    getDistrict(id);
    getPolygons(id);
  }, []);
  const updated = () => {
    debugger;
    dispatch(actions.setPolygonsToremove([]));
    dispatch(actions.cleanPolygonsInUpdate());
    getDistrict(id);
    getPolygons(id);
    setUpdating(false);
    dispatch(setMessage(t("update_success")));
  };
  useEffect(() => {
    if (
      updateDistrictSuccess &&
      createPolygonSuccess &&
      polygonsToRemove.length < 1 &&
      updating
    ) {
      debugger;
      /*  window.location.reload(); */
      updated();
    }
    if (
      updateDistrictSuccess &&
      createPolygonSuccess &&
      deletePolygonSuccess &&
      updating
    ) {
      debugger;
      /*    window.location.reload(); */
      updated();
    }
    if (
      updateDistrictSuccess &&
      newPolygonsInUpdate.length < 1 &&
      deletePolygonSuccess &&
      updating
    ) {
      /*  window.location.reload(); */
      updated();
    }
    if (updateDistrictSuccess) {
      dispatch(setMessage(t("update_success")));
    }
  }, [
    updateDistrictSuccess,
    createPolygonSuccess,
    polygonsToRemove.length,
    deletePolygonSuccess,
    newPolygonsInUpdate.length,
  ]);
  const schema = {
    title: "Device Group form",
    type: "object",
    properties: {
      name: {
        id: 0,
        type: "string",
        title: t("name"),
        default: district?.name ?? "",
      },
      description: {
        id: 0,
        type: "string",
        title: t("description"),
        default: district?.description ?? "",
      },
    },
  };
  const uiSchema = {
    "ui:ObjectFieldTemplate": objectFieldTemplate,
    description: {
      "ui:options": {
        widget: "textarea",
        minRows: 15,
      },
    },
    "ui:submitButtonOptions": {
      submitText: t("update"),
    },
  };
  useEffect(() => {
    //clear function
    return () => {
      dispatch(actions.setDistrict(null));
      dispatch(actions.updatingOf());
      dispatch(actions.setPolygons([]));
      dispatch(actions.setDevices({}));
    };
  }, []);
  return (
    <>
      {district && (
        <>
          <Form
            schema={schema}
            /*     fields={fields} */
            formData={formData}
            /*     customValidate={customValidate} */
            showErrorList={false}
            /*  onSubmit={onSubmitClick} */
            hideSubmitButton
            children={true}
            onChange={(changeEvent) => setFormData(changeEvent.formData)}
            /*   onSubmit={(values) => {
      onSubmitClick(values.formData);
    }} */
            validator={validator}
            uiSchema={uiSchema}
          />
          <PolygonsList polygonFetching={isFetching} />
          <IwdAuthWrapper
            children={
              <Button
                onClick={onSubmitClick}
                variant="outlined"
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  color: "white",
                  mt: 2,
                  "&:hover": { backgroundColor: theme.palette.primary.main },
                }}
              >
                {t("update")}
              </Button>
            }
            section={"groups_update"}
          />
        </>
      )}
    </>
  );
};

export default React.memo(DeviceGroupShowForm);

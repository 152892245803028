import React, { useEffect, useState } from "react";
import { Polygon, MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "@bopen/leaflet-area-selection/dist/index.css";
import { useTranslation } from "react-i18next";
import { Box, IconButton, Tooltip } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import { worklistActions as actions } from "../../redux/slices/worklistSlice";
import BeenhereIcon from "@mui/icons-material/Beenhere";

import WorklisteEditorMapComponent from "./WorklisteEditorMapComponent";
import { useDispatch, useSelector } from "react-redux";

const defaultLocation = [45.40667650588728, 11.879199098463717];
const WorklistMap = () => {
  const dispatch = useDispatch();
  const [drawPolygon, setDrawPolygon] = useState();
  const [currentPolygon, setCurrentPolygon] = useState(null);
  const { t } = useTranslation();
  const [location] = useState(defaultLocation);
  /*   const drawPolygon = useSelector(
    (state) => state?.deviceDistrictSlice?.drawPolygon
  ); */
  /*   const currentPolygon = useSelector(
    (state) => state?.deviceDistrictSlice?.currentPolygon
  ); */
  const updating = useSelector((state) => state?.worklistSlice?.updating);
  useEffect(() => {
    console.log("DRAWPO", currentPolygon);
  }, [currentPolygon]);
  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <Box>
        <Tooltip title={t("addNewPolygon")} placement="top">
          <IconButton
            onClick={() => {
              drawPolygon.activate();
            }}
          >
            <AddCircleOutlineIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title={t("save")} placement="top">
          <IconButton
            disabled={!currentPolygon}
            onClick={() => {
              debugger;
              drawPolygon.deactivate();
              drawPolygon.clearPolygon();

              if (updating) {
                dispatch(actions.addNewPolygonInUpdate(currentPolygon));
                setCurrentPolygon(null);
              } else {
                dispatch(actions.addPolygon(currentPolygon));
                setCurrentPolygon(null);
              }
            }}
          >
            <BeenhereIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("remove")} placement="top">
          <IconButton
            onClick={() => {
              drawPolygon.deactivate();
              drawPolygon.clearPolygon();
              setCurrentPolygon(null);
            }}
          >
            <CancelIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <MapContainer
        center={location}
        zoom={13}
        style={{ minHeight: 600, height: "100%", width: "100%" }}
      >
        <WorklisteEditorMapComponent
          setDrawPolygon={setDrawPolygon}
          setCurrentPolygon={setCurrentPolygon}
        />
      </MapContainer>
    </Box>
  );
};

export default React.memo(WorklistMap);

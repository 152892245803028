import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import { useEffect, useState } from "react";
import React from "react";
import InputAdornment from "@mui/material/InputAdornment";

import { useLazyGetDataByNameQuery } from "../../redux/slices/GetDataRtq";
import CircularProgress from "@mui/material/CircularProgress";
import debounce from "lodash.debounce";

const WorkListClassSelector = (props) => {
  const [value, setValue] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [firstRender, setFirstRender] = useState(true);
  const [loading, setLoading] = useState("");
  const [open, setOpen] = useState(false);

  const defPropValue = props?.schema?.default;
  const savedValue =
    props?.schema?.multiple &&
    props?.formData?.map((item) => ({
      label: item,
      value: item,
    }));
  const [getByName, resp, lpi] = useLazyGetDataByNameQuery();
  let res;

  res = resp?.data?.data?.map((item) => ({
    label: `${item?.attributes?.name} (${item?.attributes?.version})`,
    value: item?.attributes?.id,
  }));

  const debValue = debounce((e) => {
    setSearchValue(e.target.value);
  }, 500);

  useEffect(() => {
    if (defPropValue && res && firstRender) {
      debugger;
      const twinsNames = resp?.data?.data
        ?.filter((twin) => defPropValue.includes(twin?.id))
        .map((item) => ({
          label: `${item?.attributes?.name} (${item?.attributes?.version})`,
          value: item?.attributes?.id,
        }));

      setValue(twinsNames);
      setFirstRender(false);
    }
  }, [defPropValue, res]);
  useEffect(() => {
    console.log("PROPS", props);
  }, [props]);
  useEffect(() => {
    if (value && !props?.schema?.multiple) {
      props.onChange(value.value.toString());
    }
    if (!value && !props?.schema?.multiple) {
      props.onChange(null);
    }
  }, [value]);
  //1
  useEffect(() => {
    if (value?.length >= 0 /* && value !== props.formData */) {
      props.onChange(value.map((item) => item.value.toString()));
    }
  }, [value]);
  useEffect(() => {
    getByName([props.schema.url, {}]);
  }, []);
  //2
  /*   useEffect(() => {
    if (
      defPropValue &&
      defPropValue !== null &&
      defPropValue !== "" &&
      firstRender &&
      (!resp || !resp.data)
    ) {
      setLoading(true);
      Promise.all([getByName([props.schema.url, {}])]).then(() => {
        setSearchValue(null);
      });
    }
  }, [defPropValue]); */

  //3
  /*   useEffect(() => {
    if (
      defPropValue &&
      resp &&
      resp.data &&
      firstRender &&
      !props?.schema?.multiple
    ) {
      const val = resp?.data?.data
        .filter((item) => defPropValue?.[0].includes(item.id))
        .map((item) => ({
          label: `${item?.attributes?.name} (${item?.attributes?.version})`,
          value: item?.attributes?.id,
        }));
      if (val?.length > 0) {
        setValue(val[0]);
      } else {
        setValue(null);
      }

      setLoading(false);
      setFirstRender(false);
    }
  }, [resp]); */
  /*   useEffect(() => {
    if (
      defPropValue &&
      resp &&
      resp.data &&
      firstRender &&
      props?.schema?.multiple
    ) {
      setValue(
        resp?.data?.data
          .filter((item) => defPropValue?.[0].includes(item.attributes.name))
          .map((item) => ({
            label: item?.attributes?.name,
            value: item?.attributes?.name,
          }))
      );

      setLoading(false);
      setFirstRender(false);
    }
  }, [resp]); */

  const openFunc = () => {
    setOpen(true);
  };
  useEffect(() => {
    console.log("VALVALVAL", value);
    console.log("VALVALVALPR", props?.formData);
  }, [value, props?.formData]);

  //5
  /*   useEffect(() => {
    if (searchValue) {
      setLoading(true);
      Promise.all([getByName([props.schema.url, { name: searchValue }])]).then(
        () => {
          setLoading(false);
          
        }
      );
    }
  }, [searchValue]); */
  useEffect(() => {
    console.log("FILTERED WIDGETS", props?.schema?.filteredWidgets);
  }, [props?.schema?.filteredWidgets]);

  return (
    <>
      <Autocomplete
        multiple={props?.schema?.multiple}
        fullWidth
        options={res ? res : []}
        value={/* savedValue ? savedValue : */ value}
        open={open}
        onOpen={openFunc}
        getOptionDisabled={(option) =>
          props?.schema?.filteredWidgets &&
          props?.schema?.filteredWidgets?.includes(option.value)
        }
        onClose={() => setOpen(false)}
        popupIcon={""}
        onChange={(e, newValue) => {
          setValue(newValue);
        }}
        renderInput={(params) => {
          return (
            <TextField
              onChange={debValue}
              {...params}
              value={savedValue ? savedValue : value}
              error={props?.rawErrors?.length > 0}
              required={props.required}
              label={props.schema.title}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    {loading && <CircularProgress color="inherit" size={20} />}
                  </InputAdornment>
                ),
              }}
            />
          );
        }}
      />
    </>
  );
};

export default React.memo(WorkListClassSelector);

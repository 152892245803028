import { nodeControllerApi } from "./nodeControllerApi";

import { createSlice } from "@reduxjs/toolkit";
import {
  Connection,
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  addEdge,
  OnNodesChange,
  OnEdgesChange,
  OnConnect,
  applyNodeChanges,
  applyEdgeChanges,
} from "reactflow";
const initialState = {
  dataIsReade: false,
  nodeDashboardId: null,
  actualTabName: "",
  nodeList: {},
  edgeList: {},
  modal: false,
  appVariables: JSON.parse(localStorage.getItem("appVariables")),
  tabId: 0,
  records: JSON.parse(localStorage.getItem("records")) ?? {},
  selectedRecord: localStorage.getItem("selectedRecord") ?? "",
  properties: JSON.parse(localStorage.getItem("properties")),
};

const nodeControllerSlice = createSlice({
  name: "nodeControllerSlice",
  initialState,
  reducers: {
    setNodes(state, action) {
      debugger;
      const newNode = {
        id: action.payload?.id,
        type: action.payload?.type,
        data: action.payload?.data,
        position: action.payload.position ?? { x: 20, y: 20 },
      };

      const oldNodes = state.nodeList[state.actualTabName];

      if (oldNodes) {
        const index = oldNodes.findIndex((node) => node.id === newNode.id);
        if (index !== -1) {
          // Заменяем старый узел новым
          oldNodes[index] = newNode;
        } else {
          // Добавляем новый узел в oldNodes
          state.nodeList[state.actualTabName] = [...oldNodes, newNode];
        }
      } else {
        state.nodeList[state.actualTabName] = [newNode];
      }
    },

    updateNodes(state, action) {
      debugger;
      state.nodeList[state.actualTabName] = applyNodeChanges(
        action.payload.changes,
        state.nodeList[state.actualTabName]
      );
    },
    updateEdges(state, action) {
      debugger;
      state.edgeList[state.actualTabName] = applyEdgeChanges(
        action.payload.changes,
        state.edgeList[state.actualTabName]
      );
    },
    onConnect(state, action) {
      debugger;
      state.edgeList[state.actualTabName] = addEdge(
        action.payload.params,
        state.edgeList[state.actualTabName]
      );
    },
    onAddTab(state, action) {
      state.tabs = [...state.tabs, action.payload];
      if (state.tabs.length > 1) {
        state.tabId = state.tabs.length - 1;
      }

      state.nodeList[action.payload] = [];
      state.edgeList[action.payload] = [];
    },
    onRemoveTab(state, action) {
      debugger;
      state.tabs = state.tabs.filter((item, i) => i !== action.payload);
      if (state.tabId > 0) {
        state.tabId = state.tabId - 1;
      }
      delete state.nodeList[state.actualTabName];
      delete state.edgeList[state.actualTabName];
    },
    onTabChange(state, action) {
      state.tabId = action.payload;
    },
    setActualTabName(state, action) {
      debugger;
      state.actualTabName = action.payload;
    },
    getNodesFromLocalStorage(state, action) {
      debugger;
      state.tabs = JSON.parse(localStorage.getItem("tabs")) ?? [];
      state.nodeList = JSON.parse(localStorage.getItem("nodes")) ?? {};
      state.edgeList = JSON.parse(localStorage.getItem("edges")) ?? {};
    },
    setSwarmAppModal(state, action) {
      state.modal = action.payload;
    },
    setAppVariables(state, action) {
      state.appVariables = action.payload;
      localStorage.setItem("appVariables", JSON.stringify(state.appVariables));
    },
    setAppProperties(state, action) {
      state.properties = action.payload;
      localStorage.setItem("properties", JSON.stringify(state.properties));
    },
    addNewRecordSection(state, action) {
      const newProperty = action.payload;
      state.records[newProperty] = [];
    },
    removeRecordSection(state, action) {
      const propertyToRemove = action.payload;
      delete state.records[propertyToRemove];
    },
    setSelectedRecord(state, action) {
      if (action.payload) {
        state.selectedRecord = action.payload;
        localStorage.setItem("selectedRecord", state.selectedRecord);
      } else {
        state.selectedRecord = "";
        localStorage.removeItem("selectedRecord");
      }
    },
    addRecordsVariables(state, action) {
      const oldValues = state.records[state.selectedRecord];
      state.records[state.selectedRecord] = action.payload;
      localStorage.setItem("records", JSON.stringify(state.records));
    },
    deleteRecordsVariable(state, action) {
      state.records[state.selectedRecord].filter(
        (item, i) => i !== action.payload
      );
    },
  },
  /*  extraReducers: (builder) => {
    builder.addMatcher(
      nodeControllerApi.endpoints.getNodesFromLocalStorage.matchFulfilled,
      (state, action) => {
        console.log("DADAPISYA", action.payload);
      }
    );
  }, */
});
export const {
  setNodes,
  updateNodes,
  updateEdges,
  onConnect,
  onAddTab,
  onRemoveTab,
  onTabChange,
  setActualTabName,
  getNodesFromLocalStorage,
  setSwarmAppModal,
  setAppVariables,
  addNewRecordSection,
  setAppProperties,
  removeRecordSection,
  setSelectedRecord,
  addRecordsVariables,
  deleteRecordsVariable,
} = nodeControllerSlice.actions;
export default nodeControllerSlice.reducer;

import { styled } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
export const CustomMenuItem = styled(MenuItem)(() => ({
  "&.MuiButtonBase-root": {
    padding: "2px",
    display: "flex",
    flexDirection: "column",
  },
}));
export const CustomTab = styled(Tab)(() => ({
  "&.MuiButtonBase-root": {
    padding: "13px",
  },
}));
export const CustomAutocomlete = styled(Autocomplete)(() => ({
  "&.MuiIconButton-root": {
    padding: "1px",
  },
}));

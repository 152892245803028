import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { hasCommonValue } from "../../utils/api_params";
import { useTranslation } from "react-i18next";
import MapLeaflet from "./MapLeaflet";
import { AuthCheck } from "../../MuiOverrides/AuthCkech";
import ManufacturerData from "./ManufacturerData";
import { useLazyGetDataQuery } from "../../redux/slices/GetDataRtq";
import { useLazyGetMeterQuery } from "../../redux/slices/meterApi";
import {
  setidentifierTypeValue,
  unsetValue,
} from "../../redux/slices/breadCrumbMeterSlice";
import { meterActions } from "../../redux/slices/meterSlice";

import { useDispatch, useSelector } from "react-redux";
import theme from "../../theme";
import MeterCommands from "./MeterCommands";
import Registri from "./Registry";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import MeterReadingsTab from "../meters/MeterReadingsTab";
import { IwdAuthWrapper } from "../../MuiOverrides/IwdAuthWrapper";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const MeterShow = () => {
  const dispatch = useDispatch();
  const permessions = window.COMPONENTS;

  const roles = useSelector((state) => state?.userPreferenceSlice.roles);
  const meter = useSelector((state) => state?.meterSlice.meter);
  const { id } = useParams();
  const [getMeter] = useLazyGetMeterQuery();
  const [trigger, result, isSuccess] = useLazyGetDataQuery();
  const [value, setValue] = useState(0);
  const { t } = useTranslation();
  /*   const key = result?.data?.data?.attributes?.fields?.identifier_type;
  const identifierTypeValue = result?.data?.data?.attributes?.fields?.[key]; */
  const serial = meter?.serial_number;
  useEffect(() => {
    trigger(`altior/digital_twin/0/instance/${id}`);
    getMeter(id);
  }, []);
  useEffect(() => {
    if (serial) {
      dispatch(setidentifierTypeValue(serial));
    }
  }, [serial]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (result?.data?.data?.attributes) {
      const data = result?.data?.data?.attributes?.fields;
      const fieldsToUpdate = {
        class: result?.data?.data?.attributes?.class,
        iccid: data.iccid,
        security: data.security,
        address: data?.address ?? "",
        serial_number: data.serial_number,
        coordinates: {
          lat: data?.coordinates?.coordinates?.[1],
          lng: data?.coordinates?.coordinates?.[0],
        },

        pod: data?.pod ?? "",
        time_zone: data?.time_zone,
        tags: data?.tags,
      };
      dispatch(meterActions.setFieldsToUpdate(fieldsToUpdate));
      dispatch(
        meterActions.setDigitalTwinId(
          result.data.data.attributes.digital_twin_id
        )
      );
    }
  }, [result?.data?.data?.attributes]);
  useEffect(() => {
    console.log("serik", result?.data?.data?.attributes);
  }, [serial]);
  useEffect(() => {
    // breadCrumb value clean up function

    return () => {
      dispatch(unsetValue());
    };
  }, []);
  const tabs = [
    {
      visibility:
        permessions?.["meters_registry"]?.visibility &&
        hasCommonValue(roles, permessions["meters_registry"]?.roles),
      tab: <Tab label={t("registry")} />,
      tabPan: <Registri meter={meter} />,
    },
    {
      visibility:
        permessions?.["manufacturer_data"]?.visibility &&
        hasCommonValue(roles, permessions["manufacturer_data"]?.roles),
      tab: <Tab label={t("manufacturer_data")} />,
      tabPan: <ManufacturerData result={meter} />,
    },
    {
      visibility:
        permessions?.["meters_reading"]?.visibility &&
        hasCommonValue(roles, permessions["meters_reading"]?.roles),
      tab: <Tab label={t("readings")} />,
      tabPan: <MeterReadingsTab meter={result?.data?.data} />,
    },
    {
      visibility:
        permessions?.["meters_consumptions"]?.visibility &&
        hasCommonValue(roles, permessions["meters_consumptions"]?.roles),
      tab: <Tab label={t("consumptions")} disabled />,
      tabPan: <MapLeaflet />,
    },
    {
      visibility:
        permessions?.["meters_commands"]?.visibility &&
        hasCommonValue(roles, permessions["meters_commands"]?.roles),
      tab: <Tab label={t("commands")} />,
      tabPan: (
        <MeterCommands
          digitalTwinId={result?.data?.data?.attributes?.digital_twin_id}
        />
      ),
    },
  ];
  const test = [
    {
      label: t("registry"),
      component: (
        <IwdAuthWrapper
          children={<Tab label={t("registry")} />}
          section={"meters_registry"}
        />
      ),
    },
  ];
  const filteredTabs = tabs?.filter((item) => item?.visibility == true);
  useEffect(() => {
    console.log("tabus", filteredTabs, "test", test);
  }, [tabs, test]);
  return (
    <>
      {serial && (
        <>
          {" "}
          <Typography
            sx={{ color: theme.palette.primary.main }}
            variant="h3"
          >{`Meter: ${serial}`}</Typography>
          <Box sx={{ display: "grid", gridAutoColumns: "1fr", gap: 1 }}>
            <Box
              sx={{
                gridRow: "1",
                gridColumn: "span 2",
                width: "100%",
              }}
            >
              <Tabs value={value} onChange={handleChange}>
                {/*   <Tab label={t("registry")} />
                <Tab label={t("manufacturer_data")} />
                <Tab label={t("readings")} />
                <Tab label={t("consumptions")} disabled />
                <Tab label={t("commands")} /> */}
                {filteredTabs?.map((item) => item?.tab)}
              </Tabs>
              {/* <TabPanel value={value} index={0}>
                <Registri result={result} />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <ManufacturerData result={result} />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <MeterReadingsTab meter={result?.data?.data} />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <MapLeaflet />
              </TabPanel>
              <TabPanel value={value} index={4}>
                <MeterCommands
                  digitalTwinId={
                    result?.data?.data?.attributes?.digital_twin_id
                  }
                />
              </TabPanel> */}

              {filteredTabs?.map((item, i) => (
                <TabPanel index={i} value={value}>
                  {item?.tabPan}
                </TabPanel>
              ))}
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default MeterShow;

import { Grid } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
} from "@mui/material";
import { useEffect } from "react";
const SecurityShow = ({ security }) => {
  const mappedScopes = security?.map((item, i) => (
    <>
      <Grid item xs={6}>
        <TextField label="name" value={item?.name} disabled fullWidth />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          sx={{ mb: 3 }}
          disablePortal
          multiple
          id="combo-box-demo"
          defaultValue={[...item?.scopes]}
          options={[]}
          disabled
          fullWidth
          renderInput={(params) => <TextField {...params} label="scopes " />}
        />
      </Grid>
    </>
  ));
  useEffect(() => {
    console.log("mpdscops", security);
  }, [mappedScopes]);
  return (
    <Accordion>
      <AccordionSummary>Security</AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          {mappedScopes}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
export default SecurityShow;

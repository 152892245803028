import SettingsIcon from "@mui/icons-material/Settings";
import { Handle, Position } from "reactflow";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import CustomHandle from "../CustomHandle";
import { Typography } from "@mui/material";

const PropertyNode = () => {
  const [property, setProperty] = useState();
  const [value, setValue] = useState();
  const propertyHandler = (e) => {
    setProperty(e.target.value);
  };
  const valueHandler = (e) => {
    setValue(e.target.value);
  };

  return (
    <Box
      sx={{
        width: 200,
        height: 150,
        border: "2px solid gray",
        background: "white",
      }}
    >
      {/*       <Handle
        type="source"
        position="right"
        style={{
          marginTop: 10,
        }}
      />
      <Handle
        type="target"
        position="left"
        style={{
          marginTop: 10,
        }}
      /> */}
      <CustomHandle
        type={"source"}
        position={"right"}
        tooltip={"Output"}
        color={"#73a580"}
        style={{ marginTop: 10 }}
      />
      <CustomHandle
        type={"target"}
        position={"left"}
        tooltip={"Input"}
        color={"#e1ad01"}
        style={{ marginTop: 10 }}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems={"center"}
        sx={{ background: "blue", height: 30 }}
      >
        <SettingsIcon />
        <Typography>Set Property</Typography>
      </Box>
      <Box sx={{ mt: 2 }}>
        <FormControl fullWidth>
          <select
            style={{
              height: "2rem",
              width: "10rem",
              marginLeft: 20,
              marginTop: 3,
            }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={property}
            label="Event"
            onChange={propertyHandler}
          >
            <option value={10}>Prop 1</option>
            <option value={20}>Prop 2</option>
            <option value={30}>Prop 3</option>
          </select>
        </FormControl>
        <TextField
          size="small"
          value={value}
          variant="outlined"
          onChange={valueHandler}
          sx={{ width: "10rem", marginLeft: 2.4, mt: 1 }}
        />
      </Box>
    </Box>
  );
};

export default PropertyNode;

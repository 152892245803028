import { Handle, Position } from "reactflow";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import BookIcon from "@mui/icons-material/Book";
import FormControl from "@mui/material/FormControl";
import { Typography } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CustomHandle from "../CustomHandle";

const LogNode = () => {
  const [log, setLog] = useState();
  const [chekProperties, setCheckProperties] = useState(false);
  const [chekVariables, setCheckVariables] = useState(false);
  const propertiesChekHandler = () => {
    setCheckProperties(!chekProperties);
  };
  const variablesChekHandler = () => {
    setCheckVariables(!chekVariables);
  };
  const [value, setValue] = useState();
  const logHandler = (e) => {
    setLog(e.target.value);
  };

  const valueHandler = (e) => {
    setValue(e.target.value);
  };
  return (
    <Box
      sx={{
        width: 250,
        height: 190,
        border: "2px solid gray",
        background: "white",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/*      <Handle
        type="source"
        position="right"
        style={{
          marginTop: 10,
        }}
      ></Handle>
      <Handle
        type="target"
        position="left"
        style={{
          marginTop: 10,
        }}
      ></Handle> */}
      <CustomHandle
        type={"target"}
        tooltip={"Input"}
        color={"#e1ad01"}
        position={"left"}
        style={{ marginTop: 10 }}
      />
      <CustomHandle
        type={"source"}
        tooltip={"Output"}
        color={"#73a580"}
        position={"right"}
        style={{ marginTop: 10 }}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems={"center"}
        sx={{ height: 30, background: "green" }}
      >
        <BookIcon />
        <Typography>Log</Typography>
      </Box>
      <Box sx={{ mt: 2 }}>
        <FormControl fullWidth>
          <select
            style={{
              height: "2rem",
              width: "13rem",
              marginLeft: 20,
              marginTop: 3,
            }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={log}
            label="Event"
            onChange={logHandler}
          >
            <option value={10}>log 1</option>
            <option value={20}>log 2</option>
            <option value={30}>log 3</option>
          </select>
        </FormControl>
        <TextField
          size="small"
          value={value}
          variant="outlined"
          onChange={valueHandler}
          sx={{ width: "13rem", marginLeft: 2.4, mt: 1 }}
        />

        <Box
          display={"flex"}
          sx={{ ml: 2, mt: 2 }}
          justifyContent="space-between"
        >
          <FormControlLabel
            control={
              <Checkbox
                onClick={propertiesChekHandler}
                checked={chekProperties}
              />
            }
            label="Properties"
          />
          <FormControlLabel
            control={
              <Checkbox
                onClick={variablesChekHandler}
                checked={chekVariables}
              />
            }
            label="Variables"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default LogNode;

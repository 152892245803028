import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLazyGetSurveyIndexQuery } from "../../redux/slices/surveyApi";
import validator from "@rjsf/validator-ajv6";
import SingleDatePicker from "../form/formComponents/SingleDatePicker";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { prepareParams } from "../../utils";
import Box from "@mui/material/Box";
import { Form } from "@rjsf/mui";
import SurveyIndexTable from "./SurveyIndexTable";
import DeviceWidget from "./DeviceWidget";
import { useDeleteSurveyMutation } from "../../redux/slices/surveyApi";
import { useNavigate } from "react-router-dom";
import { setMessage } from "../../redux/slices/snackbarSlice";
import { useDispatch, useSelector } from "react-redux";
import { surveyActions } from "../../redux/slices/surveySlice";

import DatePicker from "../form/formComponents/DatePicker";
import { useSearchParams } from "react-router-dom";

import DeleteConfirmModal from "../../utils/DeleteConfirmModal";
import UserWidget from "./UserWidget";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
} from "@mui/material";

const ObjectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={2}>
      {props.properties?.map((element) => {
        return (
          <Grid key={element.id} alignItems="center" item xs={6}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
const SurveyIndex = () => {
  const dispatch = useDispatch();
  const [deleteSurvey, { isSuccess: deleteSuccess }] =
    useDeleteSurveyMutation();
  const { t } = useTranslation();
  const survayTrashId = useSelector((state) => state?.surveySlice?.trashId);
  const [trashModal, setTrashModal] = useState(false);
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const [refreshParams, setRefreshParams] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);
  const [page, setPage] = useState(1);
  const [hideAccordion, setHideAccordion] = useState(true);
  const [perPage, setPerPage] = useState(10);
  const [getSurveys] = useLazyGetSurveyIndexQuery();
  const [formData, setFormData] = useState({});
  const statusEnum = [
    { const: "" },
    { const: "created" },
    { const: "downloaded" },
    { const: "started" },
    { const: "paused" },
    { const: "ended" },
  ];
  const onTrashClick = (id) => {
    setIdToDelete(id);
    setTrashModal(true);
  };
  const paramsList = {
    page: page,
    perPage: perPage,
    name: searchParams.get("name") ?? "",
    status: searchParams.get("status") ?? "",
    active_surveys_by_date: searchParams.get("active_surveys_by_date") ?? "",
    device_id: searchParams.get("device_id") ?? "",
    user_id: searchParams.get("user_id") ?? "",
  };
  const accordionToggle = () => {
    setHideAccordion(!hideAccordion);
  };
  useEffect(() => {
    getSurveys({ ...paramsList });
  }, [page]);
  useEffect(() => {
    if (survayTrashId) {
      deleteSurvey(survayTrashId);
      dispatch(surveyActions.setSurvayTrashId(null));
    }
  }, [survayTrashId]);
  useEffect(() => {
    if (deleteSuccess) {
      dispatch(setMessage(t("delete_success")));
      getSurveys({ ...paramsList });
    }
  }, [deleteSuccess]);
  const fields = {
    datepicker: SingleDatePicker,
    users: UserWidget,
    device: DeviceWidget,
  };
  useEffect(() => {
    dispatch(surveyActions.cleanSurvey());
  }, []);
  const uiSchema = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,

    "ui:submitButtonOptions": {
      submitText: t("search"),

      props: {
        className: "bntListSearch",
      },
    },
    active_surveys_by_date: {
      "ui:field": "datepicker",
    },
    user_id: {
      "ui:field": "users",
    },
    device_id: {
      "ui:field": "device",
    },
  };

  const schema = {
    title: "New Device",
    type: "object",
    properties: {
      name: {
        $id: "1",
        type: "string",
        length: "10",
        title: t("name"),
        default: paramsList.name ?? "",
      },
      status: {
        $id: "3",
        type: "string",
        title: t("status"),
        oneOf: [...statusEnum],
        default: paramsList.status ?? "",
      },
      active_surveys_by_date: {
        $id: "3",
        title: t("inserted_at"),
        type: "string",
        default: paramsList.active_surveys_by_date ?? "",
      },

      device_id: {
        $id: "4",
        type: "string",
        title: t("device_id"),
        default: paramsList.device_id ?? "",
      },

      user_id: {
        $id: "8",
        type: "string",
        title: t("user_id"),
        default: paramsList.user_id ?? "",
      },
    },
    /*  required: [
      "key",
      "serial",
      "pod",
      "address",
      "coordinates",
      "tags",
      "time_zone",
      "digital_twin",
    ], */
  };
  useEffect(() => {
    console.log("indexFormData", formData);
  }, [formData]);
  const onSubmitClick = (obj) => {
    const objParams = {
      name: obj.name ?? "",
      status: obj.status ?? "",
      device_id: obj.device_id ?? "",
      user_id: obj.user_id ?? "",
      active_surveys_by_date: formData.active_surveys_by_date ?? "",
    };
    const params = prepareParams(objParams);
    navigate({
      pathname: "/surveys",
      search: params.toString(),
    });
    setPage(1);
    setRefreshParams(true);
  };
  useEffect(() => {
    if (refreshParams) {
      getSurveys({ ...paramsList });
      setRefreshParams(false);
    }
  }, [refreshParams]);
  useEffect(() => {
    console.log("frmdatushka", formData);
  }, [formData]);
  return (
    <Box>
      <DeleteConfirmModal
        trashModal={trashModal}
        setTrashModal={setTrashModal}
        text={t("survey_delete_text")}
        title={t("survey_delete_title")}
        deleteFunc={() => surveyActions.setSurvayTrashId(idToDelete)}
      />
      <Accordion>
        <AccordionSummary
          onClick={accordionToggle}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          Search form
        </AccordionSummary>
        <AccordionDetails sx={{ mb: 5 }}>
          <Form
            schema={schema}
            uiSchema={uiSchema}
            fields={fields}
            formData={formData}
            onSubmit={(values) => {
              onSubmitClick(values.formData);
            }}
            onChange={(changeEvent) => setFormData(changeEvent.formData)}
            validator={validator}
            /*    customValidate={customValidate}
          showErrorList={false}
          noHtml5Validate */
          />
        </AccordionDetails>
      </Accordion>
      <Box sx={{ mt: 5 }}>
        <SurveyIndexTable
          page={page}
          perPage={perPage}
          setPage={setPage}
          hideAccordion={hideAccordion}
          onTrashClick={onTrashClick}
        />
      </Box>
    </Box>
  );
};

export default SurveyIndex;

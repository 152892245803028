import * as React from "react";
import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import Chip from "@mui/material/Chip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material";
import { Avatar, TextField } from "@material-ui/core";
import InfoIcon from "@mui/icons-material/Info";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import GatewayMessagesDetails from "./GatewayMessagesDetails";
import ModalDialog from "../meters/ModalDialog";
import DetailsIcon from "@mui/icons-material/Details";
import { StyledDataGrid } from "../../utils/StyledDataGrid";
import theme from "../../theme";
import { secondsToDhms } from "../../utils/api_params";
const GatewayMessages = ({ page, setPage, perPage, twinId }) => {
  const [open, setOpen] = useState(false);
  const [meters, setMeters] = useState([]);
  const messages = useSelector((state) => state?.gatewaySlice?.messages);
  const messagesMeta = useSelector(
    (state) => state?.gatewaySlice?.messagesMeta
  );

  const { t } = useTranslation();
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },

    {
      field: "battery_level",
      headerName: t("battery_level"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
      headerAlign: "left",
    },
    {
      field: "gateway_status",
      headerName: t("gateway_status"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
      headerAlign: "left",
    },
    {
      field: "network_quality",
      headerName: t("network_quality"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      headerAlign: "left",
    },
    /*     {
      field: "uptime",
      headerName: t("uptime"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      cellClassName: "word-wrap-cell",
    }, */

    {
      field: "last_message_type",
      headerName: t("last_message_type"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
      headerAlign: "left",
    },

    {
      field: "network_technology_in_use",
      headerName: t("network_technology_in_use"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
      headerAlign: "left",
    },
    {
      field: "handled_meters",
      headerName: t("handled_meters"),
      type: "тгьиук",
      sortable: false,
      headerAlign: "left",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography
            fontWeight="bold"
            sx={{ color: theme.palette.primary.main }}
          >
            {params?.value}
          </Typography>
        );
      },
    },
    {
      field: "unhandled_meters",
      headerName: t("unhandled_meters"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography
            fontWeight="bold"
            sx={{ color: theme.palette.primary.main }}
          >
            {params?.value}
          </Typography>
        );
      },
    },
    {
      field: "number_of_devices_seen_last_communication",
      headerName: t("number_of_devices_seen_last_communication"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
      headerAlign: "left",
      renderCell: (params) => {
        return (
          <Typography
            fontWeight="bold"
            sx={{ color: theme.palette.primary.main }}
          >
            {params?.value}
          </Typography>
        );
      },
    },
    {
      field: "alarms",
      headerName: t("alarms"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      editable: false,
      headerAlign: "left",
      renderCell: (params) => {
        if (params.value !== "--") {
          return params.value.map((item) => (
            <Chip
              sx={{
                background: theme.palette.error.light,
                color: theme.palette.error.contrastText,
              }}
              label={item}
            />
          ));
        }
      },
    },

    {
      field: "message_date",
      headerName: t("message_date"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
      width: 100,
      headerAlign: "left",
      wrapText: true,
    },
  ];
  const tableRows = messages?.map((item, i) => ({
    id: i,
    battery_level: item?.attributes?.values?.values.battery_level
      ? `${item?.attributes?.values?.values.battery_level}%`
      : "--",
    gateway_status: item?.attributes?.values?.values.gateway_status ?? "--",
    last_message_type:
      item?.attributes?.values?.values.last_message_type ?? "--",

    network_technology_in_use:
      item?.attributes?.values?.values.network_technology_in_use ?? "--",
    handled_meters:
      item?.attributes?.values?.values?.last_message_type === "data"
        ? item?.attributes?.values?.values.handled_meters?.length
        : "--",
    unhandled_meters:
      item?.attributes?.values?.values?.last_message_type === "data"
        ? item?.attributes?.values?.values.unhandled_meters?.length
        : "--",
    number_of_devices_seen_last_communication:
      item?.attributes?.values?.values
        ?.number_of_devices_seen_last_communication ?? "--",
    alarms: item?.values?.values?.alarms ?? "--",
    network_quality:
      item?.attributes?.values?.values?.network_quality &&
      item?.attributes?.values?.values?.network_quality_type
        ? `${item?.attributes?.values?.values?.network_quality} ${item?.attributes?.values?.values?.network_quality_type}`
        : "--",
    message_date: item?.attributes?.message_date
      ? t("date_val", {
          val: new Date(item?.attributes?.message_date),
        })
      : "--",
  }));
  const closeModal = () => {
    setOpen(false);
  };
  const onDetailClick = (meters) => {
    console.log("mmmemeters", meters);

    const transformedHandledMeters = meters?.handled_meters?.map((item) => ({
      ...item,
      handled: true,
    }));
    const transformedUnhandledMeters = meters?.unhandled_meters?.map(
      (item) => ({
        ...item,
        handled: false,
      })
    );
    setMeters([...transformedHandledMeters, ...transformedUnhandledMeters]);
    setOpen(true);
  };
  useEffect(() => {
    console.log("trsnfrmdmtrs", meters);
  }, [meters]);
  const columnsTest = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },

    {
      field: "battery_level",
      headerName: t("battery_level"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
      headerAlign: "left",
    },
    {
      field: "gateway_status",
      headerName: t("gateway_status"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
      headerAlign: "left",
    },
    {
      field: "network_quality",
      headerName: t("network_quality"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      headerAlign: "left",
    },
    /*     {
      field: "uptime",
      headerName: t("uptime"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      cellClassName: "word-wrap-cell",
    }, */

    {
      field: "last_message_type",
      headerName: t("last_message_type"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
      headerAlign: "left",
    },

    {
      field: "network_technology_in_use",
      headerName: t("network_technology_in_use"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
      headerAlign: "left",
    },
    {
      field: "handled_meters",
      headerName: t("handled_meters"),
      type: "тгьиук",
      sortable: false,
      headerAlign: "left",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography
            fontWeight="bold"
            sx={{ color: theme.palette.primary.main }}
          >
            {params?.value}
          </Typography>
        );
      },
    },
    {
      field: "unhandled_meters",
      headerName: t("unhandled_meters"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography
            fontWeight="bold"
            sx={{ color: theme.palette.primary.main }}
          >
            {params?.value}
          </Typography>
        );
      },
    },
    {
      field: "number_of_devices_seen_last_communication",
      headerName: t("number_of_devices_seen_last_communication"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
      headerAlign: "left",
      renderCell: (params) => {
        return (
          <Typography
            fontWeight="bold"
            sx={{ color: theme.palette.primary.main }}
          >
            {params?.value}
          </Typography>
        );
      },
    },
    {
      field: "alarms",
      headerName: t("alarms"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      editable: false,
      headerAlign: "left",
      renderCell: (params) => {
        if (params.value !== "--") {
          return params.value.map((item) => (
            <Chip
              sx={{
                background: theme.palette.error.light,
                color: theme.palette.error.contrastText,
              }}
              label={item}
            />
          ));
        }
      },
    },

    {
      field: "message_date",
      headerName: t("message_date"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
      width: 100,
      headerAlign: "left",
      wrapText: true,
    },
    {
      field: "details",
      headerName: t("details"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
      width: 100,
      headerAlign: "left",
      renderCell: (params) => {
        return (
          <IconButton
            disabled={
              params.value.handled_meters?.length == 0 &&
              params.value.unhandled_meters?.length == 0
            }
            onClick={() => onDetailClick(params.value)}
          >
            <InfoIcon
              sx={{
                fill:
                  params.value.handled_meters?.length > 0 ||
                  params.value.unhandled_meters?.length > 0
                    ? theme.palette.primary.main
                    : theme.palette.warning.main,
              }}
            />
          </IconButton>
        );
      },
    },
  ];
  const tableRowsTest = messages?.map((item, i) => ({
    id: i,
    battery_level: item?.attributes?.values?.values.battery_level
      ? `${item?.attributes?.values?.values.battery_level}%`
      : "--",
    gateway_status: item?.attributes?.values?.values.gateway_status ?? "--",
    last_message_type:
      item?.attributes?.values?.values.last_message_type ?? "--",

    network_technology_in_use:
      item?.attributes?.values?.values.network_technology_in_use ?? "--",
    handled_meters:
      item?.attributes?.values?.values?.last_message_type === "data"
        ? item?.attributes?.values?.values.handled_meters?.length
        : "--",
    unhandled_meters:
      item?.attributes?.values?.values?.last_message_type === "data"
        ? item?.attributes?.values?.values.unhandled_meters?.length
        : "--",
    number_of_devices_seen_last_communication:
      item?.attributes?.values?.values
        ?.number_of_devices_seen_last_communication ?? "--",
    alarms: item?.values?.values?.alarms ?? "--",
    network_quality:
      item?.attributes?.values?.values?.network_quality &&
      item?.attributes?.values?.values?.network_quality_type
        ? `${item?.attributes?.values?.values?.network_quality} ${item?.attributes?.values?.values?.network_quality_type}`
        : "--",
    message_date: item?.attributes?.message_date
      ? t("date_val", {
          val: new Date(item?.attributes?.message_date),
        })
      : "--",
    details: {
      handled_meters: item?.attributes?.values?.values.handled_meters,
      unhandled_meters: item?.attributes?.values?.values.unhandled_meters,
    },
  }));
  return (
    <>
      <ModalDialog open={open} close={closeModal} title={"Details"}>
        <GatewayMessagesDetails meters={meters} twinId={twinId} />
      </ModalDialog>
      <Box sx={{ height: "60vh", width: "99%" }}>
        <StyledDataGrid
          disableColumnMenu
          rows={/* twinId == 62 ? */ tableRowsTest ?? [] /* : tableRows */}
          columns={/* twinId == 62 ?  */ columnsTest ?? [] /* : columns */}
          rowCount={messagesMeta}
          pageSize={perPage}
          rowsPerPageOptions={[perPage]}
          disableSelectionOnClick
          onPageChange={(pageNumber) => {
            /*  !response.isFetching &&  */
            setPage(pageNumber + 1);
          }}
          page={page - 1}
          // loading={isLoadingTable}
          paginationMode="server"
          /*    getRowHeight={() => "auto"} */
        />
      </Box>
    </>
  );
};
export default GatewayMessages;

import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import ShareIcon from "@mui/icons-material/Share";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import AddIcon from "@mui/icons-material/Add";
import { CustomTab } from "./styledComponents/StyledComponents";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import SaveIcon from "@mui/icons-material/Save";
import PropertiesTab from "./PropertiesTab";
import BuildIcon from "@mui/icons-material/Build";
import DigitalTwinAttributesShow from "./DigitalTwinAttributesShow";
import RefreshIcon from "@mui/icons-material/Refresh";
import DigitalTwinShow from "../digital_twins/DigitalTwinShow";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import DigitalTwinTab from "./DigitalTwinTab";
import { useDispatch, useSelector } from "react-redux";
import VariablesTab from "./VariablesTab";
import DigitalTwins from "../digital_twins/DigitalTwins";
import RecordsTab from "./RecordsTab";
import { getNodesFromLocalStorage } from "../../redux/slices/nodeControllerSlice";
import { useSaveNodesInLocalStorageMutation } from "../../redux/slices/nodeControllerApi";
import { Box } from "@mui/system";
import { setSwarmAppModal } from "../../redux/slices/nodeControllerSlice";
import { useState } from "react";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const ConfigMenu = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(1);
  const [saveNodes] = useSaveNodesInLocalStorageMutation();
  const digitalTwinShow = useSelector(
    (state) => state?.digitalTwinSlice?.digitalTwinShow
  );
  const appBarIsOpen = useSelector(
    (state) => state?.utilsSlice?.headerAppBarOpen
  );
  const digitalTwinName = useSelector(
    (state) => state?.digitalTwinSlice?.digitalTwinName
  );
  const nodes = useSelector((state) => state?.nodeControllerSlice.nodeList);
  const edges = useSelector((state) => state?.nodeControllerSlice?.edgeList);
  const tabs = useSelector((state) => state?.nodeControllerSlice?.tabs);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const onAdttClick = () => {
    switch (value) {
      case 0:
        dispatch(setSwarmAppModal("properties"));
        break;
      case 1:
        dispatch(setSwarmAppModal("device_twin"));
        break;
      case 2:
        dispatch(setSwarmAppModal("variables"));
        break;
      case 3:
        dispatch(setSwarmAppModal("records"));
        break;
    }
  };
  const onSaveClick = () => {
    saveNodes({
      tabs: tabs,
      nodes: nodes,
      edges: edges,
    });
  };
  const { t } = useTranslation();
  return (
    <Box sx={{ width: "35%" }}>
      <Divider />
      <Box display="flex" justifyContent={"space-between"}>
        <IconButton onClick={() => dispatch(getNodesFromLocalStorage())}>
          <Tooltip title={t("connect")}>
            <ShareIcon />
          </Tooltip>
        </IconButton>
        <IconButton>
          <Tooltip title={t("inspect")}>
            <QueryStatsIcon />
          </Tooltip>
        </IconButton>
        <IconButton onClick={onAdttClick}>
          <Tooltip title={t("new")}>
            <AddIcon />
          </Tooltip>
        </IconButton>
        <IconButton>
          <Tooltip title={t("load")}>
            <UploadFileIcon />
          </Tooltip>
        </IconButton>
        <IconButton onClick={onSaveClick}>
          <Tooltip title={t("save")}>
            <SaveIcon />
          </Tooltip>
        </IconButton>
        <IconButton>
          <Tooltip title={t("build")}>
            <BuildIcon />
          </Tooltip>
        </IconButton>
        <IconButton>
          <Tooltip title={t("refresh")}>
            <RefreshIcon />
          </Tooltip>
        </IconButton>
        <IconButton>
          <Tooltip title={t("run")}>
            <PlayCircleOutlineIcon />
          </Tooltip>
        </IconButton>
        <IconButton>
          <Tooltip title={t("testing")}>
            <EditIcon />
          </Tooltip>
        </IconButton>
      </Box>
      <Divider />
      <Box>
        <Box display={"flex"} justifyContent={"center"}>
          <Tabs value={value} onChange={handleChange}>
            <CustomTab label={t("properties")} />
            <CustomTab label={t("device_twin")} />
            <CustomTab label={t("variables")} />
            <CustomTab label={t("records")} />
          </Tabs>
        </Box>
        <Divider />
        <TabPanel value={value} index={0}>
          <PropertiesTab
            height={/* appBarIsOpen ? "48vh" :  */ "55vh"}
            width={appBarIsOpen ? "20vw" : "25.3vw"}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box>
            <Box sx={{ width: appBarIsOpen ? "20vw" : "25.3vw" }}>
              <DigitalTwinTab
                height={/* appBarIsOpen ? "48vh" : */ "56vh"}
                width={appBarIsOpen ? "20vw" : "25.3vw"}
              />
            </Box>
            <Box sx={{ ml: appBarIsOpen ? 15 : 20 }}>
              {digitalTwinShow && (
                <DigitalTwinAttributesShow
                  height={/* appBarIsOpen ? "48vh" :  */ "56vh"}
                  width={appBarIsOpen ? "20vw" : "25.3vw"}
                />
              )}
            </Box>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <VariablesTab
            height={/* appBarIsOpen ? "48vh" :  */ "55vh"}
            width={appBarIsOpen ? "20vw" : "25.3vw"}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <RecordsTab
            height={/* appBarIsOpen ? "48vh" : */ "51vh"}
            width={appBarIsOpen ? "20vw" : "25.3vw"}
          />
        </TabPanel>
      </Box>
    </Box>
  );
};

export default ConfigMenu;

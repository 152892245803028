import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
} from "@mui/material";
import { Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import FormGroup from "@mui/material/FormGroup";
import { useDispatch, useSelector } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import theme from "../../../theme";
import FormControlLabel from "@mui/material/FormControlLabel";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import Checkbox from "@mui/material/Checkbox";
const KeyWidget = (props) => {
  const open = useSelector((state) => state?.utilsSlice?.headerAppBarOpen);
  const [singleKeyValue, setSingleKeyValue] = useState("");
  const [multiple, setMultiple] = useState(false);
  const [newKey, setNewKey] = useState(false);
  const [keys, setKeys] = useState([]);
  const [key, setKey] = useState(null);
  const handleSingleValue = (e) => {
    setSingleKeyValue(e.target.value);
  };
  const handleMultiple = () => {
    setMultiple(!multiple);
  };
  const handleKeyUpdate = (index, newValue) => {
    const updatedKeys = [...keys];

    updatedKeys[index] = newValue;

    setKeys(updatedKeys);
  };
  const handleRemoveKey = (index) => {
    setKeys((prevKeys) => prevKeys.filter((_, i) => i !== index));
  };

  useEffect(() => {
    if (!multiple && setSingleKeyValue) {
      props.onChange([singleKeyValue]);
    }
  }, [singleKeyValue, multiple]);
  useEffect(() => {
    if (multiple) {
      props.onChange(keys);
    }
  }, [multiple, keys]);
  useEffect(() => {
    console.log(keys);
  }, [keys]);
  const onSaveClick = () => {
    setKeys((prev) => [...prev, key]);
    setKey(null);
    setNewKey(false);
  };
  return (
    <Box>
      {!multiple && (
        <Box display={"flex"}>
          <TextField
            value={singleKeyValue}
            onChange={(e) => handleSingleValue(e)}
            fullWidth
            label="key"
          />
          <Tooltip title={"Multiple"}>
            <FormControlLabel
              sx={{ ml: 2 }}
              control={
                <Checkbox
                  onClick={handleMultiple}
                  checked={multiple}
                  value={multiple}
                />
              }
            />
          </Tooltip>
        </Box>
      )}
      {multiple && (
        <Box display={"flex"} alignItems={"top"}>
          <Accordion sx={{ width: "100%" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              Multiple keys
            </AccordionSummary>
            <AccordionDetails>
              <>
                {keys?.map((item, i) => (
                  <Box sx={{ mt: 1 }} display={"flex"} alignItems={"center"}>
                    <TextField
                      onChange={(e) => handleKeyUpdate(i, e.target.value)}
                      fullWidth
                      value={item}
                    />
                    <IconButton onClick={() => handleRemoveKey(i)}>
                      <DeleteIcon
                        sx={{ ml: 1, fill: theme.palette.error.main }}
                      />
                    </IconButton>
                  </Box>
                ))}
                {!newKey && keys?.length > 0 && (
                  <IconButton
                    onClick={() => setNewKey(true)}
                    sx={{ float: "right", mt: 1, mb: 1 }}
                  >
                    <AddIcon />
                  </IconButton>
                )}
                {(newKey || keys?.length == 0) && (
                  <Box display={"flex"} alignItems={"center"}>
                    <TextField
                      sx={{ mt: 2 }}
                      onChange={(e) => setKey(e.target.value)}
                      value={key}
                      label={"Key"}
                      fullWidth
                    />
                    <IconButton onClick={onSaveClick}>
                      <DoneIcon sx={{ fill: theme.palette.success.main }} />
                    </IconButton>
                    <IconButton onClick={() => setNewKey(false)}>
                      <CloseIcon sx={{ fill: theme.palette.error.main }} />
                    </IconButton>
                  </Box>
                )}
              </>
            </AccordionDetails>
          </Accordion>
          <FormControlLabel
            sx={{ ml: 2 }}
            control={
              <Checkbox
                onClick={handleMultiple}
                checked={multiple}
                value={multiple}
              />
            }
          />
        </Box>
      )}
    </Box>
  );
};

export default KeyWidget;

import * as React from "react";
import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { useLazyGetUsersQuery } from "../../redux/slices/userApi";
import { useDispatch, useSelector } from "react-redux";
import { useLazyGetMetersQuery } from "../../redux/slices/meterApi";
import debounce from "lodash.debounce";
const DeviceWidget = (props) => {
  const defaultProp = props?.schema?.default;
  const err = props?.rawErrors?.[0];
  const update = props?.schema?.update;
  const [searchValue, setSearchValue] = useState("");
  const [firstRender, setFirstRender] = useState(true);
  const [defaultValue, setDefaultValue] = useState(null);
  const meters = useSelector((state) => state?.meterSlice?.meters);
  const mappedMeters = meters?.map((meter) => ({
    label: meter?.attributes?.serial_number,
    id: meter?.id,
  }));

  const [getMeters] = useLazyGetMetersQuery();
  const [value, setValue] = useState();

  useEffect(() => {
    if (mappedMeters && defaultProp && firstRender) {
      setDefaultValue(
        mappedMeters?.filter((item) => item.id == defaultProp)?.[0]
      );
      setFirstRender(false);
    }
  }, [defaultProp, mappedMeters]);

  useEffect(() => {
    if (!meters) {
      getMeters();
    }
  }, []);
  useEffect(() => {
    if (value) {
      props.onChange(value.id);
    } else {
      props.onChange("");
    }
  }, [value]);
  const debValue = debounce((e) => {
    setSearchValue(e?.target?.value);
  }, 500);

  useEffect(() => {
    if (searchValue) {
      getMeters({ serial_number: searchValue });
    }
  }, [searchValue]);

  useEffect(() => {
    console.log("parapa", props);
  }, [props]);
  const getMetersOnClick = () => {
    console.log("picdsadas", meters);
    if (!meters || meters.length == 0 || meters.length == 1) {
      getMeters();
    }
  };
  return (
    <>
      {mappedMeters && !update && (
        <Autocomplete
          fullWidth
          disablePortal
          onOpen={getMetersOnClick}
          /* value={value} */
          id="combo-box-demo"
          options={mappedMeters}
          onChange={(event, newInputValue) => {
            setValue(newInputValue);
          }}
          defaultValue={defaultValue}
          renderInput={(params) => (
            <TextField
              onChange={(e) => debValue(e)}
              error={err}
              {...params}
              label="Device"
            />
          )}
        />
      )}
      {mappedMeters && update && defaultValue && (
        <Autocomplete
          fullWidth
          disablePortal
          onOpen={getMetersOnClick}
          id="combo-box-demo"
          options={mappedMeters}
          onChange={(event, newInputValue) => {
            setValue(newInputValue);
          }}
          onInputChange={(e) => debValue(e)}
          defaultValue={defaultValue}
          renderInput={(params) => (
            <TextField error={err} {...params} label="Device" />
          )}
        />
      )}
      {!mappedMeters && (
        <Autocomplete
          fullWidth
          disablePortal
          disabled
          id="combo-box-demo"
          options={[]}
          renderInput={(params) => <TextField {...params} label="Device" />}
        />
      )}
    </>
  );
};
export default DeviceWidget;

import { useState, useCallback, useEffect } from "react";
import EventNode from "./CustomNides/EventNode";
import { useDispatch, useSelector } from "react-redux";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { useLazyGetNodesFromLocalStorageQuery } from "../../redux/slices/nodeControllerApi";
import NodeModal from "./NodeModal";
import CloseIcon from "@mui/icons-material/Close";
import ConfigMenu from "./ConfigMenu";
import theme from "../../theme";
import { ThemeProvider } from "@mui/material";
import SwarmAppAutocomlete from "./utilsComponents/SwarmAppAutocomlete";
import {
  updateNodes,
  updateEdges,
  onConnect,
  onAddTab,
  onTabChange,
  onRemoveTab,
  setActualTabName,
  getNodesFromLocalStorage,
} from "../../redux/slices/nodeControllerSlice";
import ConditionNode from "./CustomNides/ConditionNode";
import PropertyNode from "./CustomNides/PropertyNode";
import LogNode from "./CustomNides/LogNode";
import NodeSidebar from "./NodeSidebar";
import Divider from "@mui/material/Divider";
import AddIcon from "@mui/icons-material/Add";
import ReactFlow, {
  Controls,
  Background,
  applyNodeChanges,
  ReactFlowProvider,
  applyEdgeChanges,
  addEdge,
} from "reactflow";
import "reactflow/dist/base.css";
import { CustomAutocomlete } from "./styledComponents/StyledComponents";
import { Box } from "@mui/system";
import { Button } from "@mui/material";
import { IconButton } from "@material-ui/core";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const nodeTypes = {
  event: EventNode,
  condition: ConditionNode,
  log: LogNode,
  property: PropertyNode,
};
const initialEdges = [];

const SwarmApp = () => {
  const dispatch = useDispatch();
  const [lol, setLol] = useState([]);
  const [getNodes] = useLazyGetNodesFromLocalStorageQuery();
  const nodes = useSelector((state) => state?.nodeControllerSlice.nodeList);
  const edges = useSelector((state) => state?.nodeControllerSlice?.edgeList);
  const tabs = useSelector((state) => state?.nodeControllerSlice?.tabs);
  const tabId = useSelector((state) => state?.nodeControllerSlice?.tabId);
  const dataIsReady = useSelector(
    (state) => state?.nodeControllerSlice?.dataIsReady
  );
  const tabname = useSelector(
    (state) => state?.nodeControllerSlice?.actualTabName
  );

  const onNodesChange = useCallback((changes) =>
    dispatch(updateNodes({ changes: changes }))
  );
  const onEdgesChange = useCallback((changes) =>
    dispatch(updateEdges({ changes: changes }))
  );
  const onEdgeConnect = useCallback((params) =>
    dispatch(onConnect({ params: params }))
  );
  const handleChange = (event, newValue) => {
    dispatch(onTabChange(newValue));
  };
  useEffect(() => {
    setTimeout(() => {
      dispatch(getNodesFromLocalStorage());
    }, 1200);
  }, []);
  useEffect(() => {
    if (tabs?.length > 0) {
      const tabName = tabs.filter((item, i) => i === tabId);
      dispatch(setActualTabName(tabName));
    }
  }, [tabs, tabId]);
  const proOptions = { hideAttribution: true };
  return (
    <Box sx={{ height: "80vh" }}>
      <Box display={"flex"} alignItems="center" sx={{ width: "80vw" }}>
        <Tabs variant="scrollable" value={tabId} onChange={handleChange}>
          {tabs?.map((item, i) => (
            <Tab
              label={
                <Box display={"flex"} alignItems="center">
                  {item}
                  <IconButton onClick={() => dispatch(onRemoveTab(i))}>
                    <CloseIcon sx={{ height: "1rem" }} />
                  </IconButton>
                </Box>
              }
            ></Tab>
          ))}
        </Tabs>

        <NodeModal />
      </Box>
      <Box display="flex" sx={{ height: "100%" }}>
        <NodeSidebar />
        <Divider orientation="vertical" sx={{ height: "95%" }} />

        <Box sx={{ width: "85%" }}>
          {tabs?.map((item, i) => (
            <TabPanel value={tabId} index={i}>
              <Box sx={{ height: "70vh" }}>
                <ReactFlowProvider style={{ height: "500px" }}>
                  <ReactFlow
                    /*  style={{ marginLeft: 15 }} */
                    nodes={nodes[tabname]}
                    edges={edges[tabname]}
                    onNodesChange={onNodesChange}
                    proOptions={proOptions}
                    onEdgesChange={onEdgesChange}
                    onConnect={onEdgeConnect}
                    nodeTypes={nodeTypes}
                    captureClick={true}
                  >
                    <Background />
                    <Controls
                      showZoom={false}
                      showFitView={false}
                      showInteractive={false}
                    />
                  </ReactFlow>
                </ReactFlowProvider>
              </Box>
            </TabPanel>
          ))}
        </Box>
        <Divider orientation="vertical" sx={{ height: "93%" }} />
        <ConfigMenu />
      </Box>
    </Box>
  );
};

export default SwarmApp;

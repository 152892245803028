import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import AntennasRegistry from "./AntennasRegistry";
import AntennasMessages from "./AntennasMessages";
import { useLazyGetDataQuery } from "../../redux/slices/GetDataRtq";
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};
const AntennasShow = () => {
  const [value, setValue] = useState(0);
  const { t } = useTranslation();
  const { id } = useParams();
  const [trigger, result, lastPromiseInfo, isSuccess] = useLazyGetDataQuery();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    trigger(`altior/digital_twin/0/instance/${id}`);
  }, []);
  return (
    <>
      {result?.data?.data && (
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleChange}>
              <Tab label={t("registry")} />
              <Tab label={t("readings")} />
              <Tab label={t("map")} />
            </Tabs>
            <TabPanel value={value} index={0}>
              <AntennasRegistry result={result} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <AntennasMessages meter={result?.data?.data} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Box display={"flex"} width={"100%"}>
                <Box sx={{ width: "50%" }}>
                  <AntennasMessages meter={result?.data?.data} mapTab={true} />
                </Box>
              </Box>
            </TabPanel>
          </Box>
        </Box>
      )}
    </>
  );
};

export default AntennasShow;

import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { TextareaAutosize } from "@mui/base";
import { useSelector, useDispatch } from "react-redux";
import { setMessage } from "../../redux/slices/snackbarSlice";
import { useTranslation } from "react-i18next";
import { setSeverity } from "../../redux/slices/snackbarSlice";
import FormControl from "@mui/material/FormControl";
import { useParams } from "react-router-dom";
import Select from "@mui/material/Select";
import { usePostCommandsMutation } from "../../redux/slices/meterApi";
import { useLazyGetCommandsQuery } from "../../redux/slices/meterApi";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField, Typography } from "@mui/material";

const GatewayCommands = ({ digitalTwinId }) => {
  const commands = useSelector((state) => state?.meterSlice?.commands)?.map(
    (item) => ({
      label: item?.attributes?.name,
      type: item?.attributes?.type,
    })
  );
  const { t } = useTranslation();
  const [getCommands, { isSuccess: getCommandsSuccess }] =
    useLazyGetCommandsQuery();
  const [postCommands, { isSuccess: postSuccess }] = usePostCommandsMutation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [commandName, setCommandName] = React.useState("");
  const [commandValue, setCommandValue] = React.useState("");
  const handleaNameChange = (e) => {
    setCommandName(e.target.value);
  };
  const handleValueChange = (e) => {
    setCommandValue(e.target.value);
  };
  const validateJson = (obj) => {
    if (commandName.type !== "void") {
      try {
        const jsonObject = JSON.parse(obj);
        const attributes = {
          values: JSON.parse(commandValue),
        };
        postCommands({
          attributes: attributes,
          commandName: commandName.label,
          digitalTwinId: digitalTwinId,
          digitalTwinInstance: id,
        });
      } catch (error) {
        console.error("Invalid JSON:", error.message);
        dispatch(setSeverity("warning"));
        dispatch(setMessage("JSON NOT VALID"));
      }
    } else {
      const attributes = {
        values: {},
      };
      postCommands({
        attributes: attributes,
        commandName: commandName.label,
        digitalTwinId: digitalTwinId,
        digitalTwinInstance: id,
      });
    }
  };
  React.useEffect(() => {
    if (postSuccess) {
      dispatch(setSeverity("success"));
      dispatch(setMessage("Command sent"));
    }
  }, [postSuccess]);
  const onSubmitClick = () => {
    validateJson(commandValue);
  };
  React.useEffect(() => {
    console.log("comix", commands);
  }, [commands]);
  React.useEffect(() => {
    getCommands(digitalTwinId);
  }, [digitalTwinId]);
  return (
    <>
      {commands && (
        <Box sx={{ mt: 4, height: "58vh" }}>
          {/*    <TextField
          sx={{ width: "100%" }}
          value={commandName}
          label={"Command name"}
          onChange={(e) => handleaNameChange(e)}
        /> */}
          <Autocomplete
            fullWidth
            disablePortal
            value={commandName}
            onChange={(event, newValue) => {
              setCommandName(newValue);
            }}
            id="combo-box-demo"
            options={commands ?? []}
            renderInput={(params) => <TextField {...params} label="Command" />}
          />
          <Box sx={{ height: "48vh", overflowY: "auto", marginTop: 5 }}>
            <TextareaAutosize
              value={commandValue}
              disabled={commandName.type === "void"}
              minRows={30}
              onChange={(e) => handleValueChange(e)}
              style={{
                width: "100%",
                marginTop: 5,

                resize: "none",
              }}
              /*  placeholder="Command value" */
              label="Command value"
            />
          </Box>
          <Button
            disabled={!commandName}
            onClick={onSubmitClick}
            sx={{ float: "right" }}
            variant="contained"
          >
            {t("send_command")}
          </Button>
        </Box>
      )}
    </>
  );
};
export default GatewayCommands;

import { useParams } from "react-router-dom";
import {
  useLazyGetDigitalTwinShowQuery,
  useLazyGetDigitalTwinsQuery,
} from "../../redux/slices/digitalTwinApi";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { Chip } from "@material-ui/core";
import Tooltip from "@mui/material/Tooltip";

import SwarmAppAutocomlete from "./utilsComponents/SwarmAppAutocomlete";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import ModalDialog from "../meters/ModalDialog";
import { CustomMenuItem } from "./styledComponents/StyledComponents";
import TextField from "@mui/material/TextField";
import DoneIcon from "@mui/icons-material/Done";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { Button } from "@mui/material";
import {
  addNewRecordSection,
  removeRecordSection,
  setSelectedRecord,
  setSwarmAppModal,
  addRecordsVariables,
} from "../../redux/slices/nodeControllerSlice";
import theme from "../../theme";
import { setDigitalTwinAttributes } from "../../redux/slices/digitalTwinSlice";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
const RecordsTab = ({ height, width }) => {
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const swarmOpen = useSelector((state) => state?.nodeControllerSlice?.modal);
  const [recordName, setRecordname] = useState("");
  const [field, setField] = useState();
  const [type, setType] = useState("");
  const existingProperty = rows?.find((item) => item.field === field);

  const selectedRecord = useSelector(
    (state) => state?.nodeControllerSlice?.selectedRecord
  );
  const selectedRecordsValues = useSelector(
    (state) => state?.nodeControllerSlice?.records
  )?.[selectedRecord];
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const records = Object.keys(
    useSelector((state) => state?.nodeControllerSlice?.records)
  ).map((item) => ({
    label: item,
    value: item,
  }));
  const existingRecord = records?.find((item) => item.label === recordName);
  const allRecords = Object.keys(
    useSelector((state) => state?.nodeControllerSlice?.records)
  );
  const indexOfSelectedRecord = allRecords?.findIndex(
    (item) => item === selectedRecord
  );
  useEffect(() => {
    setRows(selectedRecordsValues?.length > 0 ? selectedRecordsValues : []);
  }, [selectedRecord]);
  useEffect(() => {
    console.log("recorrdsisherenow", selectedRecordsValues);
  }, [selectedRecordsValues]);
  const onNameChange = (e) => {
    setRecordname(e.target.value);
  };
  const onDoneClick = () => {
    if (!existingRecord) {
      dispatch(addNewRecordSection(recordName));
      dispatch(setSelectedRecord(recordName));
      setOpen(false);
      setRecordname("");
    }
  };
  const onDeleteClick = () => {
    dispatch(removeRecordSection(selectedRecord));
    const prevIndex = indexOfSelectedRecord - 1;
    const nextIndex = indexOfSelectedRecord + 1;
    dispatch(
      setSelectedRecord(
        allRecords[indexOfSelectedRecord == 0 ? nextIndex : prevIndex]
      )
    );
  };
  const deleteRow = (id) => {
    debugger;
    const updatedRows = rows.filter((row) => row.id !== id);
    setRows(updatedRows);
  };
  const updateRow = (id, fieldName, newValue) => {
    const rowIndex = rows.findIndex((row) => row.id === id);

    if (rowIndex !== -1) {
      debugger;
      const updatedRows = [...rows];
      updatedRows[rowIndex] = {
        ...updatedRows[rowIndex],
        [fieldName]: newValue,
      };
      setRows(updatedRows);
    }
  };
  const onFieldChange = (e) => {
    setField(e.target.value);
  };
  const onTypeChange = (e) => {
    setType(e.target.value);
  };
  const onAddRecordVariables = () => {
    if (!existingProperty) {
      const newRow = {
        id: selectedRecordsValues?.length + 1,
        field: field,
        type: type,
      };
      setRows([...rows, newRow]);
      setField("");
      setType("");
      dispatch(setSwarmAppModal(false));
    }
  };
  useEffect(() => {
    if (rows) {
      dispatch(addRecordsVariables(rows));
    }
  }, [rows]);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 3,
      hide: true,
    },
    {
      field: "field",
      headerName: t("field"),
      type: "string",
      sortable: false,
      editable: true,
      flex: 4,
    },

    {
      field: "type",
      headerName: t("type"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 3,
      renderCell: (params) => {
        if (params.value === undefined) {
          return <>--</>;
        } else {
          return (
            <>
              <Chip
                /*     style={{
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                }} */
                label={params.value}
              />
            </>
          );
        }
      },
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      renderCell: (params) => {
        return (
          <Box>
            <IconButton onClick={() => deleteRow(params.row.id)}>
              <Tooltip title={t("delete_row")}>
                <DeleteIcon sx={{ fill: theme.palette.error.main }} />
              </Tooltip>
            </IconButton>
          </Box>
        );
      },
    },
  ];
  const tableRows = rows?.map((item) => ({
    id: item?.id,
    field: item?.field,

    type: item?.type,
  }));
  return (
    <Box sx={{ width: width }}>
      <ModalDialog
        open={open}
        title={"Add Record"}
        close={() => {
          console.log("rex", records);
          setOpen(false);
        }}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          width={"15vw"}
          height={"9vh"}
        >
          <TextField
            value={recordName}
            onChange={(e) => onNameChange(e)}
            variant="outlined"
            error={existingRecord && recordName?.length > 0}
            helperText={
              existingRecord && recordName?.length > 0 && "already exists"
            }
            label="Name"
          />
          <IconButton onClick={onDoneClick}>
            <DoneIcon />
          </IconButton>
        </Box>
      </ModalDialog>
      <ModalDialog
        open={swarmOpen === "records"}
        title={"Add records"}
        close={() => dispatch(setSwarmAppModal(false))}
      >
        <Grid container spacing={2} height={110} width={700}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              value={field}
              onChange={(e) => onFieldChange(e)}
              variant="outlined"
              error={existingProperty}
              helperText={existingProperty && "Already exists"}
              label="Field"
              sx={{ mt: 2 }}
            />
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ mt: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={type}
                  label="Type"
                  onChange={onTypeChange}
                >
                  <CustomMenuItem value={"Boolean"}>Boolean</CustomMenuItem>
                  <CustomMenuItem value={"Int"}>Int</CustomMenuItem>
                  <CustomMenuItem value={"String"}>String</CustomMenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          onClick={onAddRecordVariables}
          disabled={!field || !type}
          sx={{ float: "right" }}
        >
          Add record
        </Button>
      </ModalDialog>
      <>
        <Box
          display={"flex"}
          alignItems={"center"}
          /* justifyContent={"space-between"} */
        >
          <SwarmAppAutocomlete
            sx={{ width: "70%" }}
            options={records}
            value={selectedRecord}
            setValue={(e) => dispatch(setSelectedRecord(e.value))}
          />
          <IconButton onClick={() => setOpen(true)}>
            <AddIcon />
          </IconButton>
          {/*   <IconButton>
            <DriveFileRenameOutlineIcon />
          </IconButton> */}
          <IconButton onClick={onDeleteClick}>
            <DeleteIcon sx={{ fill: theme.palette.error.main }} />
          </IconButton>
        </Box>
        <Button
          onClick={() => dispatch(setSwarmAppModal("records"))}
          sx={{ mt: 2 }}
          fullWidth
          disabled={!selectedRecord}
          variant="contained"
        >
          Add record
        </Button>
        <Box sx={{ height: height, width: width, mt: 2 }}>
          <DataGrid
            onCellEditCommit={(params) =>
              updateRow(params.id, params.field, params.value)
            }
            disableColumnMenu
            rows={tableRows?.length > 0 ? tableRows : []}
            columns={columns}
            /*   rowCount={response?.data?.meta?.count} */
            /*     pageSize={perPage} */
            /*   rowsPerPageOptions={[perPage]} */
            disableSelectionOnClick
            /*   onPageChange={(pageNumber) => {
                setPage(pageNumber + 1);
              }}
              page={page - 1} */
            // loading={isLoadingTable}

            /*   getRowHeight={() => "auto"} */
          />
        </Box>
      </>
    </Box>
  );
};
export default RecordsTab;

import validator from "@rjsf/validator-ajv6";
import { Form } from "@rjsf/mui";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button, Grid } from "@mui/material";
import { useCreateGatewayMutation } from "../../redux/slices/gatewayApi";
import SearcheableSelect from "../form/formComponents/SearcheableSelect";
import { Box } from "@mui/system";
import { getLocation } from "../../utils/getLocation";
import Point from "../form/formComponents/Point";

const ObjectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={2}>
      {props.properties.map((element) => {
        return (
          <Grid key={element.id} alignItems="center" item xs={6}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
const GatewayCreate = () => {
  const { t } = useTranslation();
  const digitalTwinId = useSelector(
    (state) => state?.digitalTwinSlice?.digitalTwin?.[0]?.id
  );
  const networkAdapters = window.GATEWAYS?.[digitalTwinId]?.map((item) => ({
    name: item,
    module: "null",
  }));
  const successCallback = (position) => {
    console.log("posic", position);
  };

  const errorCallback = (error) => {
    console.log(error);
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
  }, []);
  useEffect(() => {
    console.log("ntwrkdptrs", networkAdapters);
  }, [networkAdapters]);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [createdId, setCreatedId] = useState(null);
  const [createGateway, { isSuccess }] = useCreateGatewayMutation();
  const schema = {
    title: "New Device",
    type: "object",
    properties: {
      serial_number: {
        $id: "1",
        type: "string",
        length: "10",
        title: t("serial"),
      },

      street_address: {
        $id: "4",
        type: "string",
        title: t("street_address"),
      },
      name: {
        $id: "5",
        type: "string",
        title: t("name"),
      },

      digital_twin: {
        $id: "7",

        mapFn: (result) =>
          result?.data?.data?.map((item) => ({
            key: item.attributes?.id,
            label: `${item.attributes?.name} (${item.attributes?.version})`,
            value: item.attributes?.id,
          })),
        /*    stateSelector: classStateSelector, */

        url: "altior/digital_twin",
        type: "number",
        title: t("digital_twin"),
        dispatched: true,
      },

      coordinates: {
        $id: "12",
        type: "object",
        properties: {
          lat: {
            $id: "28",
            type: "number",
            default: 0,
          },
          lng: {
            $id: "29",
            type: "number",
            default: 0,
          },
        },
      },
      time_zone: {
        $id: "6",
        type: "string",
        title: t("time_zone"),
        url: "time_zone",
        defaultTimeZone: true,
        default: getLocation(),

        mapFn: (result) =>
          result?.data?.data?.map((item) => ({
            key: item?.attributes?.key,
            label: item?.attributes?.name,
            value: item?.attributes?.id,
          })),
      },
    },
    required: ["serial_number", "name", "digital_twin"],
  };
  const uiSchema = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    "ui:order": [
      "digital_twin",
      "name",
      "serial_number",

      "street_address",

      "coordinates",
      "time_zone",
    ],

    "ui:submitButtonOptions": {
      submitText: t("create"),
    },
    coordinates: {
      "ui:field": "point",
    },

    digital_twin: {
      "ui:field": "search",
    },
    time_zone: {
      "ui:field": "search",
    },
  };
  const fields = {
    point: Point,
    search: SearcheableSelect,
  };

  const onSubmitClick = () => {
    const attributes = {
      fields: {
        active_network_adapters: [...networkAdapters],
        identifier_type: "serial_number",
        network_adapters: [...networkAdapters],
        security: {
          acl: [
            {
              name: "/admin",
              scopes: ["Elixir.show"],
            },
          ],
          groups: ["/admin"],
        },
        start_outbound_process: true,
        tag: "WebDyn",
        class: "Gateway",
        coordinates: {
          coordinates: [formData.coordinates.lng, formData.coordinates.lat],
          type: "Point",
        },
        name: formData.name,
        serial_number: formData.serial_number,
        street_address: formData.street_address,
        time_zone: formData.time_zone,
      },
      meta_data: {},
    };
    createGateway({
      id: formData.digital_twin,
      attributes: attributes,
    })
      .unwrap()
      .then((data) => setCreatedId(data.data.id));
  };
  useEffect(() => {
    if (isSuccess && createdId) {
      navigate(`/gateways/${createdId}`);
    }
  }, [isSuccess, createdId]);
  useEffect(() => {
    console.log("formochka", formData);
  }, [formData]);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Form
        schema={schema}
        fields={fields}
        formData={formData}
        onChange={(changeEvent) => setFormData(changeEvent.formData)}
        onSubmit={(values) => onSubmitClick(values.formData)}
        validator={validator}
        uiSchema={uiSchema}
        showErrorList={false}
        noHtml5Validate
      />
    </Box>
  );
};
export default GatewayCreate;

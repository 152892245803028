import { useLazyGetWalkthroughQuery } from "../../redux/slices/gatewayApi";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { useSelector, useDispatch } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import theme from "../../theme";
import IconButton from "@mui/material/IconButton";
import { StyledDataGrid } from "../../utils/StyledDataGrid";
const GatewayCompoundTwins = ({ twindId, instanceId }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [getWalkthrough] = useLazyGetWalkthroughQuery();
  const walkthroughs = useSelector(
    (state) => state?.gatewaySlice?.walkthroughs
  )?.children;
  useEffect(() => {
    getWalkthrough({ twindId: twindId, instanceId: instanceId });
  }, []);
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },
    {
      field: "identifier_type",
      headerName: t("identifier_type"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 5,
      headerAlign: "left",
    },
    {
      field: "class",
      headerName: t("class"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 5,
      headerAlign: "left",
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      headerAlign: "right",
      flex: 4,
      renderCell: (params) => {
        return (
          <Box
            sx={{ width: "100%" }}
            display={"flex"}
            justifyContent={"flex-end"}
          >
            <IconButton onClick={() => navigate(`/meters/${params?.value}`)}>
              <VisibilityIcon sx={{ fill: theme.palette.primary.main }} />
            </IconButton>
          </Box>
        );
      },
    },
  ];
  const rows = walkthroughs?.map((item, i) => ({
    id: i,
    identifier_type: `${item?.attributes?.identifier_type}: ${
      item?.attributes[item?.attributes?.identifier_type]
    }`,
    class: item?.attributes?.class,
    actions: item?.attributes?.identifier,
  }));
  return (
    <>
      {rows && (
        <Box height={600}>
          <StyledDataGrid
            disableColumnMenu
            disableSelectionOnClick
            rows={rows}
            columns={columns}
            /*  rowCount={response?.data?.meta?.count}
        pageSize={perPage}
        rowsPerPageOptions={[perPage]} */
            /* disableSelectionOnClick
        onPageChange={(pageNumber) => {
          !response.isFetching && setPage(pageNumber + 1);
        }}
        page={page - 1}
      
        paginationMode="server"
        getRowHeight={() => "auto"} */
          />
        </Box>
      )}
    </>
  );
};

export default GatewayCompoundTwins;

import { api } from "./api";

export const nodeControllerApi = api.injectEndpoints({
  endpoints: (build) => ({
    saveNodesInLocalStorage: build.mutation({
      query: ({ tabs, nodes, edges }) => {
        debugger;
        localStorage.setItem("tabs", JSON.stringify(tabs));
        localStorage.setItem("nodes", JSON.stringify(nodes));
        localStorage.setItem("edges", JSON.stringify(edges));
      },
    }),
    getNodesFromLocalStorage: build.query({
      query: () => {
        debugger;
        return {
          tabs: localStorage.getItem("tabs"),
          nodes: localStorage.getItem("nodes"),
          edges: localStorage.getItem("edges"),
        };
      },
    }),
  }),
});

export const {
  useSaveNodesInLocalStorageMutation,
  useLazyGetNodesFromLocalStorageQuery,
} = nodeControllerApi;

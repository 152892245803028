import { useState, useEffect } from "react";
import { DropzoneArea } from "material-ui-dropzone";
import { DropzoneDialog } from "material-ui-dropzone";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

import IconButton from "@mui/material/IconButton";
import { Tooltip } from "@mui/material";
import {
  useLazyGetFileErrorsQuery,
  useLazyGetFileStatisticQuery,
} from "../../redux/slices/uploadFileApi";
import theme from "../../theme";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { v4 as uuidv4 } from "uuid";
import { setUuid } from "../../redux/slices/uploadFileSlice";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";

const bearer = localStorage.getItem("user_token");
const WorklistEditorUploader = (props) => {
  const navigate = useNavigate();
  const appBarIsOpen = useSelector(
    (state) => state?.utilsSlice?.headerAppBarOpen
  );
  const { t } = useTranslation();
  const formData = new FormData();
  const [getErrors, { isSuccess: errorsSuccess }] = useLazyGetFileErrorsQuery();
  const [getStatistic, { isSuccess: statisticSuccess }] =
    useLazyGetFileStatisticQuery();
  const [operationStatus, setOperationStatus] = useState(null);
  const dispatch = useDispatch();
  const [files, setFiles] = useState(null);
  const [uuid] = useState(uuidv4());
  const operationId = sessionStorage.getItem("uuid");

  useEffect(() => {
    dispatch(setUuid(uuid));
  }, [uuid]);

  const clearFile = () => {
    setFiles(null);
  };

  const uploadFile = () => {
    console.log(formData);
    const body = {
      operation_id: uuid,
      file: formData,
    };
    fetch(`${window.BASE_URL}/api/v1/altior/device/upload`, {
      method: "POST",
      body: formData,
      // 👇 Set headers manually for single file upload
      headers: {
        /*  "content-Type": undefined, */
        // "content-length": `${files[0].size}`,
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((res) => res.json())
      .then((data) => setOperationStatus(data.operation_status))
      .catch((err) => console.error(err));
  };

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {
    /*  console.log(status, meta, file); */
    debugger;
    setFiles(file);
  };

  // receives array of files that are done uploading when submit button is clicked
  const handleSubmit = (files, allFiles) => {
    console.log(files.map((f) => f.meta));
    allFiles.forEach((f) => f.remove());
  };
  useEffect(() => {
    if (files) {
      props.setFile(files);
    }
  }, [files]);
  useEffect(() => {
    if (files) {
      debugger;
      //   formData.append(files?.[0]?.name, files?.[0]);
      formData.append("file", files);
      formData.append("operation_id", uuid);
    }
  }, [files]);
  /*   useEffect(() =>{
    console.log()
  }) */
  useEffect(() => {
    if (operationStatus === "ok") {
      getErrors(operationId);
      getStatistic(operationId);
    }
  }, [operationStatus]);
  useEffect(() => {
    if (errorsSuccess && statisticSuccess) {
      navigate("/import/show");
    }
  }, [errorsSuccess, statisticSuccess]);
  return (
    <Card sx={{ height: 320 }}>
      <CardContent>
        <Box sx={{ height: 400 }}>
          <Typography sx={{ color: theme.palette.primary.main }} variant="h4">
            {t("import_file")}
          </Typography>
          <Box sx={{ width: !appBarIsOpen ? 1250 : 1050, mt: 2, height: 100 }}>
            {files == null && (
              <Dropzone
                /*     getUploadParams={getUploadParams} */
                style={{ color: "red" }}
                onChangeStatus={handleChangeStatus}
                onSubmit={handleSubmit}
                maxFiles={1}
                styles={{
                  dropzone: { overflow: "hidden" },
                }}
              />
            )}
            {files !== null && (
              <Box
                display={"flex"}
                alignContent={"center"}
                sx={{ width: 1100, height: 53 }}
              >
                <Typography sx={{ color: theme.palette.primary.main, mt: 1.4 }}>
                  {`File to upload : ${files?.name}`}
                </Typography>
                <Tooltip title="Delete file">
                  <IconButton onClick={clearFile}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </Box>
          {/* 
          <Button
            sx={{ mt: 6, fill: theme.palette.primary.main }}
            variant="contained"
            onClick={uploadFile}
            disabled={!files}
          >
            {t("upload")}
          </Button> */}
        </Box>
      </CardContent>
    </Card>
  );
};
export default WorklistEditorUploader;

import { useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  useLazyGetTrilliantMetersQuery,
  useDeleteTrilliantMeterMutation,
} from "../../../redux/slices/trilliantApi";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import TrashModal from "../../TrashModal";
import { setMessage } from "../../../redux/slices/snackbarSlice";
import { opendModal, unsetConfirmed } from "../../../redux/slices/trashSlice";
import DeleteIcon from "@mui/icons-material/Delete";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import SendAndArchiveIcon from "@mui/icons-material/SendAndArchive";
import ErrorIcon from "@mui/icons-material/Error";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/system";
import theme from "../../../theme";
import { StyledDataGrid } from "../../../utils/StyledDataGrid";
import { Typography } from "@mui/material";
const TrilliantMeters = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const deleteConfirmed = useSelector((state) => state?.trashSlice?.confirmed);
  const [getMeters] = useLazyGetTrilliantMetersQuery();
  const [deleteMeter, { isSuccess: deleteSuccess }] =
    useDeleteTrilliantMeterMutation();
  const [trashId, setTrashId] = useState(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const meters = useSelector((state) => state?.trilliantSlice?.meters);
  const meterCount = useSelector((state) => state?.trilliantSlice?.count);
  useEffect(() => {
    getMeters({
      page: page,
    });
  }, [page]);
  const onTrashClick = (id) => {
    dispatch(opendModal());
    setTrashId(id);
  };
  useEffect(() => {
    if (deleteConfirmed) {
      deleteMeter(trashId);
    }
    dispatch(unsetConfirmed());
  }, [deleteConfirmed]);
  useEffect(() => {
    if (deleteSuccess) {
      dispatch(setMessage(t("deleted_meter")));
      getMeters({
        page: page,
      });
    }
  }, [deleteSuccess]);
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
    },
    {
      field: "inserted_at",
      headerName: t("inserted_at"),
      width: 90,
      sortable: false,
      flex: 1,
    },
    {
      field: "last_msg_sent",
      headerName: t("last_message_sent_at"),
      width: 90,
      sortable: false,
      flex: 1,
    },
    {
      field: "status",
      headerName: t("status"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      editable: false,
      renderCell: (params) => {
        return (
          <>
            {params.value === "to_send" && (
              <Tooltip title={t(params.value)}>
                <SendIcon sx={{ fill: theme.palette.primary.main }} />
              </Tooltip>
            )}
            {params.value === "sending" && (
              <Tooltip title={t(params.value)}>
                <ScheduleSendIcon sx={{ fill: theme.palette.primary.main }} />
              </Tooltip>
            )}
            {params.value === "sent" && (
              <Tooltip title={t(params.value)}>
                <SendAndArchiveIcon sx={{ fill: theme.palette.primary.main }} />
              </Tooltip>
            )}
            {params.value === "error" && (
              <Tooltip title={t(params.value)}>
                <ErrorIcon sx={{ fill: theme.palette.error.main }} />
              </Tooltip>
            )}
          </>
        );
      },
    },

    {
      field: "actions",
      headerName: t("actions"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      headerAlign: "right",
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%" }}>
            <IconButton
              disabled={params.value.status !== "to_send"}
              onClick={() => onTrashClick(params.value.id)}
              sx={{ float: "right" }}
            >
              <DeleteIcon
                sx={{
                  fill:
                    params.value.status === "to_send" &&
                    theme.palette.error.main,
                }}
              />
            </IconButton>
            <IconButton sx={{ ml: 1, float: "right" }}>
              <Link to={`/meters/${params?.value?.device_id}`} target="_blank">
                <VisibilityIcon sx={{ fill: theme.palette.primary.main }} />
              </Link>
            </IconButton>
          </Box>
        );
      },
    },
  ];
  const rows = meters?.map((meter) => ({
    id: meter?.device_id,
    inserted_at: t("dt", { val: new Date(meter?.inserted_at) }),
    status: meter?.status,
    last_msg_sent: "--",
    actions: {
      device_id: meter?.device_id,
      id: meter?.id,
      status: meter?.status,
    },
  }));
  return (
    <>
      <TrashModal
        title={t("delete_meter_title")}
        text={t("delete_meter_text")}
      />
      {/*    <Typography variant="h3" sx={{ color: theme.palette.primary.main }}>
        Trilliant meters
      </Typography> */}
      {rows && (
        <Box sx={{ height: 632, width: "100%", mt: 3 }}>
          <StyledDataGrid
            disableColumnMenu
            rows={rows}
            columns={columns}
            pageSize={perPage}
            rowsPerPageOptions={[perPage]}
            rowCount={meterCount}
            disableSelectionOnClick
            onPageChange={(pageNumber) => {
              setPage(pageNumber + 1);
            }}
            page={page - 1}
            paginationMode="server"
          />
        </Box>
      )}
    </>
  );
};

export default TrilliantMeters;

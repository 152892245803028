import { useEffect, useState } from "react";
import { StyledDataGrid } from "../../utils/StyledDataGrid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useLazyGetConnectionsQuery } from "../../redux/slices/connectionsApi";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import Tooltip from "@mui/material/Tooltip";
import { useDeleteConnectionMutation } from "../../redux/slices/connectionsApi";
import {
  useStartConnectionMutation,
  useStopConnectionMutation,
} from "../../redux/slices/connectionsApi";
import { Link } from "react-router-dom";
import { opendModal } from "../../redux/slices/trashSlice";
import TrashModal from "../TrashModal";
import { setMessage } from "../../redux/slices/snackbarSlice";
import { unsetConfirmed } from "../../redux/slices/trashSlice";
import theme from "../../theme";
import IconButton from "@mui/material/IconButton";
import { IwdAuthWrapper } from "../../MuiOverrides/IwdAuthWrapper";
import { Box } from "@mui/material";
const Connections = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [toggleStarted, setToggleStarted] = useState(false);
  const connections = useSelector(
    (state) => state?.connectionsSlice?.connections
  );
  const count = useSelector((state) => state?.connectionsSlice?.count);
  const deleteConfirmed = useSelector((state) => state?.trashSlice?.confirmed);
  const [startConnection, { isSuccess: startSuccess }] =
    useStartConnectionMutation();
  const [stopConnection, { isSuccess: stopSuccess }] =
    useStopConnectionMutation();
  const { t } = useTranslation();
  const [deleteConnection, { isSuccess: deleteSuccess }] =
    useDeleteConnectionMutation();
  const [trashId, setTrashId] = useState();
  const [getConnections] = useLazyGetConnectionsQuery();
  const onStartClick = (id) => {
    startConnection({ id: id });
  };
  const onStopClick = (id, attributes) => {
    stopConnection({ id: id, attributes: attributes });
  };
  useEffect(() => {
    if (startSuccess) {
      dispatch(setMessage(t("strat_con_succ")));
      getConnections({
        page: page,
      });
    }
  }, [startSuccess]);
  useEffect(() => {
    if (stopSuccess) {
      dispatch(setMessage(t("stop_con_succ")));
      getConnections({
        page: page,
      });
    }
  }, [stopSuccess]);
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      editable: false,
      hide: true,
    },
    {
      field: "host",
      headerName: t("host"),
      width: 90,
      sortable: false,
      editable: false,
      flex: 1,
    },
    {
      field: "name",
      headerName: t("name"),
      width: 90,
      sortable: false,
      editable: false,
      flex: 1,
    },
    {
      field: "login",
      headerName: t("login"),
      width: 90,
      sortable: false,
      editable: false,
      flex: 1,
    },
    {
      field: "passcode",
      headerName: t("passcode"),
      width: 90,
      sortable: false,
      flex: 1,
      editable: false,
    },
    {
      field: "port",
      headerName: t("port"),
      width: 90,
      sortable: false,
      editable: false,
      flex: 1,
    },
    {
      field: "reconnect_timeout",
      headerName: t("recconnect_timeout"),
      width: 90,
      sortable: false,
      editable: false,
      flex: 1,
    },
    {
      field: "started",
      headerName: t("started"),
      width: 90,
      sortable: false,
      editable: false,
      flex: 1,
    },
    {
      field: "inserted_at",
      headerName: t("inserted_at"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      headerAlign: "left",
    },
    {
      field: "updated_at",
      headerName: t("updated_at"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      headerAlign: "left",
    },

    {
      field: "actions",
      headerName: t("actions"),
      type: "boolean",
      sortable: false,
      headerAlign: "right",

      flex: 1,
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignContent={"center"} alignItems={"center"}>
            <IwdAuthWrapper
              children={
                <IconButton sx={{ mt: 1 }}>
                  <Link to={`/connections/${params.value.id}`}>
                    <VisibilityIcon sx={{ fill: theme.palette.primary.main }} />
                  </Link>
                </IconButton>
              }
              section={"connections_show"}
            />
            <IwdAuthWrapper
              children={
                <IconButton
                  onClick={() =>
                    onTrashClick(params.value.id, params.value.started)
                  }
                >
                  <DeleteIcon sx={{ fill: theme.palette.error.main }} />
                </IconButton>
              }
              section={"connections_delete"}
            />
            {params?.value?.started === false ? (
              <IwdAuthWrapper
                children={
                  <Tooltip title={t("start_conn")}>
                    <IconButton onClick={() => onStartClick(params.value.id)}>
                      <PlayArrowIcon
                        sx={{ fill: theme.palette.success.main }}
                      />
                    </IconButton>
                  </Tooltip>
                }
                section={"connections_start_stop"}
              />
            ) : (
              <IwdAuthWrapper
                children={
                  <Tooltip title={t("stop_conn")}>
                    <IconButton
                      onClick={() =>
                        onStopClick(params.value.id, params.value.attributes)
                      }
                    >
                      <StopIcon sx={{ fill: theme.palette.error.main }} />
                    </IconButton>
                  </Tooltip>
                }
                section={"connections_start_stop"}
              />
            )}
          </Box>
        );
      },
    },
  ];
  const rows = connections?.map((connection) => ({
    id: connection.id,
    host: connection.host,
    login: connection.login,
    name: connection.name,
    passcode: connection.passcode,
    port: connection.port,
    reconnect_timeout: connection.reconnect_timeout,
    started: connection.started,
    inserted_at: t("dt", { val: new Date(connection.inserted_at) }),
    updated_at: t("dt", { val: new Date(connection.updated_at) }),
    actions: {
      id: connection.id,
      started: connection.started,
      attributes: {
        id: connection.id,
        host: connection.host,
        login: connection.login,
        name: connection.name,
        passcode: connection.passcode,
        port: connection.port,
        reconnect_timeout: connection.reconnect_timeout,
      },
    },
  }));
  useEffect(() => {
    getConnections({
      page: page,
    });
  }, [page]);
  const onTrashClick = (id, started) => {
    if (started === true) {
      debugger;
      setToggleStarted(true);
    }
    dispatch(opendModal());
    setTrashId(id);
  };
  useEffect(() => {
    if (stopSuccess && toggleStarted) {
      debugger;
      setToggleStarted(false);
    }
  }, [stopSuccess, toggleStarted]);
  useEffect(() => {
    if (deleteConfirmed && toggleStarted) {
      stopConnection({ id: trashId });
    }
  }, [deleteConfirmed, toggleStarted]);

  useEffect(() => {
    if (deleteConfirmed && !toggleStarted) {
      debugger;
      deleteConnection(trashId);
      dispatch(unsetConfirmed());
    }
  }, [deleteConfirmed, toggleStarted]);
  useEffect(() => {
    if (deleteSuccess) {
      dispatch(setMessage(t("deleted_connection")));
      getConnections({
        page: page,
      });
    }
  }, [deleteSuccess]);
  return (
    <>
      <TrashModal title={t("delete_con_title")} text={t("delete_con_text")} />
      {rows && (
        <Box height={600}>
          <StyledDataGrid
            disableColumnMenu
            disableSelectionOnClick
            rows={rows}
            columns={columns}
            pageSize={perPage}
            rowCount={count}
            paginationMode="server"
            onPageChange={(pageNumber) => {
              setPage(pageNumber + 1);
            }}
            page={page - 1}
          />
        </Box>
      )}
    </>
  );
};
export default Connections;

import React from "react";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import WorkListEditorCreateForm from "./WarkListEditorCreateForm";
import { worklistActions } from "../../redux/slices/worklistSlice";
import WorklistEditorMap from "./WorklistEditorMap";
import WorklistEditorUploader from "./WorklistEditorUploader";
import {
  Tabs,
  Tab,
  Typography,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Tooltip,
} from "@mui/material";
import { Grid } from "@mui/material";
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};
const WorkListEditorCreate = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const [file, setFile] = useState(null);
  const dispatch = useDispatch();
  const appBarIsOpen = useSelector(
    (state) => state?.utilsSlice?.headerAppBarOpen
  );
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    dispatch(worklistActions.cleanWorklistData());
  }, []);

  return (
    <Grid
      container
      sx={{
        height: "75vh",
        width: appBarIsOpen ? "78vw" : "92vw",
      }}
    >
      <Grid item xs={3} sx={{ mt: 5 }}>
        <WorkListEditorCreateForm tabValue={value} file={file} />
      </Grid>
      <Grid item xs={9}>
        <Box sx={{ height: "93%", width: "100%", ml: 11 }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab label={t("map")} />
            <Tab label={t("file")} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <WorklistEditorMap />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <WorklistEditorUploader setFile={setFile} file={file} />
          </TabPanel>
        </Box>
      </Grid>
    </Grid>
  );
};
export default WorkListEditorCreate;

import DigitalTwins from "../digital_twins/DigitalTwins";
import ModalDialog from "../meters/ModalDialog";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import theme from "../../theme";

import {
  Tabs,
  Tab,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
} from "@mui/material";
import { useState } from "react";
const CustomButton = styled(Button)(() => ({
  "&.MuiButtonBase-root": {
    padding: "7px",
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 1,
  },
}));
const DigitalTwinTab = () => {
  const [open, setOpen] = useState(false);
  const [twinName, setTwinName] = useState("");
  const onOpetnClick = () => {
    setOpen(true);
  };
  return (
    <>
      <ModalDialog open={open} close={() => setOpen(false)}>
        <Box sx={{ width: "70vw" }}>
          <DigitalTwins fromApp={true} closeAppModal={() => setOpen(false)} />
        </Box>
      </ModalDialog>
      <Box>
        <CustomButton
          fullWidth
          variant="contained"
          sx={{ mb: 2 }}
          onClick={onOpetnClick}
        >
          Select digital twin
        </CustomButton>
      </Box>
    </>
  );
};
export default DigitalTwinTab;

import { StyledDataGrid } from "../../utils/StyledDataGrid";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";

import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import theme from "../../theme";

const SurveyIndexTable = ({
  page,
  perPage,
  setPage,
  hideAccordion,
  onTrashClick,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const surveys = useSelector((state) => state?.surveySlice?.serveyIndex);
  const count = useSelector((state) => state?.surveySlice?.surveysCount);
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },

    {
      field: "name",
      headerName: t("name"),
      type: "string",
      sortable: false,
      editable: true,
      flex: 1,
      headerAlign: "left",
    },
    {
      field: "device_id",
      headerName: t("meter"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Box width={"100%"} display={"flex"} justifyContent={"center"}>
            <IconButton onClick={() => navigate(`/meters/${params.value}`)}>
              <VisibilityIcon sx={{ fill: theme.palette.primary.main }} />
            </IconButton>
          </Box>
        );
      },
    },
    {
      field: "user_id",
      headerName: t("user"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Box width={"100%"} display={"flex"} justifyContent={"center"}>
            <IconButton onClick={() => navigate(`/users/${params.value}`)}>
              <VisibilityIcon sx={{ fill: theme.palette.primary.main }} />
            </IconButton>
          </Box>
        );
      },
    },

    {
      field: "status",
      headerName: t("status"),
      type: "number",
      sortable: false,
      editable: true,
      flex: 1,
      headerAlign: "left",
    },

    {
      field: "official_start_date",
      headerName: t("official_start_date"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      headerAlign: "left",
    },
    {
      field: "official_end_date",
      headerName: t("official_end_date"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      headerAlign: "left",
    },
    {
      field: "updated_at",
      headerName: t("updated_at"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      headerAlign: "left",
    },

    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      headerAlign: "right",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            sx={{ width: "100%" }}
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <IconButton onClick={() => navigate(`/surveys/${params?.value}`)}>
              <VisibilityIcon
                sx={{
                  float: "right",
                  mt: 1,
                  fill: theme.palette.primary.main,
                }}
              />
            </IconButton>
            <IconButton onClick={() => onTrashClick(params?.value)}>
              <DeleteIcon
                sx={{ ml: 2, mt: 0, fill: theme.palette.error.main }}
              />
            </IconButton>
          </Box>
        );
      },
    },
  ];
  const rows = surveys?.map((item) => ({
    id: item?.id,
    name: item?.name,
    status: item?.status,
    official_start_date: t("dt", { val: new Date(item?.official_start_date) }),
    official_end_date: t("dt", { val: new Date(item?.official_end_date) }),
    updated_at: t("dt", { val: new Date(item?.updated_at) }),
    user_id: item?.user_id,
    device_id: item?.device_id,
    actions: item?.id,
  }));

  return (
    <Box sx={{ height: hideAccordion ? "65vh" : "39vh", width: "100%" }}>
      <StyledDataGrid
        disableColumnMenu
        rows={rows ?? []}
        columns={columns}
        rowCount={count}
        pageSize={perPage}
        rowsPerPageOptions={[perPage]}
        disableSelectionOnClick
        onPageChange={(pageNumber) => {
          setPage(pageNumber + 1);
        }}
        page={page - 1}
        // loading={isLoadingTable}
        paginationMode="server"
        /*   getRowHeight={() => "auto"} */
      />
    </Box>
  );
};
export default SurveyIndexTable;

import { Handle, Position } from "reactflow";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import CustomHandle from "../CustomHandle";
import { Button, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setNodes } from "../../../redux/slices/nodeControllerSlice";
import InputLabel from "@mui/material/InputLabel";
import SwarmAppAutocomlete from "../utilsComponents/SwarmAppAutocomlete";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Typography } from "@mui/material";
import {
  CustomAutocomlete,
  CustomMenuItem,
} from "../styledComponents/StyledComponents";
const ConditionNode = ({ data, id }) => {
  const [date, setDate] = useState(new Date());
  const dispatch = useDispatch();
  const appVariables = useSelector(
    (state) => state?.nodeControllerSlice?.appVariables
  );
  const properties = useSelector(
    (state) => state?.nodeControllerSlice?.properties
  );
  const selectedRecord = useSelector(
    (state) => state?.nodeControllerSlice?.selectedRecord
  );
  const selectedRecordsValues = useSelector(
    (state) => state?.nodeControllerSlice?.records
  )?.[selectedRecord];
  const [updatedNode, setUpdatedNode] = useState(null);
  const digitalTwin = useSelector(
    (state) => state?.digitalTwinSlice?.digitalTwinShow
  );
  const nodes = useSelector((state) => state?.nodeControllerSlice?.nodeList);
  const tabname = useSelector(
    (state) => state?.nodeControllerSlice?.actualTabName
  );
  const [argument, setArgument] = useState(data?.value);
  const [argumentOptions, setArgumentOptions] = useState([]);
  const [condition, setCondition] = useState();
  const [value, setValue] = useState();
  const [argumentType, setArgumentType] = useState(data.argumentType);
  const argumentHandler = (e) => {
    setArgument(e.target.value);
  };
  const conditionHandler = (e) => {
    setCondition(e.target.value);
  };
  const valueHandler = (e) => {
    setValue(e.target.value);
  };
  const argumentTypeHandler = (e) => {
    setArgumentType(e.target.value);
  };

  const argumentTypeOptions = [
    { label: "Properties", value: "Properties" },
    { label: "Device Twin", value: "Device Twin" },
    { label: "Variables", value: "Variables" },
    { label: "Records", value: "Records" },
  ];

  useEffect(() => {
    console.log("pissedpopped", argument);
  }, [argument]);
  useEffect(() => {
    setDate(new Date());
  }, [argument]);
  useEffect(() => {
    const filteredNode = nodes[tabname]?.filter((nds) => nds.id === id);
    const newNode = filteredNode.map((node) => {
      return {
        ...node,
        data: {
          ...node.data,
          value: argument,
          argumentType: argumentType,
        },
      };
    });
    console.log("newnode", newNode);
    setUpdatedNode(...newNode);
  }, [argument]);
  useEffect(() => {
    if (updatedNode) {
      dispatch(setNodes(updatedNode));
    }
    console.log("updtnd", updatedNode);
  }, [updatedNode]);
  const selectArgumentOptions = () => {
    debugger;
    if (argumentType?.value === "Variables") {
      setArgumentOptions(
        appVariables?.map((item) => ({
          label: `${item?.name} (${item?.type})`,
          value: item?.value,
        }))
      );
    }
    if (argumentType?.value === "Device Twin") {
      setArgumentOptions(
        digitalTwin?.map((item) => ({
          label: item?.attributes?.name,
          value: item?.attributes?.name,
        }))
      );
    }
    if (argumentType?.value === "Records") {
      setArgumentOptions(
        selectedRecordsValues?.map((item) => ({
          label: `${item?.field} (${item?.type})`,
          value: item?.field,
        }))
      );
    }
    if (argumentType?.value === "Properties") {
      setArgumentOptions(
        properties?.map((item) => ({
          label: item?.name,
          value: item?.value,
        }))
      );
    }
  };
  useEffect(() => {
    console.log("prerdak", selectedRecordsValues);
  }, [selectedRecordsValues]);
  useEffect(() => {
    console.log("pipopsucabrot");
    selectArgumentOptions();
  }, [argumentType, selectedRecordsValues, appVariables, properties]);

  return (
    <Box
      sx={{
        width: 400,
        height: 290,
        border: "2px solid gray",
        background: "white",
      }}
    >
      <CustomHandle
        type={"target"}
        position={"left"}
        style={{ marginTop: 10 }}
        id={"conditionTarget"}
        color={"#e1ad01"}
        tooltip={"Input"}
      />
      <CustomHandle
        type={"source"}
        position={"bottom"}
        id={"conditionFalse"}
        style={{ marginTop: 15 }}
        tooltip={"False"}
        color={"red"}
        textStyle={{ marginLeft: 1 }}
      />
      <CustomHandle
        type={"source"}
        position={"right"}
        id={"conditionTrue"}
        style={{ marginTop: 12 }}
        tooltip={"True"}
        color={"blue"}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems={"center"}
        sx={{ background: "black", height: 30 }}
      >
        <CallSplitIcon sx={{ fill: "white" }} />
        <Typography color={"white"}>Condition</Typography>
      </Box>

      <Grid container>
        <Grid item xs={11} sx={{ mt: 2, ml: 2 }}>
          <SwarmAppAutocomlete
            options={argumentTypeOptions}
            value={argumentType}
            setValue={setArgumentType}
            label={"Agrument Type"}
          />
        </Grid>
        <Grid item xs={11} sx={{ mt: 2, ml: 2 }}>
          <SwarmAppAutocomlete
            options={argumentOptions}
            value={argument}
            setValue={setArgument}
            label={"Agrument"}
          />
        </Grid>
        <Grid item xs={11} sx={{ mt: 2, ml: 2 }}>
          <SwarmAppAutocomlete label={"Condition"} />
        </Grid>
        <Grid item xs={11} sx={{ mt: 2, ml: 2 }}>
          <TextField
            className="nodrag"
            size="small"
            fullWidth
            value={value}
            variant="outlined"
            onChange={valueHandler}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ConditionNode;

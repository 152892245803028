import * as React from "react";
import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLazyGetWorklistEditorIndexQuery } from "../../redux/slices/worklistApi";
import debounce from "lodash.debounce";
const WorklistEditorSelectorWidget = (props) => {
  const { t } = useTranslation();
  const defaultProp = props?.schema?.default;
  const err = props?.rawErrors?.[0];
  const update = props?.schema?.update;
  const [searchValue, setSearchValue] = useState("");
  const [firstRender, setFirstRender] = useState(true);
  const [defaultValue, setDefaultValue] = useState(null);
  const worklistEditors = useSelector(
    (state) => state?.worklistSlice?.worklistEditors
  );
  const mappedEditors = worklistEditors?.map((wl) => ({
    label: wl?.name,
    id: wl?.id,
  }));

  const [getWorklistEditors] = useLazyGetWorklistEditorIndexQuery();
  const [value, setValue] = useState();

  useEffect(() => {
    if (mappedEditors && defaultProp && firstRender) {
      setDefaultValue(
        mappedEditors?.filter((item, i) => item.id == defaultProp)?.[0]
      );
      setFirstRender(false);
    }
  }, [defaultProp, mappedEditors]);

  useEffect(() => {
    if (!worklistEditors) {
      getWorklistEditors();
    }
  }, []);

  useEffect(() => {
    if (value) {
      props.onChange(value.id);
    } else {
      props.onChange("");
    }
  }, [value]);
  const debValue = debounce((e) => {
    setSearchValue(e?.target?.value);
  }, 500);

  useEffect(() => {
    if (searchValue) {
      getWorklistEditors({ name: searchValue });
    }
  }, [searchValue]);

  useEffect(() => {
    console.log("parapa", defaultProp);
  }, [defaultProp]);
  const getMetersOnClick = () => {
    console.log("picdsadas", worklistEditors);
    if (
      !worklistEditors ||
      worklistEditors.length == 0 ||
      worklistEditors.length == 1
    ) {
      getWorklistEditors();
    }
  };
  return (
    <>
      {mappedEditors && !update && (
        <Autocomplete
          fullWidth
          disablePortal
          onOpen={getMetersOnClick}
          value={value}
          id="combo-box-demo"
          options={mappedEditors}
          onChange={(event, newInputValue) => {
            setValue(newInputValue);
          }}
          defaultValue={defaultValue}
          renderInput={(params) => (
            <TextField
              onChange={(e) => debValue(e)}
              error={err}
              {...params}
              label={t("worklisteditor")}
            />
          )}
        />
      )}
      {mappedEditors && update && defaultValue && (
        <Autocomplete
          fullWidth
          disablePortal
          onOpen={getMetersOnClick}
          id="combo-box-demo"
          options={mappedEditors}
          onChange={(event, newInputValue) => {
            setValue(newInputValue);
          }}
          onInputChange={(e) => debValue(e)}
          defaultValue={defaultValue}
          renderInput={(params) => (
            <TextField error={err} {...params} label={t("worklisteditor")} />
          )}
        />
      )}
      {!mappedEditors && (
        <Autocomplete
          fullWidth
          disablePortal
          disabled
          id="combo-box-demo"
          options={[]}
          renderInput={(params) => (
            <TextField {...params} label={t("worklisteditor")} />
          )}
        />
      )}
    </>
  );
};
export default WorklistEditorSelectorWidget;

import { api } from "./api";
import { prepareParams } from "../../utils";
import { prepareBody, prepareBodyWithoutId } from "../../utils/api_params";

export const surveyApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSurveyIndex: build.query({
      query: (params) => {
        return `v1/plugin/survey?${prepareParams(params)}`;
      },
    }),
    createSurvey: build.mutation({
      query: (attributes) => ({
        url: "v1/plugin/survey",
        method: "POST",
        body: JSON.stringify(prepareBodyWithoutId(attributes, "plugin")),
      }),
    }),
    getSurvey: build.query({
      query: (id) => {
        return `v1/plugin/survey/${id}`;
      },
    }),
    updateSurvey: build.mutation({
      query: ({ attributes, id }) => ({
        url: `v1/plugin/survey/${id}`,
        method: "PUT",
        body: JSON.stringify(prepareBody(attributes, id.toString(), "plugin")),
      }),
    }),
    deleteSurvey: build.mutation({
      query: (id) => ({
        url: `/v1/plugin/survey/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useLazyGetSurveyIndexQuery,
  useCreateSurveyMutation,
  useLazyGetSurveyQuery,
  useUpdateSurveyMutation,
  useDeleteSurveyMutation,
} = surveyApi;

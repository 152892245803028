import { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import validator from "@rjsf/validator-ajv6";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import { Form } from "@rjsf/mui";
import { useNavigate } from "react-router-dom";
import { worklistActions } from "../../redux/slices/worklistSlice";
import DatePicker from "../form/formComponents/DatePicker";
import { useDispatch, useSelector } from "react-redux";
import theme from "../../theme";
import { useCreateWorkListMutation } from "../../redux/slices/worklistApi";
import UserWidget from "../survey/UserWidget";
import WorklistEditorSelectorWidget from "./WorklistEditorSelectorWidget";

const ObjectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={2}>
      {props.properties?.map((element, i) => {
        return (
          <Grid key={element.id} alignItems="center" item xs={6}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};

const WorklistCreate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [createdId, setCreatedId] = useState(null);
  const { t } = useTranslation();
  const [formData, setFormData] = useState();
  const [createWorklist, { isSuccess: createSuccess }] =
    useCreateWorkListMutation();
  const fields = {
    datepicker: DatePicker,
    users: UserWidget,
    wle: WorklistEditorSelectorWidget,
  };

  const schema = {
    title: "New Device",
    type: "object",
    properties: {
      name: {
        $id: "8",
        type: "string",
        title: t("name"),
      },
      date: {
        $id: "3",
        title: t("inserted_at"),
        type: "object",

        properties: {
          from: {
            type: "string",
          },
          to: {
            type: "string",
          },
        },
      },
      worklisteditor: {
        $id: "1",
        type: "string",
        length: "10",
        title: t("worklisteditor"),
      },

      user_id: {
        $id: "8",
        type: "string",
        title: t("user_id"),
      },
    },
  };
  const uiSchema = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,

    "ui:submitButtonOptions": {
      submitText: t("create"),

      props: {
        className: "bntListSearch",
      },
    },
    worklisteditor: {
      "ui:field": "wle",
    },
    date: {
      "ui:field": "datepicker",
    },
    user_id: {
      "ui:field": "users",
    },
  };
  useEffect(() => {
    console.log("fakd", formData);
  }, [formData]);
  const onSubmitClick = () => {
    const attributes = {
      name: formData.name,
      start_date: formData?.date?.from,
      end_date: formData?.date?.to,
      work_list_editor_id: formData?.worklisteditor,
      user_id: formData?.user_id,
    };

    createWorklist(attributes)
      .unwrap()
      .then((data) => setCreatedId(data?.data?.attributes?.id))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (createdId) {
      navigate(`/worklists/${createdId}`);
    }
  }, [createdId]);

  return (
    <Box height={200}>
      <Form
        schema={schema}
        uiSchema={uiSchema}
        fields={fields}
        formData={formData}
        validator={validator}
        onSubmit={onSubmitClick}
        onChange={(changeEvent) => setFormData(changeEvent.formData)}
        /*  customValidate={customValidate} */
        noHtml5Validate
        showErrorList={false}
      />
    </Box>
  );
};
export default WorklistCreate;

import { useParams } from "react-router-dom";
import {
  useLazyGetDigitalTwinShowQuery,
  useLazyGetDigitalTwinsQuery,
} from "../../redux/slices/digitalTwinApi";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { StyledDataGrid } from "../../utils/StyledDataGrid";
import { Typography } from "@mui/material";
import { Chip } from "@material-ui/core";
import ModalDialog from "../meters/ModalDialog";
import theme from "../../theme";
import { setDigitalTwinAttributes } from "../../redux/slices/digitalTwinSlice";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { setAppVariables } from "../../redux/slices/nodeControllerSlice";
import DeleteIcon from "@mui/icons-material/Delete";
import { setSwarmAppModal } from "../../redux/slices/nodeControllerSlice";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import { setMessage } from "../../redux/slices/snackbarSlice";
import MenuItem from "@mui/material/MenuItem";
import { CustomMenuItem } from "./styledComponents/StyledComponents";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Button } from "@mui/material";
const VariablesTab = ({ height, width }) => {
  const dispatch = useDispatch();
  const appVariables = useSelector(
    (state) => state?.nodeControllerSlice?.appVariables
  );
  const [firstRender, setFirstRender] = useState(true);
  const open = useSelector((state) => state?.nodeControllerSlice?.modal);
  const [name, setName] = useState(null);
  const [type, setType] = useState("Boolean");
  const [value, setValue] = useState(true);

  const { t } = useTranslation();
  const onNameChange = (e) => {
    setName(e.target.value);
  };
  const onTypeChange = (e) => {
    setType(e.target.value);
  };
  const onValueChange = (e) => {
    setValue(e.target.value);
  };
  const [rows, setRows] = useState([]);
  const existingProperty = rows?.find((item) => item.name === name);

  const onAddClick = () => {
    if (!existingProperty) {
      const newRow = {
        id: rows.length + 1,
        name: name,
        type: type,
        value: value,
      };
      setRows([...rows, newRow]);
      setName("");
      setType("Boolean");
      setValue("");
      dispatch(setSwarmAppModal(false));
    }
  };

  const deleteRow = (id) => {
    const updatedRows = rows.filter((row) => row.id !== id);
    setRows(updatedRows);
  };
  const updateRow = (id, fieldName, newValue) => {
    const rowIndex = rows.findIndex((row) => row.id === id);

    if (rowIndex !== -1) {
      const updatedRows = [...rows];
      updatedRows[rowIndex] = {
        ...updatedRows[rowIndex],
        [fieldName]: newValue,
      };
      setRows(updatedRows);
    }
  };
  useEffect(() => {
    setRows(appVariables?.length > 0 ? appVariables : []);
    setFirstRender(false);
  }, []);
  useEffect(() => {
    if (!firstRender) {
      debugger;
      dispatch(setAppVariables([...rows]));
    }
  }, [rows]);
  useEffect(() => {
    console.log("rowsik", rows);
  }, [rows]);
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 3,
      hide: true,
    },
    {
      field: "name",
      headerName: t("name"),
      type: "string",
      sortable: false,
      editable: true,
      flex: 4,
    },
    /*     {
      field: "access",
      headerName: t("access"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 4,
    }, */

    {
      field: "type",
      headerName: t("type"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 3,
      renderCell: (params) => {
        if (params.value === undefined) {
          return <>--</>;
        } else {
          return (
            <>
              <Chip
                /*     style={{
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                }} */
                label={params.value}
              />
            </>
          );
        }
      },
    },
    {
      field: "value",
      headerName: "Value",
      type: "string",
      sortable: false,
      editable: true,
      flex: 3,
      renderCell: (params) => {
        const { id, value } = params.row;

        if (params.row.type === "Boolean") {
          return (
            <Select
              value={value.toString()}
              onChange={(event) => updateRow(id, "value", event.target.value)}
            >
              <CustomMenuItem value="true">True</CustomMenuItem>
              <CustomMenuItem value="false">False</CustomMenuItem>
            </Select>
          );
        }

        return <span>{value}</span>;
      },
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      renderCell: (params) => {
        return (
          <Box>
            <IconButton onClick={() => deleteRow(params.row.id)}>
              <Tooltip title={t("delete_row")}>
                <DeleteIcon sx={{ fill: theme.palette.error.main }} />
              </Tooltip>
            </IconButton>
          </Box>
        );
      },
    },
  ];
  /*   const tableRows = digitalTwin?.map((item) => ({
    id: item.id ? delteUnderscore(item.id) : "--",
    access: t(item?.attributes?.access) ?? "--",
    name: item?.attributes?.name ?? "--",
    type: item?.attributes?.type,
  })); */
  return (
    <>
      {!firstRender && (
        <Box sx={{ width: width }}>
          <ModalDialog
            open={open === "variables"}
            title={"Add variable"}
            close={() => dispatch(setSwarmAppModal(false))}
          >
            <Grid container spacing={2} height={110} width={700}>
              <Grid item xs={4}>
                <TextField
                  value={name}
                  onChange={(e) => onNameChange(e)}
                  variant="outlined"
                  label="Name"
                  error={existingProperty}
                  helperText={existingProperty && "Already exists"}
                  sx={{ mt: 2 }}
                />
              </Grid>
              <Grid item xs={4}>
                <Box sx={{ mt: 2 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Type</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={type}
                      label="Type"
                      onChange={onTypeChange}
                    >
                      <CustomMenuItem value={"Boolean"}>Boolean</CustomMenuItem>
                      <CustomMenuItem value={"Int"}>Int</CustomMenuItem>
                      <CustomMenuItem value={"String"}>String</CustomMenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={4}>
                {type !== "Boolean" ? (
                  <TextField
                    variant="outlined"
                    value={value}
                    onChange={(e) => onValueChange(e)}
                    label="Value"
                    sx={{ mt: 2 }}
                  />
                ) : (
                  <Box sx={{ mt: 2 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Value
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={value}
                        label="Value"
                        onChange={onValueChange}
                      >
                        <CustomMenuItem value={"true"}>True</CustomMenuItem>
                        <CustomMenuItem value={"false"}>False</CustomMenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                )}
              </Grid>
            </Grid>
            <Button
              variant="contained"
              onClick={onAddClick}
              disabled={!name || !type || !value}
              sx={{ float: "right" }}
            >
              Add variable
            </Button>
          </ModalDialog>
          <>
            <Button
              onClick={() => dispatch(setSwarmAppModal("variables"))}
              fullWidth
              variant="contained"
            >
              Add variable
            </Button>
            <Box sx={{ height: height, width: width, mt: 2 }}>
              <StyledDataGrid
                disableColumnMenu
                rows={rows}
                columns={columns}
                onCellEditCommit={(params) =>
                  updateRow(params.id, params.field, params.value)
                }
                /*   rowCount={response?.data?.meta?.count} */
                /*     pageSize={perPage} */
                /*   rowsPerPageOptions={[perPage]} */
                disableSelectionOnClick
                /*   onPageChange={(pageNumber) => {
                setPage(pageNumber + 1);
              }}
              page={page - 1} */
                // loading={isLoadingTable}

                /*   getRowHeight={() => "auto"} */
              />
            </Box>
          </>
          {/*   )} */}
        </Box>
      )}
    </>
  );
};
export default VariablesTab;

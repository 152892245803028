import { useParams } from "react-router-dom";
import {
  useLazyGetDigitalTwinShowQuery,
  useLazyGetDigitalTwinsQuery,
} from "../../redux/slices/digitalTwinApi";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { Chip } from "@material-ui/core";
import theme from "../../theme";
import { setDigitalTwinAttributes } from "../../redux/slices/digitalTwinSlice";
import { DataGrid } from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  setSwarmAppModal,
  setAppProperties,
} from "../../redux/slices/nodeControllerSlice";
import ModalDialog from "../meters/ModalDialog";
import { useDispatch, useSelector } from "react-redux";
import Select from "@mui/material/Select";
import { CustomMenuItem } from "./styledComponents/StyledComponents";
import FormControl from "@mui/material/FormControl";
import { Button } from "@mui/material";
import { setMessage, setSeverity } from "../../redux/slices/snackbarSlice";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
const PropertiesTab = ({ height, width }) => {
  const [name, setName] = useState();
  const [rows, setRows] = useState([]);
  const properties = useSelector(
    (state) => state?.nodeControllerSlice?.properties
  );
  const [value, setValue] = useState();

  const [firstRender, setFirstRender] = useState(true);
  const dispatch = useDispatch();
  const open = useSelector((state) => state?.nodeControllerSlice?.modal);
  const { t } = useTranslation();
  const existingProperty = rows?.find((item) => item.name === name);
  const onNameChange = (e) => {
    setName(e.target.value);
  };

  const onValueChange = (e) => {
    setValue(e.target.value);
  };
  const onAddClick = () => {
    const newRow = {
      id: rows?.length + 1,
      name: name,
      value: value,
    };

    console.log("exstngvl", existingProperty);
    if (!existingProperty) {
      setRows([...rows, newRow]);
      setName("");
      setValue("");
      dispatch(setSwarmAppModal(false));
    }
  };
  useEffect(() => {
    if (firstRender && properties?.length > 0) {
      setRows(properties);
    }
    setFirstRender(false);
  }, []);
  const deleteRow = (id) => {
    const updatedRows = rows.filter((row) => row.id !== id);
    setRows(updatedRows);
  };
  const updateRow = (id, fieldName, newValue) => {
    const rowIndex = rows.findIndex((row) => row.id === id);

    if (rowIndex !== -1) {
      const updatedRows = [...rows];
      updatedRows[rowIndex] = {
        ...updatedRows[rowIndex],
        [fieldName]: newValue,
      };
      setRows(updatedRows);
    }
  };
  useEffect(() => {
    if (rows) {
      dispatch(setAppProperties(rows));
    }
  }, [rows]);
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },
    {
      field: "name",
      headerName: t("name"),
      type: "string",
      sortable: false,
      editable: true,
      flex: 3,
    },

    {
      field: "value",
      headerName: t("value"),
      type: "string",
      sortable: false,
      editable: true,
      flex: 2,
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box>
            <IconButton onClick={() => deleteRow(params.row.id)}>
              <Tooltip title={t("delete_row")}>
                <DeleteIcon sx={{ fill: theme.palette.error.main }} />
              </Tooltip>
            </IconButton>
          </Box>
        );
      },
    },
  ];
  /*   const tableRows = digitalTwin?.map((item) => ({
    id: item.id ? delteUnderscore(item.id) : "--",
    access: t(item?.attributes?.access) ?? "--",
    name: item?.attributes?.name ?? "--",
    type: item?.attributes?.type,
  })); */
  return (
    <>
      <Button
        onClick={() => dispatch(setSwarmAppModal("properties"))}
        fullWidth
        variant="contained"
      >
        Add property
      </Button>
      <ModalDialog
        open={open === "properties"}
        title={"Add property"}
        close={() => dispatch(setSwarmAppModal(false))}
      >
        <Grid container spacing={2} height={110} width={700}>
          <Grid item xs={6}>
            <TextField
              value={name}
              fullWidth
              onChange={(e) => onNameChange(e)}
              variant="outlined"
              label="Name"
              error={existingProperty}
              helperText={existingProperty && "Already exists"}
              sx={{ mt: 2 }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              value={value}
              onChange={(e) => onValueChange(e)}
              variant="outlined"
              label="Value"
              sx={{ mt: 2 }}
            />
          </Grid>
        </Grid>
        <Button
          variant="contained"
          onClick={onAddClick}
          disabled={!name || !value}
          sx={{ float: "right" }}
        >
          Add propertyy
        </Button>
      </ModalDialog>

      <Box sx={{ width: width }}>
        {/*   {digitalTwinShowAttributes && ( */}
        <>
          <Box sx={{ height: height, width: width, mt: 2 }}>
            <DataGrid
              onCellEditCommit={(params) =>
                updateRow(params.id, params.field, params.value)
              }
              disableColumnMenu
              rows={rows?.length > 0 ? rows : []}
              columns={columns}
              /*   rowCount={response?.data?.meta?.count} */
              /*     pageSize={perPage} */
              /*   rowsPerPageOptions={[perPage]} */
              disableSelectionOnClick
              /*   onPageChange={(pageNumber) => {
                setPage(pageNumber + 1);
              }}
              page={page - 1} */
              // loading={isLoadingTable}

              /*   getRowHeight={() => "auto"} */
            />
          </Box>
        </>
        {/*   )} */}
      </Box>
    </>
  );
};
export default PropertiesTab;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SaveIcon from "@mui/icons-material/Save";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import FormAccordion from "../form/formComponents/Accordion";
import { useTranslation } from "react-i18next";
import { unSetClusterId } from "../../redux/slices/clusterSlice";

import { useLazyGetPreferenceQuery } from "../../redux/slices/userPreferenceApi";
import { Box } from "@mui/system";
import GatewayIndexTable from "./GatewayIndexTable";
import {
  Tabs,
  Tab,
  Typography,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Tooltip,
} from "@mui/material";
import MapDevice from "../form/formComponents/MapDevice";
import GatewayIndexForm from "./GatewayIndexFrom";
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};
const GatewayIndex = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [iconHover, setIconHover] = useState(true);
  const [perPage, setPerPage] = useState(10);
  const selectedClusterId = useSelector(
    (state) => state?.clusterSlice?.selectedClusterId
  );
  const [getPreference] = useLazyGetPreferenceQuery();
  const [preferenceLoading, setPreferenceLoading] = useState(false);
  const [hideAccordion, setHideAccordion] = useState(true);
  const [value, setValue] = useState(0);
  const gateways = useSelector((state) => state?.gatewaySlice?.gataways);
  const devices = useSelector((state) => state?.clusterSlice?.devices);
  const userPreferenceFirstRender = useSelector(
    (state) => state?.userPreferenceSlice?.firstRender
  );
  const onAccordionClick = () => {
    setHideAccordion(!hideAccordion);
  };
  const handleChange = (event, newValue) => {
    if (newValue !== 2) {
      setValue(newValue);
    }
  };
  useEffect(() => {
    if (userPreferenceFirstRender) {
      getPreference();
    }
  }, []);
  const onIconHover = () => {
    setIconHover(!iconHover);
  };
  const onCancellClick = () => {
    dispatch(unSetClusterId());
    setIconHover(true);
  };

  return (
    <>
      <FormAccordion onClick={onAccordionClick}>
        <GatewayIndexForm page={page} perPage={perPage} setPage={setPage} />
      </FormAccordion>

      <Box sx={{ mt: 2 }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label={t("table")} />
          <Tab label={t("map")} />
          {selectedClusterId !== "" && (
            <Tab
              label={
                iconHover ? (
                  <CheckCircleOutlineIcon sx={{ fill: "green" }} />
                ) : (
                  <Tooltip title="unset cluster">
                    <HighlightOffIcon sx={{ fill: "red" }} />
                  </Tooltip>
                )
              }
              onMouseEnter={onIconHover}
              onMouseLeave={onIconHover}
              onClick={onCancellClick}
            />
          )}
          {preferenceLoading && (
            <Tab
              label={
                <Tooltip title="saving user preference">
                  <SaveIcon sx={{ fill: "green" }} />
                </Tooltip>
              }
            />
          )}
        </Tabs>
        <TabPanel value={value} index={0}>
          <GatewayIndexTable
            data={devices?.data ? devices?.data : gateways}
            page={page}
            perPage={perPage}
            setPage={setPage}
            hideAccordion={hideAccordion}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <MapDevice
            hideAccordion={hideAccordion}
            setPreferenceLoading={setPreferenceLoading}
            classValue={"Gateway"}
          />
        </TabPanel>
      </Box>
    </>
  );
};
export default GatewayIndex;

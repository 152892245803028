import { useEffect } from "react";
import { useState } from "react";

import { useTranslation } from "react-i18next";

import { useParams } from "react-router-dom";
import { Grid, Box, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DraftsIcon from "@mui/icons-material/Drafts";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import Tooltip from "@mui/material/Tooltip";
import ModalDialog from "../meters/ModalDialog";
import { useLazyGetMeterReadedMessagesQuery } from "../../redux/slices/gatewayApi";
/* import CopyTextButton from "./CopyTextButton"; */
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import theme from "../../theme";

import { StyledDataGrid } from "../../utils/StyledDataGrid";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";

const ReadedMeters = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [message, setMessage] = useState(null);
  const [modalMessage, setModalMessage] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [allMessages, setAllMessages] = useState([]);
  const readedMessages = useSelector(
    (state) => state?.gatewaySlice?.readedMessages
  );
  /*  const handledMessages = useSelector(
    (state) => state?.gatewaySlice?.handledMeterMessages
  ); */
  const condominumId = useSelector((state) => state?.gatewaySlice?.gateway)
    ?.fields?.condominium_id;
  const unhandledMeterMesages =
    useSelector((state) => state?.gatewaySlice?.messages)?.filter(
      (item) => item?.attributes?.values?.values?.last_message_type === "data"
    )?.[0]?.attributes?.values?.values?.unhandled_meters ?? [];
  /*     const unhandledMeterMesages =
    useSelector((state) => state?.gatewaySlice?.messages)
      ?.filter(
        (item) => item?.attributes?.values?.values?.last_message_type === "data"
      )
      ?.filter(
        (item) => item.attributes?.values?.values?.unhandled_meters.length > 0
      )[0]?.attributes?.values?.values?.unhandled_meters ?? [];   */
  const transformName = (name) => {
    const nameInfo = name.split(":");
    const firstPart = parseInt(nameInfo[1], 16);
    const secondPart = parseInt(nameInfo[2], 16);
    const result = `${nameInfo[0]}:${firstPart}:${secondPart}`;

    return result;
  };

  /*   const transformedHandledMassages =
    handledMessages?.length > 0
      ? handledMessages?.map((obj) => ({
          ...obj,
          attributes: {
            device_id: obj.id,
            handled: true,
            values: {
              class: obj.attributes.class,
              serial_number: obj.attributes.serial_number,
              values: {
                condominium_name: obj.attributes.fields.condominium_name,
                apartment_name: obj.attributes.fields.apartment_name,
              },
            },
          },
        }))
      : []; */
  const [getMessages] = useLazyGetMeterReadedMessagesQuery();

  useEffect(() => {
    getMessages(id);
  }, []);
  useEffect(() => {
    if (
      readedMessages /* && unhandledMeterMesages */ /*  && handledMessages */
    ) {
      setAllMessages([
        ...readedMessages,
        /*  ...transformedHandledMassages, */
        /*  ...unhandledMeterMesages, */
      ]);
    }
  }, [readedMessages /*  unhandledMeterMesages  */ /*  handledMessages */]);

  const styleSelector = (params) => {
    console.log("parix", params);
    const datastr = params.row.readTime;
    const date = new Date();
    console.log(date);
    /*     if (params?.row?.alarms?.length > 0 && params?.row?.alarms !== "--") {
      return `super-app-theme--Orange`;
    }
    if (params?.row?.dayDiff > 5) {
      return `super-app-theme--PartiallyFilled`;
    } */
    if (params?.row?.conid !== "--" && params?.row?.conid !== condominumId) {
      return `super-app-theme--Rejected`;
    }
    if (params.row.handled) {
      return `super-app-theme--Blue`;
    }
  };

  useEffect(() => {
    transformName("MAD:50:07");
  }, []);
  const onMailClick = (msg) => {
    setMessage(msg);
    setModalMessage(true);
  };
  useEffect(() => {
    if (allMessages.length > 0) {
      console.log("mes", allMessages);
    }
  }, [allMessages]);
  useEffect(() => {
    console.log("unhandledMeterMesages", unhandledMeterMesages);
  }, [unhandledMeterMesages]);
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
      headerAlign: "left",
    },

    {
      field: "device",
      headerName: t("meter"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 2,
    },

    {
      field: "last_telegram",
      headerName: t("last_telegram"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 3,
    },

    {
      field: "reading",
      headerName: t("reading"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 3,
      headerAlign: "left",
    },
    {
      field: "handled",
      headerName: t("handled_meters"),
      width: 90,
      sortable: false,
      flex: 3,

      headerAlign: "left",
      renderCell: (params) => {
        return params.value === true ? (
          <DoneIcon sx={{ fill: theme.palette.success.main }} />
        ) : (
          <CloseIcon sx={{ fill: theme.palette.error.main }} />
        );
      },
    },
    {
      field: "frame",
      headerName: t("Frame"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 2,
      renderCell: (params) => {
        return (
          <>
            {params?.row?.frame ? (
              <IconButton onClick={() => onMailClick(params?.row?.frame)}>
                <DraftsIcon />
              </IconButton>
            ) : (
              <CloseIcon sx={{ fill: theme.palette.error.main, ml: 1 }} />
            )}
          </>
        );
      },
    },

    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      headerAlign: "right",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            width={"100%"}
            justifyContent={"flex-end"}
            sx={{ mr: 1, maxWidth: 900 }}
          >
            {params?.row?.handled && (
              <IconButton onClick={() => navigate(`/meters/${params.value}`)}>
                <VisibilityIcon sx={{ fill: theme.palette.primary.main }} />
              </IconButton>
            )}
          </Box>
        );
      },
    },
  ];
  const renderDevice = (item) => {
    if (item && item.serial) {
      return `${item.manufacturer}:${item.version}:${item.type}  ${item?.serial}`;
    } else if (item && item.attributes?.values?.class) {
      return `${item?.attributes?.values?.class} ${item?.attributes?.values?.serial_number}`;
    } else if (item && item.attributes?.values?.type) {
      return `${item?.attributes?.values?.manufacturer}:${item?.attributes?.values?.version}:${item?.attributes?.values?.type} ${item?.attributes?.values?.serial}`;
    } else {
      return "na";
    }
  };
  const rows = allMessages?.map((item, i) => ({
    id: i,
    device: renderDevice(item),

    last_telegram: item?.attributes?.values
      ? t("date_val", {
          val: new Date(
            item?.attributes?.values?.values?.value?.last_reading?.datetime_utc
          ),
        })
      : t("date_val", { val: new Date(item?.datetime_utc) }),
    reading: item?.attributes?.values
      ? `${t("number", {
          val:
            item?.attributes?.values?.values?.value?.last_reading?.value ?? "-",
        })} ${
          item?.attributes?.values?.values?.value?.last_reading?.unit ?? "-"
        }`
      : "--",
    frame: item?.attributes?.values.frame,
    handled: item?.attributes?.values?.values?.value?.last_reading?.value
      ? true
      : false,
    actions: item?.attributes?.device_id,
  }));
  useEffect(() => {
    console.log("almesix", allMessages);
  }, [allMessages]);
  return (
    <>
      <ModalDialog
        open={modalMessage}
        close={() => setModalMessage(false)}
        title={"Message"}
      >
        <Box>
          <Typography variant="subtitle2">
            <pre
              style={{
                overflow: "auto",
                background: "#c0c0c0",
                /*     color: theme.palette.primary.contrastText, */
                padding: "9px",
              }}
            >
              {JSON.stringify(message, null, 10)}
            </pre>
          </Typography>
          {/* <CopyTextButton text={message?.frame} /> */}
        </Box>
      </ModalDialog>
      {allMessages.length > 0 && (
        <Box sx={{ height: "60vh", width: "99%" }}>
          <StyledDataGrid
            disableColumnMenu
            rows={rows}
            columns={columns}
            rowCount={allMessages.length}
            pageSize={perPage}
            rowsPerPageOptions={[perPage]}
            disableSelectionOnClick
            onPageChange={(pageNumber) => {
              setPage(pageNumber + 1);
            }}
            page={page - 1}
          />
        </Box>
      )}
      {allMessages.length === 0 && (
        <Box sx={{ mt: 7 }}>
          <Typography
            sx={{ color: theme.palette.primary.main }}
            variant="h3"
            align="center"
          >
            {t("no_available_data")}
          </Typography>
        </Box>
      )}
    </>
  );
};
export default ReadedMeters;

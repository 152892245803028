import { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLazyGetSurveyIndexQuery } from "../../redux/slices/surveyApi";
import validator from "@rjsf/validator-ajv6";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { prepareParams } from "../../utils";
import Box from "@mui/material/Box";
import { Form } from "@rjsf/mui";
import SurveyIndexTable from "./SurveyIndexTable";
import { useNavigate } from "react-router-dom";
import DeviceWidget from "./DeviceWidget";
import DatePicker from "../form/formComponents/DatePicker";
import theme from "../../theme";
import { useDispatch, useSelector } from "react-redux";
import { surveyActions } from "../../redux/slices/surveySlice";
import { useCreateSurveyMutation } from "../../redux/slices/surveyApi";
import UserWidget from "./UserWidget";

const ObjectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={2}>
      {props.properties?.map((element) => {
        return (
          <Grid key={element.id} alignItems="center" item xs={6}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
const CreateSurvey = () => {
  const dispatch = useDispatch();
  const [createdId, setCreatedId] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [createSurvey] = useCreateSurveyMutation();
  const [formData, setFormData] = useState({});
  const fields = {
    datepicker: DatePicker,
    users: UserWidget,
    device: DeviceWidget,
  };
  const uiSchema = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,

    "ui:submitButtonOptions": {
      submitText: t("create"),

      props: {
        className: "bntListSearch",
      },
    },
    active_surveys_by_date: {
      "ui:field": "datepicker",
    },
    user_id: {
      "ui:field": "users",
    },
    device_id: {
      "ui:field": "device",
    },
  };

  const schema = {
    title: "New Device",
    type: "object",
    properties: {
      name: {
        $id: "1",
        type: "string",
        length: "10",
        title: t("name"),
      },

      active_surveys_by_date: {
        $id: "3",
        title: t("inserted_at"),
        type: "object",

        properties: {
          from: {
            type: "string",
          },
          to: {
            type: "string",
          },
        },
      },

      device_id: {
        $id: "4",
        type: "string",
        title: t("device_id"),
      },

      user_id: {
        $id: "8",
        type: "string",
        title: t("user_id"),
      },
    },
    required: ["name"],
  };
  useEffect(() => {
    dispatch(surveyActions.cleanSurvey());
  }, []);
  useEffect(() => {
    console.log("asdasdasda", formData);
  }, [formData]);
  useEffect(() => {
    if (createdId) {
      navigate(`/surveys/${createdId}`);
    }
  }, [createdId]);
  const onSubmitClick = () => {
    const attributes = {
      name: formData.name,
      device_id: formData.device_id,
      user_id: formData.user_id,
      official_start_date: formData.active_surveys_by_date.from,
      official_end_date: formData.active_surveys_by_date.to,
    };
    createSurvey(attributes)
      .unwrap()
      .then((data) => setCreatedId(data?.data?.attributes?.id));
  };
  const customValidate = (formData, errors) => {
    if (!formData.user_id) {
      errors?.user_id.addError("is a required property");
    }
    if (!formData.device_id) {
      errors?.device_id.addError("is a required property");
    }
    if (!formData.active_surveys_by_date?.from) {
      errors.active_surveys_by_date?.from.addError(t("missing_startDate"));
    }
    if (!formData.active_surveys_by_date?.to) {
      errors.active_surveys_by_date?.to.addError(t("missing_endDate"));
    }
    if (
      formData.active_surveys_by_date?.from &&
      formData.active_surveys_by_date?.to
    ) {
      if (
        formData.active_surveys_by_date?.from !== "" &&
        formData.active_surveys_by_date?.to !== "" &&
        formData.active_surveys_by_date?.from >=
          formData.active_surveys_by_date?.to
      ) {
        errors.active_surveys_by_date?.to.addError(t("date_error"));
      }
    } else {
      if (
        formData.active_surveys_by_date?.from &&
        !formData.active_surveys_by_date?.to
      ) {
        errors.insertion_date?.to.addError(t("missing_endDate"));
      }
      if (
        !formData.active_surveys_by_date?.from &&
        formData.active_surveys_by_date?.to
      ) {
        errors.active_surveys_by_date?.from.addError(t("missing_startDate"));
      }
    }

    return errors;
  };
  return (
    <Box sx={{ height: "35vh" }}>
      <Typography
        variant="h3"
        sx={{ mb: 5, color: theme.palette.primary.main }}
      >
        Create Survey
      </Typography>
      <Form
        schema={schema}
        uiSchema={uiSchema}
        fields={fields}
        formData={formData}
        validator={validator}
        onSubmit={onSubmitClick}
        onChange={(changeEvent) => setFormData(changeEvent.formData)}
        customValidate={customValidate}
        noHtml5Validate
        showErrorList={false}
      />
    </Box>
  );
};
export default CreateSurvey;

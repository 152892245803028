import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showError: false,
  data: [],
  errors: [],
  errorTitle: "",
  errorStatus: "",
};

const errorSlice = createSlice({
  name: "errorSlice",
  initialState,
  reducers: {
    setErrors(state, action) {
      if (typeof action.payload.data === "object") {
        state.data = Object.entries(action.payload.data.errors).map(
          (item) => `${item?.[0]}: ${item?.[1].body ?? item?.[1]}`
        );
      }
      if (Array.isArray(action.payload.data.errors)) {
        state.data = action.payload.data.errors.map((item) => item.title);
      }
      state.errors = action.payload.error;
      state.errorStatus = action.payload.originalStatus;
      state.errorTitle = action.payload.status;
      state.showError = true;
    },
    clearErrors(state) {
      state.errors = [];
      state.errorTitle = "";
      state.errorStatus = "";
      state.data = [];
      state.showError = false;
    },
  },
});
export const { setErrors, clearErrors } = errorSlice.actions;
export default errorSlice.reducer;

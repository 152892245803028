import { Routes, Route } from "react-router-dom";
import RequireAuth from "../components/RequireAuth";
import NotFoundPage from "../utils/NotfoundPage";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { hasCommonValue } from "../utils/api_params";

export const IwdRoute = ({ component, section, path, auth }) => {
  const permessions = window.MENU[section];
  const userInfo = useSelector((state) => state?.userPreferenceSlice.userInfo);
  useEffect(() => {
    console.log("compix", component);
  }, [component]);

  const roles = useSelector((state) => state?.userPreferenceSlice.roles);

  return (
    <>
      {section === "login" && <>{component}</>}
      {permessions?.visibility &&
        hasCommonValue(roles, permessions?.roles) &&
        (auth ? <RequireAuth> {component}</RequireAuth> : <>{component}</>)}
      {userInfo !== null &&
        (!permessions?.visibility ||
          !hasCommonValue(roles, permessions?.roles)) && <NotFoundPage />}
    </>
  );
};

import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import { BrowserRouter } from "react-router-dom";
import Layout from "./components/Layout";
import AuthProvider from "./components/AuthProvider";
import RequireAuth from "./components/RequireAuth";
import Connections from "./components/connections/Connections";
import ConnectionsShow from "./components/connections/ConnectionsShow";
import Campaigns from "./components/campaign/Campaigns";
import { useSelector } from "react-redux";
import ConnectionUpdate from "./components/connections/ConnectionUpdate";
import UserShow from "./components/users/UserShow";
import DistrictIndex from "./components/DeviceGroups/DistrictsIndex";
import UserCreate from "./components/users/UserCreate";
import { Routes, Route } from "react-router-dom";
import { IwdRoute } from "./MuiOverrides/IwdRoute";
import LiveMessages from "./components/messages/LiveMessages";
import GroupDeviceCreate from "./components/DeviceGroups/GroupDeviceCreate";
import GatewayCreate from "./components/gateway/GatewayCreate";
import { Provider } from "react-redux";
import Uploader from "./components/FileUploader /Uploader";
import store from "./redux/store";
import Logged from "./components/Logged";
import CreateWidget from "./components/widgets/CreateWidget";
import "../node_modules/react-grid-layout/css/styles.css";
import "../node_modules/react-resizable/css/styles.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { faCheckSquare, faCoffee } from "@fortawesome/free-solid-svg-icons";
import LoginPage from "./components/Login";
import TrilliantMeters from "./components/campaign/trilliantMeters/TrilliantMeters";
import DashboardCreate from "./components/dashboard/DashboardCreate";
import { MuiThemeProvider, createTheme } from "@material-ui/core";
import MeterCreate from "./components/form/MeterCreate";
import UploadedFileShow from "./components/FileUploader /UploadedFileShow";
import Meters from "./components/form/Meters";
import Devices from "./pages/Devices";
import CreateDevice from "./pages/devices/CreateDevice";
import ShowDevice from "./pages/ShowDevice";
import AuthenticatedProvider from "./components/AuthenticatedProvider";
import DeviceGroups from "./pages/DeviceGroups";
import DeviceDistricts from "./pages/DeviceDistricts";
import DeviceGroupCreate from "./pages/DeviceGroupCreate";
import DeviceGroup from "./pages/DeviceGroup";
import DeviceGroupEdit from "./pages/device_groups/DeviceGroupEdit";
import AddDeviceToGroup from "./pages/device_groups/AddDeviceToGroup";
import DeviceDistrictCreateUpdate from "./pages/DeviceDistrictCreateUpdate";
import Dashboard from "./pages/Dashboard";
import PositionCreate from "./components/position/PositionCreate";
import CampaignCreate from "./components/campaign/CampaignCreate";
import Users from "./components/users/Users";
import CampaignShow from "./components/campaign/CampaignShow";
import theme from "./theme";
import GlobalLoading from "./utils/GlobalLoading";
import themeTrilliant from "./themeTrilliant";
import ConnectionCreate from "./components/connections/ConnectionCreate";
import { ThemeProvider } from "@mui/material";
import GroupDeviceShow from "./components/DeviceGroups/GroupsDeviceShow";
import Dex from "./pages/Dex";
import CustomSnackbar from "./utils/Snackbars";
import NetworkAdapters from "./pages/NetworkAdapters";
import CustomerEndpoints from "./pages/CustomerEndpoints";
import CustomerEndpointCreate from "./pages/CustomerEndpointCreate";
import { createRoot } from "react-dom/client";
import Messages from "./components/messages/Messages";
import CustomerEndpointUpdate from "./pages/CustomerEndpointUpdate";
import MeterShow from "./components/metrics/MeterShow";
import Riccardo from "./pages/Riccardo";
import FallbackLoader from "./utils/FallbackLoader";
import ErrorsHandler from "./utils/ErrorsHandler";
import SwarmApp from "./components/swarmApp/SwarmApp";
import TestLik from "./components/TestLink";
import StatisticShow from "./components/statistics/StatisticShow";
import Metrics from "./pages/Metrics";
import MyAccount from "./pages/MyAccount";
import GatewayShow from "./components/gateway/GatewayShow";
import StatisticCreate from "./components/statistics/StatisticCreate";
import HelmetHeader from "./utils/HelmetHeader";
import WidgetShow from "./components/widgets/WidgetShow";
import Statistics from "./components/statistics/Statistics";
import GatewayIndex from "./components/gateway/GatewayIndex";
import NotFoundPage from "./utils/NotfoundPage";
import Widgets from "./components/widgets/Widgets";
import DigitalTwinShow from "./components/digital_twins/DigitalTwinShow";
import DigitalTwins from "./components/digital_twins/DigitalTwins";
import { IwdRoutes } from "./utils/Routes";
import UploadFileForm from "./components/FileUploader /UploadFileForm";
function Ade() {
  window.location.replace("http://192.168.1.65:9295/");

  return null;
}

function ProtectedPage() {
  return <h3>Protected</h3>;
}
library.add(fab, faCheckSquare, faCoffee);

const container = document.getElementById("root");
const root = createRoot(container);

/* root.render(
  <Provider store={store}>
    <HelmetHeader />
    <React.StrictMode>
      <Suspense>
        <GlobalLoading />
        <ErrorsHandler />
        <CustomSnackbar />
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <AuthenticatedProvider>
              <BrowserRouter>
                <Routes>
                  <Route element={<Layout />}>
                    {IwdRoutes.map((item) => (
                      <Route
                        path={item?.path}
                        element={
                          <IwdRoute
                            auth={item?.auth}
                            component={item?.component}
                            section={item?.section}
                          />
                        }
                      />
                    ))}

                    <Route
                      path="/swarmapp"
                      element={
                        <RequireAuth>
                          <SwarmApp />
                        </RequireAuth>
                      }
                    />
                  </Route>
                </Routes>
              </BrowserRouter>
            </AuthenticatedProvider>
          </AuthProvider>
        </ThemeProvider>
      </Suspense>
    </React.StrictMode>
  </Provider>
); */
root.render(
  <Provider store={store}>
    <HelmetHeader />
    <React.StrictMode>
      <Suspense /* fallback={<FallbackLoader />} */>
        <GlobalLoading />
        <ErrorsHandler />
        <CustomSnackbar />
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <AuthenticatedProvider>
              <BrowserRouter>
                <Routes>
                  <Route element={<Layout />}>
                    {IwdRoutes.map((item) => (
                      <Route
                        path={item?.path}
                        element={
                          <IwdRoute
                            auth={item?.auth}
                            component={item?.component}
                            section={item?.section}
                          />
                        }
                      />
                    ))}
                    <Route
                      path="/swarmapp"
                      element={
                        <RequireAuth>
                          <SwarmApp />
                        </RequireAuth>
                      }
                    />
                  </Route>
                </Routes>
              </BrowserRouter>
            </AuthenticatedProvider>
          </AuthProvider>
        </ThemeProvider>
      </Suspense>
    </React.StrictMode>
  </Provider>
);

reportWebVitals();

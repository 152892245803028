import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import WorklistEditorSearchBar from "./WorklistEditorSearchBar";
import { useDeleteWorkListEditorMutation } from "../../redux/slices/worklistApi";
import { useTranslation } from "react-i18next";
import { setMessage } from "../../redux/slices/snackbarSlice";
import WorklistEditorTable from "./WorklistEditorTable";
import { useLazyGetWorklistEditorIndexQuery } from "../../redux/slices/worklistApi";
import { worklistActions } from "../../redux/slices/worklistSlice";
const WorklistEditorIndex = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const worklistEditorTrashId = useSelector(
    (state) => state?.worklistSlice?.worklistEditorTrashId
  );
  const [getWorklistEditors] = useLazyGetWorklistEditorIndexQuery();
  const [deleteWorkListEditor, { isSuccess: deleteSuccess }] =
    useDeleteWorkListEditorMutation();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const params = {
    page: page,
    perPage: perPage,
  };
  useEffect(() => {
    dispatch(worklistActions.cleanWorklistData());
  }, []);
  useEffect(() => {
    if (worklistEditorTrashId) {
      deleteWorkListEditor(worklistEditorTrashId);
    }
  }, [worklistEditorTrashId]);
  useEffect(() => {
    if (deleteSuccess) {
      dispatch(setMessage(t("delete_success")));
      getWorklistEditors(params);
    }
  }, [deleteSuccess]);
  useEffect(() => {
    getWorklistEditors(params);
  }, []);
  return (
    <>
      <WorklistEditorSearchBar params={params} />
      <WorklistEditorTable perPage={perPage} page={page} setPage={setPage} />
    </>
  );
};
export default WorklistEditorIndex;

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import GatewayRegistri from "./GatewayRegistry";
import ReadedMeters from "./ReadedMeters";
import { useTranslation } from "react-i18next";
import GatewayCommands from "./GatewayCommdans";
import GatewayCompoundTwins from "./GatewayCompoundTwins";
import TextField from "@mui/material/TextField";
import {
  useLazyGetShowGatewayQuery,
  useLazyGetGatewayMessagesQuery,
} from "../../redux/slices/gatewayApi";
import { gatewayActions } from "../../redux/slices/gatewaySlice";
import { useLazyGetDataQuery } from "../../redux/slices/GetDataRtq";
import {
  setidentifierTypeValue,
  unsetValue,
} from "../../redux/slices/breadCrumbMeterSlice";
import { useDispatch, useSelector } from "react-redux";
import GatewayMessages from "./GatewayMessages";
import theme from "../../theme";

import { Tabs, Tab, Box, Typography } from "@mui/material";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const GatewayShow = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const gateway = useSelector((state) => state?.gatewaySlice?.gateway);
  const twinId = useSelector((state) => state?.gatewaySlice?.twinId);
  const instanceId = useSelector((state) => state?.gatewaySlice?.instanceId);
  const [getShowGateway, { isError }] = useLazyGetShowGatewayQuery();
  const [getGatewayMessages, { isSuccess }] = useLazyGetGatewayMessagesQuery();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [value, setValue] = useState(0);
  const { t } = useTranslation();
  /*   const key = result?.data?.data?.attributes?.fields?.identifier_type;
  const identifierTypeValue = result?.data?.data?.attributes?.fields?.[key]; */
  /*   const serial = result?.data?.data?.attributes?.fields?.serial; */
  useEffect(() => {
    getShowGateway(id);
  }, []);
  useEffect(() => {
    if (twinId) {
      getGatewayMessages({
        twinId: twinId,
        instanceId: instanceId,
        params: { page: page, per_page: perPage },
      });
    }
  }, [twinId, page]);
  /*   useEffect(() => {
    if (serial) {
      dispatch(setidentifierTypeValue(serial));
    }
  }, [serial]); */
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /*   useEffect(() => {
    // breadCrumb value clean up function

    return () => {
      dispatch(unsetValue());
    };
  }, []); */
  useEffect(() => {
    return () => {
      dispatch(gatewayActions.unsetGatewayShow());
    };
  }, []);
  return (
    <>
      {gateway && (
        <>
          <Typography
            sx={{ color: theme.palette.primary.main }}
            variant="h3"
          >{`Gateway: ${gateway?.serial_number}`}</Typography>
          <Box sx={{ display: "grid", gridAutoColumns: "1fr", gap: 1 }}>
            <Box
              sx={{
                gridRow: "1",
                gridColumn: "span 2",
                width: "100%",
              }}
            >
              <Tabs value={value} onChange={handleChange}>
                <Tab label={t("registry")} />
                <Tab label={t("manufacturer_data")} />
                <Tab label={t("messages")} disabled={!isSuccess} />

                <Tab label={t("commands")} />
                <Tab label={t("meters_read")} />
                <Tab label={t("compound_twins")} />
              </Tabs>
              <TabPanel value={value} index={0}>
                <GatewayRegistri gateway={gateway} />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Typography variant="subtitle2">{t("name") + ":"} </Typography>
                <Typography>Manufacturer WebDyn </Typography>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <GatewayMessages
                  page={page}
                  setPage={setPage}
                  perPage={perPage}
                  twinId={twinId}
                />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <GatewayCommands digitalTwinId={twinId} />
              </TabPanel>
              <TabPanel value={value} index={4}>
                <ReadedMeters />
              </TabPanel>
              <TabPanel value={value} index={5}>
                <GatewayCompoundTwins twindId={twinId} instanceId={id} />
              </TabPanel>
            </Box>
          </Box>
        </>
      )}
      {!gateway && isError && (
        <Typography
          sx={{ color: theme.palette.primary.main }}
          variant="h3"
          align="center"
        >
          Gateway not found
        </Typography>
      )}
    </>
  );
};

export default GatewayShow;

import * as React from "react";
import Stack from "@mui/material/Stack";
import parse from "html-react-parser";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import { useDispatch, useSelector } from "react-redux";
import { setOpen, setMessage } from "../redux/slices/snackbarSlice";
import MuiAlert from "@mui/material/Alert";
import { TextField } from "@material-ui/core";
import Highlight from "react-highlight";
import theme from "../theme";
import { ThemeProvider, createTheme } from "@material-ui/core";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomSnackbar() {
  const dispatch = useDispatch();
  const message = useSelector((state) => state?.snackbarSlice?.message);
  const open = useSelector((state) => state?.snackbarSlice?.open);
  const severity = useSelector((state) => state?.snackbarSlice?.severity);

  const handleClick = () => {
    setOpen(true);
  };
  React.useEffect(() => {
    if (message) {
      dispatch(setOpen(true));
    }
  }, [message]);
  const colorSelector = () => {
    if (severity === "success") {
      return theme.palette.success.main;
    }
    if (severity === "warning") {
      return theme.palette.warning.main;
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(setOpen(false));
    dispatch(setMessage(null));
  };

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        sx={{ backgroundColor: colorSelector() }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          /*    severity={severity} */
          variant="trouble"
          sx={{ width: "100%", color: "white" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}

import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { v4 as uuidv4 } from "uuid";
import TreeView from "@mui/lab/TreeView";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import EventIcon from "@mui/icons-material/Event";
import { setNodes } from "../../redux/slices/nodeControllerSlice";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import BookIcon from "@mui/icons-material/Book";
import ListItem from "@mui/material/ListItem";
import { useDispatch, useSelector } from "react-redux";
import SettingsIcon from "@mui/icons-material/Settings";
import TreeItem from "@mui/lab/TreeItem";
const NodeSidebar = () => {
  const tabname = useSelector(
    (state) => state?.nodeControllerSlice?.actualTabName
  );
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState([]);
  const [items] = useState({
    flow: uuidv4(),
    eventNode: uuidv4(),
    logNode: uuidv4(),
    conditionNode: uuidv4(),
    propertyNode: uuidv4(),
    data: uuidv4(),
    interface: uuidv4(),
    functions: uuidv4(),
    Utility: uuidv4(),
  });

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
  };
  const addNode = (type) => {
    if (tabname) {
      dispatch(
        setNodes({
          id: uuidv4(),
          type: type,
          data: { value: null },
        })
      );
    }
  };
  return (
    <Box sx={{ height: "100%", width: 230 }}>
      <TreeView
        aria-label="file system navigator"
        expanded={expanded}
        selected={selected}
        onNodeToggle={handleToggle}
        onNodeSelect={handleSelect}
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        sx={{ height: "100%", flexGrow: 1, width: 220, overflowY: "auto" }}
      >
        <List>
          <TreeItem
            nodeId={items.flow}
            label={
              <ListItem sx={{ paddingLeft: 0 }}>
                <ListItemText primary="Flow" />
              </ListItem>
            }
          >
            <TreeItem
              onClick={() => addNode("event")}
              nodeId={items.eventNode}
              label={
                <ListItem sx={{ paddingLeft: 0 }}>
                  <ListItemIcon>
                    <EventIcon />
                  </ListItemIcon>
                  <ListItemText primary="Event" />
                </ListItem>
              }
            />
            <TreeItem
              onClick={() => addNode("condition")}
              nodeId={items.conditionNode}
              label={
                <ListItem sx={{ paddingLeft: 0 }}>
                  <ListItemIcon>
                    <CallSplitIcon />
                  </ListItemIcon>
                  <ListItemText primary="Condition" />
                </ListItem>
              }
            />
            <TreeItem
              onClick={() => addNode("log")}
              nodeId={items.logNode}
              label={
                <ListItem sx={{ paddingLeft: 0 }}>
                  <ListItemIcon>
                    <BookIcon />
                  </ListItemIcon>
                  <ListItemText primary="Log" />
                </ListItem>
              }
            />
            <TreeItem
              onClick={() => addNode("property")}
              nodeId={items.propertyNode}
              label={
                <ListItem sx={{ paddingLeft: 0 }}>
                  <ListItemIcon>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Property" />
                </ListItem>
              }
            />
          </TreeItem>
          <TreeItem
            nodeId={items.data}
            label={
              <ListItem sx={{ paddingLeft: 0 }}>
                <ListItemText primary="Data" />
              </ListItem>
            }
          >
            some data
          </TreeItem>
          <TreeItem
            nodeId={items.interface}
            label={
              <ListItem sx={{ paddingLeft: 0 }}>
                <ListItemText primary="Interface" />
              </ListItem>
            }
          >
            some interface
          </TreeItem>
          <TreeItem
            nodeId={items.functions}
            label={
              <ListItem sx={{ paddingLeft: 0 }}>
                <ListItemText primary="Functions" />
              </ListItem>
            }
          >
            Funtions
          </TreeItem>
          <TreeItem
            nodeId={items.Utility}
            label={
              <ListItem sx={{ paddingLeft: 0 }}>
                <ListItemText primary="Utility" />
              </ListItem>
            }
          >
            Utility
          </TreeItem>
        </List>
      </TreeView>
    </Box>
  );
};

export default NodeSidebar;

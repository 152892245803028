import { Handle, Position } from "reactflow";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import CustomHandle from "../CustomHandle";
import FormControl from "@mui/material/FormControl";

import EventIcon from "@mui/icons-material/Event";
import { Typography } from "@mui/material";
const EventNode = () => {
  const [events, setEvets] = useState([]);
  const handleChange = (event) => {
    setEvets(event.target.value);
  };

  return (
    <Box
      sx={{
        width: 200,
        height: 100,
        border: "2px solid gray",
        background: "white",
      }}
    >
      <CustomHandle
        type={"source"}
        position={"right"}
        style={{ marginTop: 10 }}
        color={"#73a580"}
        tooltip={"Output"}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems={"center"}
        sx={{ background: "green", height: 30 }}
      >
        <EventIcon />
        <Typography>Event</Typography>
      </Box>
      <Box sx={{ mt: 2 }}>
        <FormControl fullWidth>
          <select
            style={{
              height: "2rem",
              width: "10rem",
              marginLeft: 20,
              marginTop: 3,
            }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={events}
            label="Event"
            onChange={handleChange}
          >
            <option value={10}>Ten</option>
            <option value={20}>Twenty</option>
            <option value={30}>Thirty</option>
          </select>
        </FormControl>
      </Box>
    </Box>
  );
};
export default EventNode;

import { useParams } from "react-router-dom";
import { useLazyGetSurveyQuery } from "../../redux/slices/surveyApi";
import { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLazyGetSurveyIndexQuery } from "../../redux/slices/surveyApi";
import validator from "@rjsf/validator-ajv6";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { prepareParams } from "../../utils";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { Form } from "@rjsf/mui";
import SurveyIndexTable from "./SurveyIndexTable";
import { useNavigate } from "react-router-dom";
import { setMessage } from "../../redux/slices/snackbarSlice";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import DeviceWidget from "./DeviceWidget";
import DatePicker from "../form/formComponents/DatePicker";
import { useUpdateSurveyMutation } from "../../redux/slices/surveyApi";
import theme from "../../theme";
import { IwdAuthWrapper } from "../../MuiOverrides/IwdAuthWrapper";
import { useDispatch, useSelector } from "react-redux";
import { useCreateSurveyMutation } from "../../redux/slices/surveyApi";
import UserWidget from "./UserWidget";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
} from "@mui/material";
const ObjectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={2}>
      {props.properties?.map((element) => {
        return (
          <Grid key={element.id} alignItems="center" item xs={6}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
const SurveyShow = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [getSurvey] = useLazyGetSurveyQuery();
  const [updateSurvey, { isSuccess }] = useUpdateSurveyMutation();
  const survey = useSelector((state) => state?.surveySlice?.survey);
  useEffect(() => {
    getSurvey(id);
  }, []);
  const statusEnum = [
    { const: "" },
    { const: "created" },
    { const: "downloaded" },
    { const: "started" },
    { const: "paused" },
    { const: "ended" },
  ];
  const { t } = useTranslation();
  const [createSurvey] = useCreateSurveyMutation();
  const [formData, setFormData] = useState({});
  const onSubmitClick = () => {
    const attributes = {
      name: formData.name,
      device_id: formData.device_id.toString(),
      user_id: formData.user_id.toString(),
      official_start_date: formData.active_surveys_by_date.from,
      official_end_date: formData.active_surveys_by_date.to,
      status: formData.status,
    };

    updateSurvey({
      attributes: attributes,
      id: id,
    });
  };
  useEffect(() => {
    if (isSuccess) {
      dispatch(setMessage(t("update_success")));
      getSurvey(id);
    }
  }, [isSuccess]);
  const fields = {
    datepicker: DatePicker,
    users: UserWidget,
    device: DeviceWidget,
  };
  const uiSchema = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,

    "ui:submitButtonOptions": {
      submitText: t("update"),

      props: {
        className: "bntListSearch",
      },
    },
    active_surveys_by_date: {
      "ui:field": "datepicker",
    },
    user_id: {
      "ui:field": "users",
    },
    device_id: {
      "ui:field": "device",
    },
  };

  const schema = {
    title: "New Device",
    type: "object",
    properties: {
      name: {
        $id: "1",
        type: "string",
        length: "10",
        title: t("name"),
        default: survey?.name,
      },
      status: {
        $id: "3",
        type: "string",
        title: t("status"),
        oneOf: [...statusEnum],
        default: survey?.status ?? "",
      },
      active_surveys_by_date: {
        $id: "3",
        title: t("inserted_at"),
        type: "object",

        properties: {
          from: {
            type: "string",
            default: survey?.official_start_date,
          },
          to: {
            type: "string",
            default: survey?.official_end_date,
          },
        },
      },

      device_id: {
        $id: "4",
        type: "string",
        title: t("device_id"),
        default: survey?.device_id.toString(),
        update: true,
      },

      user_id: {
        $id: "8",
        type: "string",
        title: t("user_id"),
        default: survey?.user_id,
        update: true,
      },
    },
    required: ["name"],
  };
  useEffect(() => {
    console.log("showfrmdt", formData);
  }, [formData]);
  const customValidate = (formData, errors) => {
    if (!formData.user_id) {
      errors?.user_id.addError("is a required property");
    }
    if (!formData.device_id) {
      errors?.device_id.addError("is a required property");
    }
    if (!formData.active_surveys_by_date?.from) {
      errors.active_surveys_by_date?.from.addError(t("missing_startDate"));
    }
    if (!formData.active_surveys_by_date?.to) {
      errors.active_surveys_by_date?.to.addError(t("missing_endDate"));
    }
    if (
      formData.active_surveys_by_date?.from &&
      formData.active_surveys_by_date?.to
    ) {
      if (
        formData.active_surveys_by_date?.from !== "" &&
        formData.active_surveys_by_date?.to !== "" &&
        formData.active_surveys_by_date?.from >=
          formData.active_surveys_by_date?.to
      ) {
        errors.active_surveys_by_date?.to.addError(t("date_error"));
      }
    } else {
      if (
        formData.active_surveys_by_date?.from &&
        !formData.active_surveys_by_date?.to
      ) {
        errors.insertion_date?.to.addError(t("missing_endDate"));
      }
      if (
        !formData.active_surveys_by_date?.from &&
        formData.active_surveys_by_date?.to
      ) {
        errors.active_surveys_by_date?.from.addError(t("missing_startDate"));
      }
    }

    return errors;
  };
  return (
    <>
      {survey && (
        <>
          <Typography
            variant="h3"
            sx={{ mb: 5, color: theme.palette.primary.main }}
          >
            {`Survey ${survey?.name}`}
          </Typography>
          <Card>
            <CardContent>
              <Grid container>
                <Grid item xs={2}>
                  <Typography variant="subtitle2">
                    {t("official_start_date")}
                  </Typography>
                  <Typography variant="subtitle1">
                    {t("dt", { val: new Date(survey?.official_start_date) })}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="subtitle2">
                    {t("official_end_date")}
                  </Typography>
                  <Typography variant="subtitle1">
                    {t("dt", { val: new Date(survey?.official_end_date) })}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="subtitle2">{t("updated_at")}</Typography>
                  <Typography variant="subtitle1">
                    {t("dt", { val: new Date(survey?.updated_at) })}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="subtitle2">
                    {t("actual_start_date")}
                  </Typography>
                  <Typography variant="subtitle1">
                    {survey?.actual_start_date
                      ? t("dt", { val: new Date(survey?.actual_start_date) })
                      : t("undifined_date")}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="subtitle2">
                    {t("actual_end_date")}
                  </Typography>
                  <Typography variant="subtitle1">
                    {survey?.actual_end_date
                      ? t("dt", { val: new Date(survey?.actual_end_date) })
                      : t("undifined_date")}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="subtitle2">{t("meter")}</Typography>
                  <IconButton
                    onClick={() => navigate(`/meters/${survey.device_id}`)}
                  >
                    <VisibilityIcon sx={{ fill: theme.palette.primary.main }} />
                  </IconButton>
                </Grid>
                <Grid item xs={1}>
                  <Typography sx={{ ml: 0.5 }} variant="subtitle2">
                    {t("user")}
                  </Typography>
                  <IconButton>
                    <VisibilityIcon
                      onClick={() => navigate(`/users/${survey.user_id}`)}
                      sx={{ fill: theme.palette.primary.main }}
                    />
                  </IconButton>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="subtitle2">{t("name")}</Typography>
                  <Typography variant="subtitle1">{survey.name}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="subtitle2">{t("status")}</Typography>
                  <Typography variant="subtitle1">{survey.status}</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <IwdAuthWrapper
            section={"surveys_update"}
            children={
              <Box sx={{ mt: 5 }}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    Update form
                  </AccordionSummary>
                  <AccordionDetails sx={{ mb: 5 }}>
                    <Form
                      schema={schema}
                      uiSchema={uiSchema}
                      fields={fields}
                      formData={formData}
                      onSubmit={onSubmitClick}
                      customValidate={customValidate}
                      onChange={(changeEvent) =>
                        setFormData(changeEvent.formData)
                      }
                      validator={validator}
                      noHtml5Validate
                      showErrorList={false}
                    />
                  </AccordionDetails>
                </Accordion>
              </Box>
            }
          />
        </>
      )}
    </>
  );
};
export default SurveyShow;

import * as React from "react";
import { actions } from "../../redux/slices/iwdWebSocketSlice";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { useState, useEffect } from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import { useTranslation } from "react-i18next";
import WsConnector from "../../utils/WsConnector";
import {
  setLiveMessages,
  clearLiveMessages,
} from "../../redux/slices/messageSlice";
import MessagePopover from "./MessagePopover";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import PollIcon from "@mui/icons-material/Poll";
import ModalDialog from "../meters/ModalDialog";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import WifiIcon from "@mui/icons-material/Wifi";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import Messages from "./Messages";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DraftsIcon from "@mui/icons-material/Drafts";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FolderIcon from "@mui/icons-material/Folder";
import DeleteIcon from "@mui/icons-material/Delete";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import theme from "../../theme";
import Button from "@mui/material/Button";

function generate(element) {
  return [0, 1, 2].map((value) =>
    React.cloneElement(element, {
      key: value,
    })
  );
}

const Demo = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

const LiveMessages = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState(false);
  const [dense, setDense] = React.useState(false);
  const dispatch = useDispatch();
  const liveMessages = useSelector((state) => state.messageSlice.liveMessages);
  const [secondary, setSecondary] = React.useState(false);
  /*  const {
    isWSConnected,
    data: dataMessage,
    error,
    channels,
    loading: wsLoading,
  } = useSelector((state) => state.iwdWebSocketSlice);
  useEffect(() => {
    if (!isWSConnected) {
      dispatch(actions.connect());
    }
  }, [isWSConnected]);
  useEffect(() => {
    if (isWSConnected) {
      dispatch(
        actions.joinChannel({
          name: "message",
        })
      );
    }
  }, [isWSConnected]);
  useEffect(() => {
    if (Object.keys(channels).length > 0) {
      dispatch(
        actions.subscribeChannel({
          channelName: "message",
          eventName: "messages_incoming",
        })
      );
    }
  }, [channels]);
  const showData = dataMessage?.payload?.map((item) => item.values.values);
  useEffect(() => {
    if (dataMessage?.messages_incoming) {
      debugger;
      dispatch(setLiveMessages(dataMessage?.messages_incoming));
    }
  }, [dataMessage]); */
  const openModal = (msg) => {
    setMessage(msg);
    setModal(true);
  };
  /*   useEffect(
    () => console.log("dataMessage", dataMessage, channels),
    [dataMessage, channels]
  ); */
  const closeModal = () => {
    setMessage("");
    setModal(false);
  };
  const clearMessages = () => {
    dispatch(clearLiveMessages());
  };

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h4" sx={{ color: theme.palette.primary.main }}>
          {t("live_messages")}
        </Typography>
        <Box display="flex" alignItems="center">
          {/*  {isWSConnected ? (
            <Tooltip title={`${t("webSocket")}: ${t("connected")} `}>
              <WifiIcon sx={{ mr: 3 }} />
            </Tooltip>
          ) : (
            <Tooltip title={`${t("webSocket")}: ${t("no_connection")}`}>
              <WifiOffIcon sx={{ mr: 3 }} />
            </Tooltip>
          )} */}
          <WsConnector
            channelName={"message"}
            eventName={"messages_incoming"}
            setDataFunction={setLiveMessages}
            style={{ mr: 3 }}
          />
          <Tooltip title={t("clear_messages")}>
            <Button variant="contained" onClick={clearMessages}>
              <DeleteForeverIcon />
            </Button>
          </Tooltip>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          maxHeight: 705,
          overflow: "hidden",
          mt: 3,
        }}
      >
        <ModalDialog open={modal} close={() => closeModal()} title={"Message"}>
          <Box>
            <Typography variant="subtitle2">
              <pre
                style={{
                  overflow: "auto",
                  background: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                  padding: "9px",
                }}
              >
                {JSON.stringify(message, null, 10)}
              </pre>
            </Typography>
          </Box>
        </ModalDialog>
        <Box overflow="auto">
          <Box overflow="hidden" sx={{ mt: 2 }}>
            <Grid container spacing={2} overflow="hidden">
              {liveMessages?.length > 0 ? (
                liveMessages.map((item) => (
                  <>
                    <Grid item xs={12} overflow="hidden">
                      {item?.values?.values?.value_type === "diagnostic" && (
                        <Grid container columnSpacing={12}>
                          <Grid item xs={1}>
                            <Box display="flex">
                              <IconButton
                                onClick={() =>
                                  navigate(`/meters/${item?.device_id}`)
                                }
                              >
                                <VisibilityIcon
                                  sx={{ fill: theme.palette.primary.main }}
                                />
                              </IconButton>
                              <IconButton
                                onClick={() =>
                                  openModal(item?.values?.values?.value)
                                }
                              >
                                <DraftsIcon
                                  sx={{ fill: theme.palette.primary.main }}
                                />
                              </IconButton>
                            </Box>
                          </Grid>
                          <Grid item xs={1} sx={{ mt: 1 }}>
                            <Tooltip title={t("diagnostic_event")}>
                              <TroubleshootIcon />
                            </Tooltip>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography>{`${t("battery_lvl_value")}: ${
                              item?.values?.values?.value?.battery_level?.value
                            }  ${
                              item?.values?.values?.value?.battery_level?.unit
                            } `}</Typography>
                          </Grid>
                          <Grid item xs={2}>
                            {item?.values?.values?.value?.meter_status
                              ?.value === "normal" ? (
                              <Grid container>
                                <Grid item xs={10}>
                                  <Typography>{`${t(
                                    "meter_status_value"
                                  )}:`}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <CheckIcon sx={{ color: "green" }} />
                                </Grid>
                              </Grid>
                            ) : (
                              <Grid container>
                                <Grid item xs={10}>
                                  {" "}
                                  <Typography>{`${t(
                                    "meter_status_value"
                                  )}:`}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  {" "}
                                  <ErrorIcon
                                    sx={{
                                      color: theme.palette.error.light,
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                          <Grid item xs={3}>
                            <Typography>{`${t("read_time")} : ${t("dt", {
                              val: new Date(item.values?.read_time),
                            })}`}</Typography>
                          </Grid>
                          <Grid item xs={3}>
                            {item?.values?.values?.value?.alarms?.length > 0 ? (
                              <Box display="flex" alignItems="center">
                                <Typography>{`${t("alarms")}: `}</Typography>
                                {item?.values?.values?.value?.alarms.map(
                                  (item) => (
                                    <Chip
                                      label={item}
                                      sx={{
                                        ml: 1,
                                        background: theme.palette.error.light,
                                        color: theme.palette.error.contrastText,
                                      }}
                                    />
                                  )
                                )}
                              </Box>
                            ) : (
                              <Box sx={{ mr: 2 }}>
                                <Typography>{`${t(
                                  "alarms"
                                )}: No alarms`}</Typography>
                              </Box>
                            )}
                          </Grid>
                        </Grid>
                      )}
                      {item?.values?.values?.value_type === "reading" && (
                        <Grid container columnSpacing={12}>
                          <Grid item xs={1}>
                            <Box display="flex">
                              <IconButton
                                onClick={() =>
                                  navigate(`/meters/${item?.device_id}`)
                                }
                              >
                                <VisibilityIcon
                                  sx={{ fill: theme.palette.primary.main }}
                                />
                              </IconButton>
                              <IconButton
                                onClick={() =>
                                  openModal(item?.values?.values?.value)
                                }
                              >
                                <DraftsIcon
                                  sx={{ fill: theme.palette.primary.main }}
                                />
                              </IconButton>
                            </Box>
                          </Grid>
                          <Grid item xs={1} sx={{ mt: 1 }}>
                            <Tooltip title={t("standart_event")}>
                              <MenuBookIcon />
                            </Tooltip>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography>{`${t("last_reading_value")}: ${
                              item?.values?.values?.value?.last_reading?.value
                            } ${
                              item?.values?.values?.value?.last_reading?.unit
                            } `}</Typography>
                          </Grid>
                          <Grid item xs={2}>
                            {item?.values?.values?.value?.meter_status
                              ?.value === "normal" ? (
                              <Grid container>
                                <Grid item xs={10}>
                                  <Typography>{`${t(
                                    "meter_status_value"
                                  )}:`}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <CheckIcon sx={{ color: "green" }} />
                                </Grid>
                              </Grid>
                            ) : (
                              <Grid container>
                                <Grid item xs={10}>
                                  {" "}
                                  <Typography>{`${t(
                                    "meter_status_value"
                                  )}:`}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  {" "}
                                  <ErrorIcon
                                    sx={{
                                      color: theme.palette.error.light,
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                          <Grid item xs={3}>
                            <Typography>{`${t("read_time")} : ${t("dt", {
                              val: new Date(item.values?.read_time),
                            })}`}</Typography>
                          </Grid>
                          <Grid item xs={3}>
                            {item?.values?.values?.value?.alarms?.length > 0 ? (
                              <Box display="flex" alignItems="center">
                                <Typography>{`${t("alarms")}: `}</Typography>
                                {item?.values?.values?.value?.alarms.map(
                                  (item) => (
                                    <Chip
                                      label={item}
                                      sx={{
                                        ml: 1,
                                        background: theme.palette.error.light,
                                        color: theme.palette.error.contrastText,
                                      }}
                                    />
                                  )
                                )}
                              </Box>
                            ) : (
                              <Box sx={{ mr: 2 }}>
                                <Typography>{`${t(
                                  "alarms"
                                )}: No alarms`}</Typography>
                              </Box>
                            )}
                          </Grid>
                        </Grid>
                      )}
                      {item?.values?.values?.survey_id && (
                        <Grid container columnSpacing={12}>
                          <Grid item xs={1}>
                            <Box display="flex">
                              <IconButton
                                onClick={() =>
                                  navigate(`/meters/${item?.device_id}`)
                                }
                              >
                                <VisibilityIcon
                                  sx={{ fill: theme.palette.primary.main }}
                                />
                              </IconButton>
                              <IconButton
                                disabled={!item?.values?.values?.value}
                                onClick={() =>
                                  openModal(item?.values?.values?.value)
                                }
                              >
                                <DraftsIcon
                                  sx={{
                                    fill:
                                      item?.values?.values?.value &&
                                      theme.palette.primary.main,
                                  }}
                                />
                              </IconButton>
                            </Box>
                          </Grid>
                          <Grid item xs={1} sx={{ mt: 1 }}>
                            <Tooltip title={t("survey_event")}>
                              <PollIcon />
                            </Tooltip>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography>{`${t("last_reading_value")}: ${
                              item?.values?.values?.value?.last_reading
                                ?.value ?? "-"
                            } ${
                              item?.values?.values?.value?.last_reading?.unit ??
                              "-"
                            } `}</Typography>
                          </Grid>
                          <Grid item xs={2}>
                            {item?.values?.values?.value?.meter_status
                              ?.value === "normal" ? (
                              <Grid container>
                                <Grid item xs={10}>
                                  <Typography>{`${t(
                                    "meter_status_value"
                                  )}:`}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <CheckIcon sx={{ color: "green" }} />
                                </Grid>
                              </Grid>
                            ) : (
                              <Grid container>
                                <Grid item xs={10}>
                                  {" "}
                                  <Typography>{`${t(
                                    "meter_status_value"
                                  )}:`}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  {" "}
                                  <ErrorIcon
                                    sx={{
                                      color: theme.palette.error.light,
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                          <Grid item xs={3}>
                            <Typography>{`${t("read_time")} : ${t("dt", {
                              val: new Date(item.values?.read_time),
                            })}`}</Typography>
                          </Grid>
                          <Grid item xs={3}>
                            {item?.values?.values?.value?.alarms?.length > 0 ? (
                              <Box display="flex" alignItems="center">
                                <Typography>{`${t("alarms")}: `}</Typography>
                                {item?.values?.values?.value?.alarms.map(
                                  (item) => (
                                    <Chip
                                      label={item}
                                      sx={{
                                        ml: 1,
                                        background: theme.palette.error.light,
                                        color: theme.palette.error.contrastText,
                                      }}
                                    />
                                  )
                                )}
                              </Box>
                            ) : (
                              <Box sx={{ mr: 2 }}>
                                <Typography>{`${t(
                                  "alarms"
                                )}: No alarms`}</Typography>
                              </Box>
                            )}
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </>
                ))
              ) : (
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item xs={12} sx={{ mt: 3 }}>
                    <Typography
                      sx={{ color: theme.palette.primary.main }}
                      variant="h4"
                    >
                      {t("no_messages")}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default LiveMessages;

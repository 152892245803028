import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { CustomTextField } from "../styledComponents/StyledComponents";
import { CustomAutocomlete } from "../styledComponents/StyledComponents";

export default function SwarmAppAutocomlete({
  options,
  value,
  setValue,
  label,
  sx,
}) {
  React.useEffect(() => {
    console.log("obtikai", options);
  }, [options]);
  return (
    <CustomAutocomlete
      size="small"
      className="nodrag"
      options={options?.length > 0 ? options : []}
      value={value}
      sx={sx}
      onChange={(event, value) => {
        console.log("selected value:", value);

        setValue(value ? value : "");
      }}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
}
